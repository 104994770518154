import React from 'react';
import PropTypes from 'prop-types';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {trimStart} from '../../../utils';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'individual_contribution', label: 'Employer HSA Individual Contribution'},
    {name: 'family_contribution', label: 'Employer HSA Family Contribution'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const HsaForm = ({card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isFinAccount/>

            <CardHeaderInfo/>

            <CopyableField name='display_card_settings.show_employer_contribution'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption='Show Employer Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='individual_contribution' parse={trimStart}>
                {props => <Input {...props} placeholder='Enter individual contribution...' label='Employer HSA Individual Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='family_contribution' parse={trimStart}>
                {props => <Input {...props} placeholder='Enter family contribution...' label='Employer HSA Family Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='display_card_settings.show_limited_purpose'>
                {props => <Switcher {...props} caption='Show IRS Contribution Limit'/>}
            </CopyableField>

            <Separator type='dashed'/>

            <CopyableField name='display_card_settings.show_additional_contribution'>
                {props => <Switcher {...props} caption='Show  hint *Over age 55: Additional $1,000 catch-up contribution'/>}
            </CopyableField>

        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

HsaForm.propTypes = {
    card: PropTypes.string
};

export {HsaForm as TestableHsaForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(HsaForm);
