import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import {TOOLTIP_DIRECTIONS} from '@frontend/ui-kit/Components/Tooltip';
import FileUploader from '../../shared/FileUploader';
import {Form, Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import AssignableActionBar from '../../shared/AssignableActionBar';
import {getIndustryList} from '../../../selectors/company';
import {requestIndustryList} from '../../../actions/company';
import {requestAuthInfo, requestAuthInfoUpdating} from '../../../actions/authorization';
import {getActiveCompany, getIsLaunchDatePassed, getIsSuperAdmin} from '../../../selectors/general';
import {
    addDays,
    getRegisteredFieldsValues,
    minToSec,
    validateInt,
    validateRequired
} from '../../../utils';
import {
    ACCEPTABLE_IMAGE_FORMATS,
    DEFAULT_PHONE_PLACEHOLDER,
    DEFAULT_SESSION_LENGTH,
    FORMS,
    MAX_IMAGE_SIZE,
    PASSWORD_LENGTH_RANGE,
    SESSION_LENGTH_RANGE
} from '../../../constants';
import './index.scss';

const SESSION_PLATFORMS = {mobile: 'mobile', dashboard: 'dashboard'};

const NAME_TOOLTIP_CONTENT = `
    This is the name employees will see in email marketing and their HealthJoy apps.
    If different from your legal corporate name, enter the name that employees will recognize.
`;
const INTERNAL_OPTIONS_TOOLTIP_CONTENT = 'These options are only visible to HealthJoy Employees.';

/* istanbul ignore next */
const validate = isSuperAdmin => values => ({
    name: validateRequired(values.name),
    address: validateRequired(values.address),
    industry_id: validateRequired(values.industry_id),
    phone_number: validateRequired(values.phone_number),
    ...isSuperAdmin && {
        authInfo: {
            // eslint-disable-next-line camelcase
            dashboard_session_ttl: validateInt(values.authInfo?.dashboard_session_ttl, SESSION_LENGTH_RANGE),
            // eslint-disable-next-line camelcase
            mobile_session_ttl: validateInt(values.authInfo?.mobile_session_ttl, SESSION_LENGTH_RANGE),
            // eslint-disable-next-line camelcase
            mobile_password_min_length: validateInt(values.authInfo?.mobile_password_min_length, PASSWORD_LENGTH_RANGE),
            // eslint-disable-next-line camelcase
            dashboard_password_min_length: validateInt(values.authInfo?.dashboard_password_min_length, PASSWORD_LENGTH_RANGE)
        }
    }
});

const GeneralInfoStep = ({requestCompany, requestCompanyUpdating, ...props}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const industryList = useSelector(getIndustryList);
    const isLaunchDatePassed = useSelector(state => getIsLaunchDatePassed(state, 0));
    const {is_lite: isInitialLite, alias} = useSelector(getActiveCompany);
    const {is_lite: isLite, launch_date: launchDate, authInfo = {}} = initialValues;

    const switchSessionRenewal = (form, platform) => () => {
        form.change(`authInfo.${platform}_session_ttl`, DEFAULT_SESSION_LENGTH);
    };

    const changeIsLite = form => event => {
        const {checked} = event?.target;

        return checked ? false : form.change('is_lite_permanently', false);
    };

    const onSubmit = async (values, {getRegisteredFields}) => {
        const registeredFieldsValues = getRegisteredFieldsValues(getRegisteredFields(), values);
        const {authInfo = {}, ...companyInfo} = registeredFieldsValues;
        const formattedAuthInfo = {
            ...authInfo,
            dashboard_session_ttl: minToSec(authInfo.dashboard_session_ttl),
            mobile_session_ttl: minToSec(authInfo.mobile_session_ttl),
            mobile_password_min_length: parseInt(authInfo.mobile_password_min_length, 10),
            dashboard_password_min_length: parseInt(authInfo.dashboard_password_min_length, 10)
        };

        const requests = [
            dispatch(requestCompanyUpdating(companyInfo)),
            isSuperAdmin && dispatch(requestAuthInfoUpdating(formattedAuthInfo))
        ].filter(Boolean);

        const responses = await Promise.all(requests);
        if (responses.some(({isSuccess}) => !isSuccess)) {
            return responses.reduce((errors, {submissionErrors}) => ({...errors, ...submissionErrors}), {});
        }

        setInitialValues(values);
    };

    useEffect(() => {
        (async () => {
            dispatch(requestIndustryList());
            const {data: companyData} = await dispatch(requestCompany());
            if (!isSuperAdmin) {
                return setInitialValues(companyData);
            }

            const {data: authInfo} = await dispatch(requestAuthInfo(alias));
            setInitialValues({...companyData, authInfo});
        })();
    }, []);

    const {mobile_session_renewal: isMobileSessionRenewal, dashboard_session_renewal: isDashboardSessionRenewal} = authInfo;

    const isLiteDatePassed = !isLite && (isLaunchDatePassed || !launchDate);
    const isLiteDisabled = isInitialLite || isLiteDatePassed;

    return (
        <Form name={FORMS.companyGeneralInfo} initialValues={initialValues} onSubmit={onSubmit} validate={validate(isSuperAdmin)}>
            {({form, handleSubmit}) => {
                const onChangeIsLite = changeIsLite(form);
                const actionBarProps = {...props, onSubmitStep: handleSubmit};

                return (
                    <React.Fragment>
                        <form onSubmit={handleSubmit} noValidate>
                            <Row className='company-wizard-step general-info-step'>
                                <Column className='company-wizard-step-section' sm>
                                    <Field name='name'>
                                        {props => <Input {...props} placeholder='Enter company name...' label={<LabeledTooltip title='Company Name' content={NAME_TOOLTIP_CONTENT}/>} isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='logo'>
                                        {props => <FileUploader {...props} size='lg' isImage accept={ACCEPTABLE_IMAGE_FORMATS} maxSize={MAX_IMAGE_SIZE} label='Upload Company Logo' caption='240px x 50px'/>}
                                    </Field>
                                </Column>

                                <Column className='company-wizard-step-section' sm>
                                    <Field name='address'>
                                        {props => <Input {...props} placeholder='Enter address...' label='Address' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='industry_id'>
                                        {props => <Select {...props} options={industryList} placeholder='Select Industry...' label='Industry' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='phone_number'>
                                        {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='General Phone Number' isRequired/>}
                                    </Field>
                                </Column>

                                {isSuperAdmin && (
                                    <Column sm={12} className='company-wizard-step-section internal-options'>
                                        <Text className='internal-options__title'><LabeledTooltip title='HealthJoy Internal Options' content={INTERNAL_OPTIONS_TOOLTIP_CONTENT} direction={TOOLTIP_DIRECTIONS.right}/></Text>

                                        <Row columnGap='lg' rowGap='lg' className='internal-options-row'>
                                            <Column className='internal-options-row__column' sm={8}>
                                                <Row columnGap='lg' rowGap='lg' className='internal-options-row'>
                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.mobile_two_step'>
                                                            {props => <Switcher {...props} className='internal-options__field' caption='Require Two-Factor SMS Code for App Authentication'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.dashboard_two_step'>
                                                            {props => <Switcher {...props} className='internal-options__field' caption='Require Two-Factor SMS Code for Dashboard Authentication'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.mobile_session_renewal' onChange={switchSessionRenewal(form, SESSION_PLATFORMS.mobile)}>
                                                            {props => <Switcher {...props} className='internal-options__field' caption='Allow Automatic Session Renewal for App Authentication'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.dashboard_session_renewal' onChange={switchSessionRenewal(form, SESSION_PLATFORMS.dashboard)}>
                                                            {props => <Switcher {...props} className='internal-options__field' caption='Allow Automatic Session Renewal for Dashboard Authentication'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.mobile_password_compromised'>
                                                            {props => <Switcher {...props} className='internal-options__field' caption='Send Compromised Password Notifications for App Users'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.dashboard_password_compromised'>
                                                            {props => <Switcher {...props} className='internal-options__field' caption='Send Compromised Password Notifications for Dashboard Users'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.mobile_session_ttl'>
                                                            {props => <Input {...props} data-testid='mobile_session_ttl' placeholder='Enter session length' disabled={isMobileSessionRenewal} label='App Session Length in Minutes'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.dashboard_session_ttl'>
                                                            {props => <Input {...props} data-testid='dashboard_session_ttl' placeholder='Enter session length' disabled={isDashboardSessionRenewal} label='Dashboard Session Length in Minutes'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.mobile_password_min_length'>
                                                            {props => <Input {...props} placeholder='Enter password length' label='App Minimum Password Length'/>}
                                                        </Field>
                                                    </Column>

                                                    <Column className='internal-options-row__column' sm={6}>
                                                        <Field name='authInfo.dashboard_password_min_length'>
                                                            {props => <Input {...props} placeholder='Enter password length' label='Dashboard Minimum Password Length'/>}
                                                        </Field>
                                                    </Column>
                                                </Row>
                                            </Column>
                                            <Column className='internal-options-row__column' sm={4}>
                                                <Field name='is_lite' onChange={onChangeIsLite}>
                                                    {props => (
                                                        <Switcher {...props}
                                                            data-testid='is_lite'
                                                            className='internal-options__field'
                                                            caption='Enable Access to HealthJoy QuickLaunch App'
                                                            wrapperClassName='mb-13'
                                                            disabled={isLiteDisabled}/>
                                                    )}
                                                </Field>

                                                <Field name='is_lite_permanently'>
                                                    {props => (
                                                        <Switcher {...props}
                                                            data-testid='is_lite_permanently'
                                                            disabled={!isLite}
                                                            className='internal-options__field'
                                                            wrapperClassName='mb-13'
                                                            caption="Don't show “Coming Soon” Section (permanent QuickLaunch)"/>
                                                    )}
                                                </Field>

                                                <Field name='lite_start_date'>
                                                    {props => (
                                                        <DatePicker {...props}
                                                            data-testid='lite_start_date'
                                                            disabled={!isLite}
                                                            maxDate={addDays(launchDate, -1)}
                                                            label='QuickLaunch Activation date'
                                                            placeholder='Choose date...'/>
                                                    )}
                                                </Field>
                                            </Column>
                                        </Row>
                                    </Column>
                                )}
                            </Row>
                        </form>

                        <AssignableActionBar {...actionBarProps}/>
                    </React.Fragment>
                );
            }}
        </Form>

    );
};

GeneralInfoStep.propTypes = {
    requestCompany: PropTypes.func.isRequired,
    requestCompanyUpdating: PropTypes.func.isRequired
};

export {GeneralInfoStep as TestableGeneralInfoStep};
export default GeneralInfoStep;
