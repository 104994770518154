export const getLoaderStatus = state => state.shared.loader.isLoader;

export const getPopupState = state => state.shared.popup;

export const getWizardActiveStep = state => state.shared.wizard.activeStep;

export const getCoreCarriers = state => state.shared.coreCarriers;

export const getAncillaryCarriers = state => state.shared.ancillaryCarriers;

export const getFormRevisions = state => state.shared.formRevisions;

export const getDirtyForms = state => state.shared.dirtyForms;
