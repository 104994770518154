import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {redirectTo} from '../../../actions/general';
import {requestContactList} from '../../../actions/contacts';
import {getContactList} from '../../../selectors/contacts';
import {getActiveCompanyName, getIsSuperAdmin} from '../../../selectors/general';
import {equal, groupBy} from '../../../utils';
import {ROUTES, USER_TYPES} from '../../../constants';
import './index.scss';

const INITIAL_CONTACT_GROUPS_STRUCTURE = {
    [USER_TYPES.employee]: [],
    [USER_TYPES.broker]: [],
    [USER_TYPES.other]: []
};

const ContactsBoard = ({onComplete}) => {
    const dispatch = useDispatch();
    const contactList = useSelector(getContactList);
    const companyName = useSelector(getActiveCompanyName);
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    useEffect(() => {
        dispatch(requestContactList());
    }, []);

    const onAddContact = type => () => dispatch(redirectTo(`${ROUTES.contactInfo}?userType=${type}`));

    const getContact = user => {
        const {id, title, full_name: name, user_type: type} = user;
        const isEmployee = equal(type, USER_TYPES.employee);
        const isEditable = isSuperAdmin || !isEmployee;

        return (
            <div className='board-contact' key={id}>
                <div className='board-contact-profile'>
                    <div className='board-contact-profile-avatar'>
                        <Icon className='board-contact-profile-avatar__placeholder' type={ICON_TYPES.avatar}/>
                    </div>

                    <div className='board-contact-profile__info'>
                        <Text className='board-contact-profile__name' type={TEXT_TYPES.bodyBold}>{name}</Text>

                        {title && <Sticker className='board-contact-profile__role'>{title}</Sticker>}
                    </div>

                    {isEditable && <Link className='board-contact-profile__link' href={`${ROUTES.contactInfo}?userType=${type}&userId=${id}`}>Edit</Link>}
                </div>
            </div>
        );
    };

    const contacts = useMemo(() => groupBy(contactList, 'user_type', INITIAL_CONTACT_GROUPS_STRUCTURE), [contactList]);

    return (
        <ContentSection className='contacts-board'>
            <Row>
                <Column sm={4} className='contacts-board-section'>
                    <Text className='contacts-board-section__title' type={TEXT_TYPES.bodyBold}>{companyName}</Text>

                    {contacts[USER_TYPES.employee].map(getContact)}

                    {isSuperAdmin && (
                        <Button className='contacts-board-section__button' type={BUTTON_TYPES.secondary} onClick={onAddContact(USER_TYPES.employee)}>
                            <Icon type={ICON_TYPES.circlePlus}/> Add contact
                        </Button>
                    )}
                </Column>

                <Column sm={4} className='contacts-board-section'>
                    <Text className='contacts-board-section__title' type={TEXT_TYPES.bodyBold}>Broker</Text>

                    {contacts[USER_TYPES.broker].map(getContact)}

                    <Button className='contacts-board-section__button' type={BUTTON_TYPES.secondary} onClick={onAddContact(USER_TYPES.broker)}>
                        <Icon type={ICON_TYPES.circlePlus}/> Add broker
                    </Button>
                </Column>

                <Column sm={4} className='contacts-board-section'>
                    <Text className='contacts-board-section__title' type={TEXT_TYPES.bodyBold}>Other (Ben-Admin, TPA)</Text>

                    {contacts[USER_TYPES.other].map(getContact)}

                    <Button className='contacts-board-section__button' type={BUTTON_TYPES.secondary} onClick={onAddContact(USER_TYPES.other)}>
                        <Icon type={ICON_TYPES.circlePlus}/> Add other
                    </Button>
                </Column>
            </Row>

            <Separator type='solid'/>

            <div className='contacts-board-action-bar'>
                <Button className='contacts-board-action-bar__button' type={BUTTON_TYPES.primary} onClick={onComplete}>Continue</Button>
            </div>
        </ContentSection>
    );
};

ContactsBoard.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export {ContactsBoard as TestableContactsBoard, INITIAL_CONTACT_GROUPS_STRUCTURE};
export default withSubStepCompletion(ContactsBoard);
