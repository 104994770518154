import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {trimStart} from '../../../utils';

const POLICY_HOLDER_TOOLTIP_CONTENT = 'If you’re unsure, most of the time this is the Client’s name.';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'policy_holder', label: 'Policyholder'},
    {name: 'group_policy', label: 'Group Policy'},
    {name: 'monthly_benefit', label: 'Monthly Benefit'},
    {name: 'benefits_begin', label: 'Benefits Begin'},
    {name: 'max_duration', label: 'Max Duration'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const LtdForm = ({card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo/>

            <CardHeaderInfo/>

            <CopyableField name='policy_holder' parse={trimStart}>
                {props => <Input {...props} placeholder='Please enter' label={<LabeledTooltip title='Policyholder' content={POLICY_HOLDER_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='group_policy' parse={trimStart}>
                {props => <Input {...props} placeholder='Please enter' label='Group Policy' wrapperClassName='mb-12'/>}
            </CopyableField>

            <Text type={TEXT_TYPES.bodyBold} className='ancillary-plan-details-form__title'>Benefit Summary</Text>

            <CopyableField name='monthly_benefit' parse={trimStart}>
                {props => <Input {...props} placeholder='Please enter specifics' label='Monthly Benefit' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='benefits_begin' parse={trimStart}>
                {props => <Input {...props} placeholder='ex: 7' label='Benefits Begin' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='max_duration' parse={trimStart}>
                {props => <Input {...props} placeholder='ex: Up to Social Security normal retirement age' label='Max Duration'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

LtdForm.propTypes = {
    card: PropTypes.string
};

export {LtdForm as TestableLtdForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(LtdForm);
