import {
    ADMIN_PORTAL_ROUTES,
    COMPANY_STAGES,
    PLAN_PERIOD_TYPES,
    LAUNCHED_TIMELINE_AVAILABILITY_PERIOD,
    ROUTES,
    STEP_ALIASES,
    TIMELINE_STEP_STATUSES,
    USER_ROLES,
    WORKSPACES
} from '../constants';
import {equal, getDifferenceInCalendarDays, getEqual, getItemKeyValue, negateFunc} from '../utils';

export const getWorkspace = state => state.general.workspace;

export const getIsAdminPortal = state => equal(getWorkspace(state), WORKSPACES.adminPortal);

export const getStepAlias = state => state.general.stepAlias;

export const getProfileInfo = state => state.general.profileInfo;

export const getCompaniesMap = state => state.general.companies.companiesMap;

export const getActiveCompany = state => state.general.companies.activeCompany;

export const getRedirectToUrl = state => state.general.redirectToUrl;

export const getActiveCompanyName = state => getActiveCompany(state).name;

export const getActiveCompanyStageCriteria = (state, stage) => {
    const {activity_stage: activityStage} = getActiveCompany(state);

    return equal(activityStage, stage);
};

export const getActiveCompanyPlanPeriods = state => getActiveCompany(state).all_plan_periods || [];

export const getActiveCompanyPlanPeriodById = (state, id) => getActiveCompanyPlanPeriods(state).find(getEqual(parseInt(id, 10), 'id')) || {};

export const getPlanPeriodTypeCriteria = (state, periodId, type) => {
    const planPeriods = getActiveCompanyPlanPeriods(state);
    const planPeriodType = planPeriods.find(getEqual(parseInt(periodId, 10), 'id'))?.status;

    return equal(planPeriodType, type);
};

export const getIsSuperAdmin = state => getProfileInfo(state).isSuperAdmin;

export const getRoleInActiveCompany = state => {
    const {id: companyId} = getActiveCompany(state);
    const {role_per_company: roleByCompany = {}} = getProfileInfo(state);

    return roleByCompany[companyId];
};

export const getTypeInActiveCompany = state => {
    const {id: companyId} = getActiveCompany(state);
    const {user_type_per_company: typeByCompany = {}} = getProfileInfo(state);

    return typeByCompany[companyId];
};

export const getRoleByCompanyCriteria = (state, role) => equal(getRoleInActiveCompany(state), role);

export const getTimeline = state => state.general.timeline;

export const getTimelineExistenceStatus = state => getTimeline(state).isExisted;

export const getTimelineSteps = state => getTimeline(state).steps;

export const getTimelineOwner = state => getTimeline(state).owner;

export const getTimelineImplementationManager = state => getTimeline(state).implementation_manager;

export const getTimelineImplementationSpecialists = state => getTimeline(state).implementation_specialists;

export const getTimelineId = state => getTimeline(state).id;

export const getProcessedTimelineStep = state => {
    const steps = getTimelineSteps(state);
    const stepAlias = getStepAlias(state);

    return steps.find(getEqual(stepAlias, 'url'));
};

export const getProcessedTimelineSubStep = state => {
    const {children: subSteps} = getProcessedTimelineStep(state) || {children: []};
    const subStepAlias = window.location.pathname;

    return subSteps.find(getEqual(subStepAlias, 'url'));
};

export const getPriorityTimelineStep = state => {
    const steps = getTimelineSteps(state);

    return steps.find(negateFunc(getEqual(TIMELINE_STEP_STATUSES.completed, 'status'))) || {};
};

export const getOverviewCompletionStatus = state => {
    const steps = getTimelineSteps(state);
    const benefitsOverviewStep = steps.find(getEqual(STEP_ALIASES.benefitsOverview, 'url'));
    const renewalOverviewStep = steps.find(getEqual(STEP_ALIASES.renewalOverview, 'url'));
    const {status} = benefitsOverviewStep || renewalOverviewStep || {};

    return equal(status, TIMELINE_STEP_STATUSES.completed);
};

export const getIsLaunchDatePassed = (state, daysCount = 1) => {
    const {launch_date: launchDate} = getTimeline(state);
    const today = new Date();

    return getDifferenceInCalendarDays(today, launchDate) >= daysCount;
};

export const getPassLength = state => state.authorization.passLength;

export const getMarketingPages = state => state.general.marketingPages;

export const getIsBroadcasterOnboarded = state => {
    const {is_broadcaster_onboarded: isBroadcasterOnboarded} = getProfileInfo(state);

    return isBroadcasterOnboarded;
};

export const getIsBroadcasterApprovalProcess = state => {
    const {is_broadcaster_approval_process_enabled: isBroadcasterApprovalProcess} = getProfileInfo(state);

    return isBroadcasterApprovalProcess;
};

export const getIsPlanAdditionAndDeletionAllowed = (state, planPeriodId) => {
    const {
        is_implementation_manager: isImplementationManager,
        is_implementation_specialist: isImplementationSpecialist
    } = getProfileInfo(state);
    const [isLaunchPlanPeriod, isRenewalPlanPeriod] = [
        PLAN_PERIOD_TYPES.launch,
        PLAN_PERIOD_TYPES.renewal
    ].map(planPeriodType => getPlanPeriodTypeCriteria(state, planPeriodId, planPeriodType));
    const {remove_restrictions_for_plan_add_delete: isPlanAdditionAndDeletionAllowedByCompany} = getActiveCompany(state);

    return (isImplementationManager || isImplementationSpecialist) || (isLaunchPlanPeriod || isRenewalPlanPeriod) || isPlanAdditionAndDeletionAllowedByCompany;
};

export const getRoutesUnavailabilityMap = state => {
    const isSuperAdmin = getIsSuperAdmin(state);
    const isCompanyAdmin = getRoleByCompanyCriteria(state, USER_ROLES.admin);
    const isLaunchDatePassed = getIsLaunchDatePassed(state);
    const isTimelineUnavailable = getIsLaunchDatePassed(state, LAUNCHED_TIMELINE_AVAILABILITY_PERIOD);
    const isCompanyLaunching = getActiveCompanyStageCriteria(state, COMPANY_STAGES.launch);
    const isReportingUnavailable = isCompanyLaunching && !isLaunchDatePassed;
    const isBroadcasterUnavailable = isCompanyLaunching && !isLaunchDatePassed;
    const adminPortalUnavailabilityMap = ADMIN_PORTAL_ROUTES.reduce((acc, route) => ({...acc, [route]: [{isUnavailable: !isSuperAdmin, reserveRoute: ROUTES.root}]}), {});

    const UNAVAILABILITY_MAP = {
        [ROUTES.root]: [{isUnavailable: true, reserveRoute: isLaunchDatePassed ? ROUTES.summaryReporting : ROUTES.timeline}],
        [ROUTES.adminPortalRoot]: [{isUnavailable: true, reserveRoute: isSuperAdmin ? ROUTES.planManager : ROUTES.root}],
        [ROUTES.timeline]: [{isUnavailable: isTimelineUnavailable, reserveRoute: ROUTES.summaryReporting}],
        [ROUTES.timelineGeneration]: [{isUnavailable: !isSuperAdmin, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [ROUTES.summaryReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [ROUTES.programSavingsReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [ROUTES.utilizationReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [ROUTES.memberActivationReporting]: [
            {isUnavailable: !isSuperAdmin && !isCompanyAdmin, isHidden: true, reserveRoute: ROUTES.root},
            {isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}
        ],
        [ROUTES.reportDownloadsReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [ROUTES.broadcasterWelcome]: [{isUnavailable: isBroadcasterUnavailable, reserveRoute: ROUTES.pageNotFound}],
        [`${ROUTES.broadcastEditor}/:id?`]: [{isUnavailable: isBroadcasterUnavailable, reserveRoute: ROUTES.pageNotFound}],
        [`${ROUTES.broadcastDetails}/:id`]: [{isUnavailable: isBroadcasterUnavailable, reserveRoute: ROUTES.pageNotFound}],
        ...adminPortalUnavailabilityMap
    };

    return Object.entries(UNAVAILABILITY_MAP).reduce((acc, [route, unavailabilityItems]) => {
        return {...acc, [route]: unavailabilityItems.find(getItemKeyValue('isUnavailable')) || {}};
    }, {});
};
