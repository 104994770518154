import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import {Field, Form} from '../../shared/FormComponents';
import FileUploader from '../../shared/FileUploader';
import withToast from '../../../HOC/withToast';
import {
    requestImportsJsonrpc,
    requestTPAPartnerConfig,
    requestTPAPartnerImportSession,
    requestTPAPartnerImportSessionCreating,
    requestTPAPartnerImportSessions,
    requestTPAPartnerImportSessionUpdating
} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {FILE_FORMATS, FORMS, MAX_TPA_FILE_SIZE, ROUTES} from '../../../constants';
import {equal, generateUniqueId, parseQuery, validateRequired} from '../../../utils';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    return {
        file_path: validateRequired(values.file_path)
    };
};

const TPAUploadFileForm = ({onChangeActiveStep, showToast}) => {
    const {partner: partnerId} = useParams();
    const [partnerConfigId, setPartnerConfigId] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const {full_name: userFullName} = useSelector(getProfileInfo);
    const dispatch = useDispatch();
    const {search} = useLocation();
    const partnerName = parseQuery(search).partner_name;

    useEffect(() => {
        (async () => {
            const {data, isSuccess: isPartnerSuccess} = await dispatch(requestTPAPartnerConfig({tpaPartnerId: partnerId}));

            if (isPartnerSuccess) {
                setPartnerConfigId(data[0]?.id);
            }

            const {sessions, isSuccess: isSuccessSessions} = await dispatch(requestTPAPartnerImportSessions({limit: 1, tpa_partner_id: partnerId, only_active: false, isLoader: true, isCancelable: false}));
            const {id: sessionId} = sessions?.data[0] || {};

            if (!isSuccessSessions || !sessionId) {
                return false;
            }

            const {session, isSuccess} = await dispatch(requestTPAPartnerImportSession(sessionId));

            if (isSuccess && equal(session?.status, 'new')) {
                setInitialValues(session);
            }
        })();
    }, [dispatch, partnerId]);

    const runTPAImport = useCallback(async session => {
        const jsonrpcObj = {jsonrpc: '2.0', method: 'run_tpa_preprocessing', id: generateUniqueId(), params: {session}};
        const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        if (!equal(jsonrpc?.result, 'ok')) {
            return false;
        }

        dispatch(redirectTo(`${ROUTES.importsTpa}/${partnerId}${search}&show_submit_alert=true`));
    }, [dispatch, partnerId, search]);

    const onSubmit = useCallback(async ({is_submit: isSubmit, ...values}) => {
        const {id: importId} = initialValues || {};
        const requestImportSession = (importId && equal(values?.status, 'new')) ? requestTPAPartnerImportSessionUpdating : requestTPAPartnerImportSessionCreating;
        const enhancedValues = importId ? values : {
            ...values,
            tpa_partner_id: partnerId,
            tpa_partner_config_id: partnerConfigId,
            additional_data: {
                tpa_partner_name: partnerName,
                created_by: userFullName
            }
        };
        const {session, isSuccess, submissionErrors} = await dispatch(requestImportSession(enhancedValues, importId));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues({is_submit: false, ...session});
        showToast({content: 'Progress Saved'});

        if (isSubmit) {
            runTPAImport(session);
        }
    }, [dispatch, initialValues, partnerId, userFullName, partnerConfigId, partnerName, runTPAImport]);

    return (
        <Form name={FORMS.uploadTPAFile} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, valid, form}) => {
                const onRunTPAImport = () => {
                    form.change('is_submit', true);
                    handleSubmit();
                };

                return (
                    <form className='tpa-upload-file-form' onSubmit={handleSubmit}>
                        <Field name='is_submit'>{props => <Input {...props} type='hidden'/>}</Field>

                        <ContentSection className='mt-20'>
                            <Field name='file_path'>
                                {props => <FileUploader {...props} isPrivateFile maxSize={MAX_TPA_FILE_SIZE} accept={[FILE_FORMATS.csv, FILE_FORMATS.txt, FILE_FORMATS.xls, FILE_FORMATS.xlsx].map(format => `.${format}`)} label='Upload File' isRequired/>}
                            </Field>
                        </ContentSection>

                        <div className='action-bar'>
                            <Button data-testid='back-tpa-button' className='action-bar__button' type={BUTTON_TYPES.secondary} onClick={() => onChangeActiveStep('configure')}>Back to TPA Config</Button>
                            <Button data-testid='save-button' isSubmit className='action-bar__button' disabled={!valid} type={BUTTON_TYPES.secondary}>Save Progress</Button>
                            <Button data-testid='submit-button' onClick={onRunTPAImport} disabled={!valid} className='action-bar__button'>Submit</Button>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

TPAUploadFileForm.propTypes = {
    onChangeActiveStep: PropTypes.func,
    showToast: PropTypes.func
};

export {TPAUploadFileForm as TestableTPAUploadFileForm};
export default withToast(TPAUploadFileForm);
