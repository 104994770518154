import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import classnames from 'classnames';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Row from '@frontend/ui-kit/Components/Row';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import SFTPNewCredentialsPopup from '../SFTPNewCredentialsPopup';
import SFTPWhitelistPopup from '../SFTPWhitelistPopup';
import SFTPLinkGenerationPopup from '../SFTPLinkGenerationPopup';
import withToast from '../../../../HOC/withToast';
import CustomizablePopup from '../../../shared/CustomizablePopup';
import {Field, FieldArray} from '../../../shared/FormComponents';
import LabeledTooltip from '../../../shared/LabeledTooltip';
import {requestSFTPCredentials, requestSFTPCredentialsCreation, requestSFTPCredentialsList, requestSFTPPasswordLinkCreation, requestSFTPTemporaryPasswordCreation} from '../../../../actions/adminPortal';
import useForm from '../../../../hooks/useForm';
import useFormState from '../../../../hooks/useFormState';
import {getDefaultSFTPCredentialsInfo} from '../../../../helpers';
import {
    validateRequired,
    validateSFTPUsername,
    validateMinLength,
    promisifyAsyncFunction,
    pass,
    equal,
    getEqual,
    getFromObjSafe,
    formatDate,
    delay,
    isEmptyNested
} from '../../../../utils';
import {SFTP_HOST_TYPES, SFTP_SETTINGS_TYPES, SFTP_USERNAME_MIN_LENGTH, SFTP_USERNAME_MAX_LENGTH} from '../../../../constants';
import './index.scss';

const TOOLTIP_DELAY = 5000;
const TOAST_VISIBILITY_TIME = 5000;
const ADD_NEW_OPTION_VALUE = Symbol('add_new');
const LINKED_CREDENTIALS_AFFECTABLE_FIELDS = ['id', 'companies', 'username', 'multi_client_feed_name', 'is_multi_client_feed', 'password', 'passgen_link', 'password_updated_at', 'notes', 'ip_whitelist', 'is_ben_admin'];

/* istanbul ignore next */
const validate = (values, index, credentials) => {
    const [isInternalHost, isTPAHost] = [SFTP_HOST_TYPES.internal, SFTP_HOST_TYPES.tpa].map(getEqual(values.host_type));
    const restMultiClientFeedNamesByHostType = credentials.reduce((acc, {multi_client_feed_name, host_type}, credentialsInfoIndex) => {
        const isSameHostType = equal(values.host_type, host_type);
        return isSameHostType && !equal(index, credentialsInfoIndex) && multi_client_feed_name
            ? [...acc, multi_client_feed_name]
            : acc;
    }, []);

    return {
        username: validateRequired(values.username) || validateMinLength(values.username, SFTP_USERNAME_MIN_LENGTH) || validateSFTPUsername(values.username),
        multi_client_feed_name: (isInternalHost || isTPAHost) && restMultiClientFeedNamesByHostType.includes(values.multi_client_feed_name)
            ? 'These linked credentials are already in use for this client'
            : undefined,
        password: !isInternalHost ? validateRequired(values.password) : undefined,
        settings: values.settings.map(settingsInfo => {
            const {type, active, path, file_pattern: filePattern} = settingsInfo;

            return {
                path: active ? validateRequired(path) : undefined,
                file_pattern: active && !equal(type, SFTP_SETTINGS_TYPES.reporting) ? validateRequired(filePattern) : undefined
            };
        })
    };
};

const SFTPCredentialsInfo = ({fieldName, settingsActivity, isEditDisabled, isCompanyLaunching, closePopup, openPopup, showToast}) => {
    const dispatch = useDispatch();
    const {companyAlias} = useParams();
    const form = useForm();
    const {values, errors} = useFormState();
    const [linkedCredentialsList, setLinkedCredentialsList] = useState([]);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [fieldTooltipContent, setFieldTooltipContent] = useState('Copy');
    const [temporaryPasswordTooltipContent, setTemporaryPasswordTooltipContent] = useState(null);
    const credentialsInfo = getFromObjSafe(fieldName, values);
    const credentialsInfoErrors = getFromObjSafe(fieldName, errors);
    const defaultSFTPCredentialsInfo = getDefaultSFTPCredentialsInfo(credentialsInfo.host_type);
    const affectableClients = credentialsInfo.is_multi_client_feed ? credentialsInfo.companies : null;
    const [isInternalHost, isTPAHost] = [SFTP_HOST_TYPES.internal, SFTP_HOST_TYPES.tpa].map(getEqual(credentialsInfo.host_type));

    const getCopyableFieldProps = value => {
        return {
            tooltipProps: {content: fieldTooltipContent, hideOnClick: false, onHidden: () => setFieldTooltipContent('Copy')},
            icon: ICON_TYPES.copy,
            onIconClick: () => {
                navigator.clipboard.writeText(value ?? '');
                setFieldTooltipContent('Copied to Clipboard');
            }
        };
    };

    const openNotificationPopup = ({title, content}) => {
        const actionBar = <Button onClick={closePopup} type={BUTTON_TYPES.primary}>OK</Button>;
        const popupProps = {title, content, actionBar};
        const children = <CustomizablePopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const getSFTPCredentialsId = async ({id, host_type, host, username}) => {
        if (id) {
            return id;
        }

        const {data, isSuccess, submissionErrors} = await dispatch(requestSFTPCredentialsCreation({company_alias: companyAlias, host_type, host, username}));

        if (!isSuccess) {
            const [errorMessage] = Object.values(submissionErrors) ?? [];
            openNotificationPopup({title: 'Validation Error', content: errorMessage});

            return null;
        }

        return data.id;
    };

    const openSFTPNewCredentialsPopup = () => {
        const onSave = async id => {
            const {data} = await dispatch(requestSFTPCredentials(id));

            form.batch(() => {
                LINKED_CREDENTIALS_AFFECTABLE_FIELDS.forEach(name => form.change(`${fieldName}.${name}`, data[name]));
                form.change(`${fieldName}.settings`, defaultSFTPCredentialsInfo.settings);
            });

            closePopup();
            showToast({content: 'Linked credentials added.', visibilityTime: TOAST_VISIBILITY_TIME});
        };
        const initialValues = {host_type: credentialsInfo.host_type, host: credentialsInfo.host};

        return openPopup({type: POPUP_TYPES.simple, children: <SFTPNewCredentialsPopup initialValues={initialValues} onClose={closePopup} onSave={onSave}/>});
    };

    const openSFTPWhitelistPopup = () => {
        const onSave = ({ip_whitelist, is_ben_admin}) => {
            form.batch(() => {
                form.change(`${fieldName}.ip_whitelist`, ip_whitelist);
                form.change(`${fieldName}.is_ben_admin`, is_ben_admin);
            });

            closePopup();
            showToast({content: 'Changes saved.', visibilityTime: TOAST_VISIBILITY_TIME});
        };
        const initialValues = {
            ip_whitelist: credentialsInfo.ip_whitelist,
            is_ben_admin: credentialsInfo.is_ben_admin
        };

        return openPopup({type: POPUP_TYPES.simple, children: <SFTPWhitelistPopup initialValues={initialValues} affectableClients={affectableClients} onClose={closePopup} onSave={onSave}/>});
    };

    const openSFTPLinkGenerationPopup = () => {
        const onContinue = async () => {
            const credentialsId = await getSFTPCredentialsId(credentialsInfo);
            if (!credentialsId) {
                return;
            }

            const {data} = await dispatch(requestSFTPPasswordLinkCreation(credentialsId));

            form.change(`${fieldName}.id`, credentialsId);
            form.change(`${fieldName}.passgen_link`, data.link);

            openNotificationPopup({title: 'Changes saved', content: 'SFTP credentials saved'});
        };

        return openPopup({type: POPUP_TYPES.simple, children: <SFTPLinkGenerationPopup affectableClients={affectableClients} onClose={closePopup} onContinue={onContinue}/>});
    };

    const loadLinkedCredentialsOptions = promisifyAsyncFunction(async query => {
        // FYI: isCancelable: !!query is needed to be sure that defaultOptions will be loaded if there are several same autocompletes on page (07.07.2022, Oleh)
        const isCancelable = !!query;
        const {data: linkedCredentialsList} = await dispatch(requestSFTPCredentialsList({
            hostType: credentialsInfo.host_type,
            isMultiClient: true,
            multiClientFeedNameQuery: query
        }, isCancelable));
        const linkedCredentialsOptions = linkedCredentialsList.map(({multi_client_feed_name: label}) => ({label, value: label}));

        setLinkedCredentialsList(linkedCredentialsList);

        return [{label: 'Add New', value: ADD_NEW_OPTION_VALUE}, ...linkedCredentialsOptions];
    });
    const onChangeLinkedCredentials = value => {
        if (equal(value, ADD_NEW_OPTION_VALUE)) {
            // FYI: we have to preserve current field value until new creds are created (10.07.2023, Oleh)
            form.change(`${fieldName}.multi_client_feed_name`, credentialsInfo.multi_client_feed_name);

            openSFTPNewCredentialsPopup();
            return;
        }

        const data = linkedCredentialsList.find(getEqual(value, 'multi_client_feed_name')) ?? {};

        form.batch(() => {
            LINKED_CREDENTIALS_AFFECTABLE_FIELDS.forEach(name => form.change(`${fieldName}.${name}`, data[name]));
            form.change(`${fieldName}.settings`, defaultSFTPCredentialsInfo.settings);
        });
    };
    const onClearLinkedCredentials = () => {
        form.batch(() => {
            LINKED_CREDENTIALS_AFFECTABLE_FIELDS.forEach(name => {
                const defaultValue = Object.hasOwn(defaultSFTPCredentialsInfo, name) ? defaultSFTPCredentialsInfo[name] : null;

                form.change(`${fieldName}.${name}`, defaultValue);
            });
            form.change(`${fieldName}.settings`, defaultSFTPCredentialsInfo.settings);
        });
    };

    const onGenerateTemporaryPassword = async () => {
        const credentialsId = await getSFTPCredentialsId(credentialsInfo);
        if (!credentialsId) {
            return;
        }

        const {data} = await dispatch(requestSFTPTemporaryPasswordCreation(credentialsId));

        form.change(`${fieldName}.id`, credentialsId);
        navigator.clipboard.writeText(data.temporary_password ?? '');
        setTemporaryPasswordTooltipContent('Generated and Copied to Clipboard. Temporary passwords are valid for 1 hour.');
        delay(() => setTemporaryPasswordTooltipContent(null), TOOLTIP_DELAY);

        openNotificationPopup({title: 'Changes saved', content: 'SFTP credentials saved'});
    };

    return (
        <div className='sftp-credentials-info mt-8'>
            <Field name={`${fieldName}.host_type`}>{props => <Input {...props} type='hidden'/>}</Field>

            <Row>
                <Column sm={4}>
                    <Field name={`${fieldName}.host`}>
                        {props => <Input {...props} label='Host' disabled data-testid='host-field'/>}
                    </Field>
                </Column>

                {(isInternalHost || isTPAHost) && (
                    <Column sm={4}>
                        <Field name={`${fieldName}.multi_client_feed_name`} onChange={onChangeLinkedCredentials}>
                            {props => (
                                <AsyncAutocomplete {...props}
                                    loadOptions={loadLinkedCredentialsOptions}
                                    label='Linked Credentials'
                                    disabled={isEditDisabled}
                                    defaultOptions
                                    filterOption={pass}
                                    isCreatable={false}
                                    description={(
                                        <div className='interactive-description interactive-description_with-text'>
                                            <Text type={TEXT_TYPES.helper}>These are credentials shared across multiple clients</Text>
                                            <Button className='interactive-description__button' disabled={!props.value} type={BUTTON_TYPES.tertiary} onClick={onClearLinkedCredentials}>Clear</Button>
                                        </div>
                                    )}/>
                            )}
                        </Field>
                    </Column>
                )}

                <Column sm={4}>
                    <Field name={`${fieldName}.username`}>
                        {props => <Input {...props} label='Login' placeholder='' maxLength={SFTP_USERNAME_MAX_LENGTH} disabled={isEditDisabled || credentialsInfo.id} {...getCopyableFieldProps(props.value)}/>}
                    </Field>
                </Column>

                {!isInternalHost && (
                    <Column sm={4}>
                        <Field name={`${fieldName}.password`}>
                            {props => (
                                <Input {...props}
                                    label='Password'
                                    type={isPasswordShown && !credentialsInfo.id ? 'text' : 'password'}
                                    placeholder=''
                                    autoComplete='new-password'
                                    maxLength={128}
                                    disabled={isEditDisabled || credentialsInfo.id}
                                    {...getCopyableFieldProps(props.value)}
                                    description={(
                                        <div className='interactive-description'>
                                            {!credentialsInfo.id && (
                                                <Button className='interactive-description__button' data-testid='toggle-password-button' type={BUTTON_TYPES.tertiary} onClick={() => setIsPasswordShown(shown => !shown)}>
                                                    {isPasswordShown ? 'Hide' : 'Show'}
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                    data-testid='password-field'/>
                            )}
                        </Field>
                    </Column>
                )}
            </Row>

            {isInternalHost && (
                <Row className='mt-24'>
                    <Column sm={12}>
                        <Field name={`${fieldName}.passgen_link`}>
                            {props => (
                                <Input {...props}
                                    label='Client Password Link'
                                    placeholder=''
                                    disabled
                                    {...getCopyableFieldProps(props.value)}
                                    description={(
                                        <div className='interactive-description interactive-description_with-text'>
                                            <Text type={TEXT_TYPES.helper}>
                                                {credentialsInfo.password_updated_at && `Password set on ${formatDate(credentialsInfo.password_updated_at, 'M/d/yyyy')}`}
                                                {(!credentialsInfo.password_updated_at && !props.value) && 'Password link does not exist or is no longer valid'}
                                            </Text>
                                            <Button className='interactive-description__button' disabled={!!credentialsInfoErrors.username} type={BUTTON_TYPES.tertiary} onClick={openSFTPLinkGenerationPopup}>
                                                Generate New Password Link
                                            </Button>
                                        </div>
                                    )}/>
                            )}
                        </Field>
                    </Column>
                </Row>
            )}

            <Row className='mt-24'>
                {isInternalHost && (
                    <Column sm={4}>
                        <Tooltip isOpened={!!temporaryPasswordTooltipContent} content={<Text type={TEXT_TYPES.helperBold}>{temporaryPasswordTooltipContent}</Text>} isClickable>
                            <Button type={BUTTON_TYPES.secondary} disabled={!!credentialsInfoErrors.username} onClick={onGenerateTemporaryPassword}>
                                Generate Temporary Password
                            </Button>
                        </Tooltip>
                    </Column>
                )}

                <Column sm={8}>
                    <Field name={`${fieldName}.notes`}>
                        {props => <Input {...props} label='SFTP Notes' maxLength={200} disabled={isEditDisabled}/>}
                    </Field>
                </Column>
            </Row>

            {isInternalHost && (
                <React.Fragment>
                    <Separator className='mt-24 mb-24'/>

                    <Collapse hasCollapseIcon
                        isOpened
                        initiator={<Text type={TEXT_TYPES.bodyBold}>Whitelist</Text>}
                        actionBar={(
                            <Button disabled={isEditDisabled} type={BUTTON_TYPES.tertiary} onClick={openSFTPWhitelistPopup}>
                                <Icon type={ICON_TYPES.edit}/> Edit Whitelist
                            </Button>
                        )}
                        content={(
                            <React.Fragment>
                                <FieldArray name={`${fieldName}.ip_whitelist`}>
                                    {({fields = []}) => (
                                        <Row className='mb-16' rowGap='md'>
                                            {fields.map(field => (
                                                <Column key={field} sm={4}>
                                                    <Field name={field}>{props => <Input {...props} disabled/>}</Field>
                                                </Column>
                                            ))}
                                        </Row>
                                    )}
                                </FieldArray>
                                <Field name={`${fieldName}.is_ben_admin`}>
                                    {props => <Checkbox {...props} caption='Allow Ben Admin Access to SFTP folder' disabled/>}
                                </Field>
                            </React.Fragment>
                        )}/>
                </React.Fragment>
            )}

            <FieldArray name={`${fieldName}.settings`}>
                {({fields, meta = {}}) => {
                    const {active: isMemberEligibilityActive} = fields.value.find(getEqual(SFTP_SETTINGS_TYPES.memberEligibility, 'type')) ?? {};
                    const cobraEligibilityIndex = fields.value.findIndex(getEqual(SFTP_SETTINGS_TYPES.cobraEligibility, 'type'));

                    return (
                        <React.Fragment>
                            {fields.map((field, index) => {
                                const isValid = !meta.submitFailed || isEmptyNested(meta.error?.[index]);
                                const {type} = fields.value[index];
                                const [isMemberEligibility, isFinancialEligibility, isCobraEligibility] = [
                                    SFTP_SETTINGS_TYPES.memberEligibility,
                                    SFTP_SETTINGS_TYPES.financialEligibility,
                                    SFTP_SETTINGS_TYPES.cobraEligibility
                                ].map(getEqual(type));
                                const typeLabel = {
                                    [SFTP_SETTINGS_TYPES.memberEligibility]: 'Member Eligibility',
                                    [SFTP_SETTINGS_TYPES.financialEligibility]: 'Deductible Import',
                                    [SFTP_SETTINGS_TYPES.cobraEligibility]: 'COBRA',
                                    [SFTP_SETTINGS_TYPES.reporting]: 'Reporting'
                                }[type];
                                const isAvailable = !settingsActivity[type] && (!isCobraEligibility || isMemberEligibilityActive);
                                const onChangeActivity = ({target}) => {
                                    if (!isMemberEligibility || target.checked) {
                                        return;
                                    }

                                    fields.update(cobraEligibilityIndex, {...fields.value[cobraEligibilityIndex], active: false});
                                };

                                return (
                                    <React.Fragment key={field}>
                                        <Separator className='mt-24 mb-24'/>

                                        <Collapse hasCollapseIcon
                                            initiator={(
                                                <React.Fragment>
                                                    <Field name={`${field}.active`} onChange={onChangeActivity}>
                                                        {props => (
                                                            <Checkbox {...props}
                                                                caption={<LabeledTooltip title={`Use Credentials for ${typeLabel} Automation`} content={isMemberEligibility && isCompanyLaunching ? 'This group has not launched yet. You cannot check this box until after launch day.' : null}/>}
                                                                disabled={isEditDisabled || (!isAvailable && !props.value) || (isMemberEligibility && isCompanyLaunching)}
                                                                wrapperClassName={classnames('collapse-checkbox', {'collapse-checkbox_invalid': !isValid})}/>
                                                        )}
                                                    </Field>

                                                    {isCobraEligibility && (
                                                        <Tooltip className='settings-tooltip' content='Member Eligibility Automation must be checked in order to enable COBRA Automation.'>
                                                            <Icon className='settings-tooltip__icon' type={ICON_TYPES.info}/>
                                                        </Tooltip>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            content={(
                                                <React.Fragment>
                                                    <Field name={`${field}.id`}>{props => <Input {...props} type='hidden'/>}</Field>
                                                    <Field name={`${field}.type`}>{props => <Input {...props} type='hidden'/>}</Field>

                                                    <Row>
                                                        <Column sm={4}>
                                                            <Field name={`${field}.path`}>
                                                                {props => <Input {...props} label={`SFTP ${typeLabel} Path`} maxLength={128} disabled={isEditDisabled} {...getCopyableFieldProps(props.value)}/>}
                                                            </Field>
                                                        </Column>

                                                        {(isMemberEligibility || isFinancialEligibility || isCobraEligibility) && (
                                                            <Column sm={4}>
                                                                <Field name={`${field}.file_pattern`}>
                                                                    {props => <Input {...props} label={`${typeLabel} File Pattern`} maxLength={128} disabled={isEditDisabled}/>}
                                                                </Field>
                                                            </Column>
                                                        )}
                                                    </Row>

                                                    {(isMemberEligibility || isFinancialEligibility || isCobraEligibility) && (
                                                        <Row className='mt-24'>
                                                            <Column sm={8}>
                                                                <Field name={`${field}.notes`}>
                                                                    {props => <Input {...props} label={`${typeLabel} Automation Notes`} maxLength={200} disabled={isEditDisabled}/>}
                                                                </Field>
                                                            </Column>
                                                        </Row>
                                                    )}
                                                </React.Fragment>
                                            )}/>
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                }}
            </FieldArray>
        </div>
    );
};

SFTPCredentialsInfo.propTypes = {
    fieldName: PropTypes.string.isRequired,
    settingsActivity: PropTypes.shape({
        member_eligibility: PropTypes.bool.isRequired,
        financial_eligibility: PropTypes.bool.isRequired,
        cobra_eligibility: PropTypes.bool.isRequired,
        reporting: PropTypes.bool.isRequired
    }).isRequired,
    isEditDisabled: PropTypes.bool.isRequired,
    isMemberEligibilityDisabled: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    showToast: PropTypes.func
};

export {validate, SFTPCredentialsInfo as TestableSFTPCredentialsInfo};
export default withToast(SFTPCredentialsInfo);
