import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Text from '@frontend/ui-kit/Components/Text';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import TimelineTimestamp from '../TimelineTimestamp';
import TimelineStepInfo from '../TimelineStepInfo';
import {requestTimelineUpdating, setExpandedTimelineStep} from '../../../actions/home';
import {requestTimelineStepUpdating} from '../../../actions/general';
import {getExpandedTimelineStep} from '../../../selectors/home';
import {
    getActiveCompanyStageCriteria,
    getIsSuperAdmin,
    getOverviewCompletionStatus,
    getPriorityTimelineStep,
    getTimeline
} from '../../../selectors/general';
import {equal, getEqual, isEmpty} from '../../../utils';
import {COMPANY_STAGES, STEP_ALIASES, TIMELINE_STEP_STATUSES} from '../../../constants';
import './index.scss';

const Timeline = () => {
    const dispatch = useDispatch();
    const timeline = useSelector(getTimeline);
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const expandedStep = useSelector(getExpandedTimelineStep);
    const isOverviewCompleted = useSelector(getOverviewCompletionStatus);
    const priorityStep = useSelector(getPriorityTimelineStep);
    const isCompanyRenewing = useSelector(state => getActiveCompanyStageCriteria(state, COMPANY_STAGES.renewal));

    const {steps, launch_date: launchDate} = timeline;

    const expandProcessedStep = () => {
        const startedStep = timeline.steps.findIndex(getEqual(TIMELINE_STEP_STATUSES.started, 'status'));

        dispatch(setExpandedTimelineStep(Math.max(0, startedStep)));
    };

    const getStep = (step, index, steps) => {
        const {id, title, status, url: alias, due_date: dueDate} = step;
        const isLastStep = equal(index, steps.length - 1);
        const isExpandedStep = equal(expandedStep, index);
        const isOverviewStep = [STEP_ALIASES.benefitsOverview, STEP_ALIASES.renewalOverview].some(getEqual(alias));
        const isUpdatableStep = isOverviewCompleted || isOverviewStep;
        const expandedClassName = isExpandedStep ? 'timeline-step_expanded' : '';
        const priorityClassName = equal(priorityStep.id, id) ? 'timeline-step_priority' : '';
        const onExpandStep = () => dispatch(setExpandedTimelineStep(index));
        const onChangeDueDate = dueDate => dispatch(requestTimelineStepUpdating(id, {due_date: dueDate}));
        const onChangeLaunchDate = launchDate => dispatch(requestTimelineUpdating({launch_date: launchDate}));
        const timelineStepInfoProps = {...step, isUpdatableStep};
        const datePickerProps = {
            className: 'timeline-step__date',
            value: dueDate,
            onChange: isLastStep ? onChangeLaunchDate : onChangeDueDate,
            customInput: <TimelineTimestamp status={status}/>,
            popperPlacement: 'right-start',
            disabled: !isSuperAdmin || isLastStep
        };

        return (
            <li key={index} className={`timeline-step timeline-step_${status} ${expandedClassName} ${priorityClassName}`}>
                <DatePicker {...datePickerProps}/>

                {isExpandedStep && <TimelineStepInfo {...timelineStepInfoProps}/>}

                {!isExpandedStep && <Text className='timeline-step__title' onClick={onExpandStep}>{title}</Text>}
            </li>
        );
    };

    useEffect(() => {
        expandProcessedStep();
    }, [timeline.id]);

    const enhancedSteps = isEmpty(steps) ? steps : [...steps, {
        status: TIMELINE_STEP_STATUSES.goal,
        due_date: launchDate,
        title: isCompanyRenewing ? 'Renewal' : 'Launch'
    }];

    return (
        <div className='timeline'>
            <ul className='timeline-steps'>{enhancedSteps.map(getStep)}</ul>
        </div>
    );
};

export default Timeline;
