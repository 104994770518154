import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import ContactInfoBasicForm from '../ContactInfoBasicForm';
import {redirectTo} from '../../../actions/general';
import {requestContactInfo, requestContactUpdating} from '../../../actions/contacts';
import {ROUTES, FORMS} from '../../../constants';

const ExistedContactInfoForm = ({id, type}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestContactInfo(id));

            setInitialValues(data);
        })();
    }, []);

    const onSubmit = async values => {
        const {isSuccess, data, submissionErrors} = await dispatch(requestContactUpdating({...values, user_id: id}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
    };

    const onSubmitSuccess = () => dispatch(redirectTo(ROUTES.contacts));

    const basicFormProps = {id, formName: FORMS.existedContactInfo, initialValues, type, isExisted: true, onSubmit, onSubmitSuccess};

    return <ContactInfoBasicForm {...basicFormProps}/>;
};

ExistedContactInfoForm.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

export {ExistedContactInfoForm as TestableExistedContactInfoForm};
export default ExistedContactInfoForm;
