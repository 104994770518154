import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import './index.scss';

const ContactDeletionPopup = ({fullName, companyName, onDelete}) => {
    const actionBar = <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>;
    const props = {title: 'Confirmation', actionBar};

    return (
        <PopupContent {...props}>
            <Text className='contact-deletion-popup-body'>
                Are you sure you want to delete this contact? {fullName} will no longer be able to access the dashboard for {companyName}
            </Text>
        </PopupContent>
    );
};

ContactDeletionPopup.propTypes = {
    fullName: PropTypes.string,
    companyName: PropTypes.string,
    onDelete: PropTypes.func
};

export {ContactDeletionPopup as TestableContactDeletionPopup};
export default React.memo(ContactDeletionPopup);
