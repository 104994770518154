import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import FileUploader from '../../shared/FileUploader';
import {Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CarrierAutocomplete from '../../shared/CarrierAutocomplete';
import useForm from '../../../hooks/useForm';
import {getAncillaryCarriers} from '../../../selectors/shared';
import {getEqual, trimStart, validateRequired} from '../../../utils';
import {ACCEPTABLE_IMAGE_FORMATS, DEFAULT_PHONE_PLACEHOLDER, MAX_IMAGE_SIZE} from '../../../constants';
import './index.scss';

const CARRIER_NAME = 'carrier_name';
const CARRIER_LOGO_NAME = 'carrier_logo';
const CARRIER_PHONE_NAME = 'carrier_phone';
const CARRIER_URL_NAME = 'carrier_url';
const CARRIER_URL_ALIAS = 'carrier_url_alias';
const URL_TOOLTIP_CONTENT = 'This is the specific page the member will be directed to';
const URL_ALIAS_TOOLTIP_CONTENT = 'This is how the link will appear on the card';

const recommendedFields = [
    {name: CARRIER_LOGO_NAME, label: 'Carrier Logo'},
    {name: CARRIER_PHONE_NAME, label: 'Carrier Phone'},
    {name: CARRIER_URL_NAME, label: 'Carrier URL'},
    {name: CARRIER_URL_ALIAS, label: 'Carrier Alias'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({
    [CARRIER_NAME]: validateRequired(values[CARRIER_NAME])
});

const AncillaryCarrierInfo = ({isFinAccount}) => {
    const form = useForm();
    const ancillaryCarriers = useSelector(getAncillaryCarriers);

    const carrierLabel = `Carrier ${isFinAccount ? '/ Financial Institution' : ''}`;

    const onCarrierChange = useCallback(value => {
        const carrierData = ancillaryCarriers.find(getEqual(value, 'name')) || {};
        const {logo = null, url = null, url_alias: urlAlias = null, phone_number: phone = null} = carrierData;

        form.batch(() => {
            form.change(CARRIER_LOGO_NAME, logo);
            form.change(CARRIER_PHONE_NAME, phone);
            form.change(CARRIER_URL_NAME, url);
            form.change(CARRIER_URL_ALIAS, urlAlias);
        });
    }, [form, ancillaryCarriers]);

    return (
        <div className='ancillary-carrier-info'>
            <Text type={TEXT_TYPES.bodyBold} className='ancillary-carrier-info__title'>Carrier Information</Text>

            <CarrierAutocomplete name={CARRIER_NAME} onChange={onCarrierChange} label={carrierLabel} isRequired wrapperClassName='mb-12'/>

            <Field name={CARRIER_LOGO_NAME}>
                {props => <FileUploader {...props} isImage accept={ACCEPTABLE_IMAGE_FORMATS} maxSize={MAX_IMAGE_SIZE} label='Upload Carrier Logo' caption='240px x 50px' wrapperClassName='mb-12'/>}
            </Field>

            <Field name={CARRIER_PHONE_NAME}>
                {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Phone Number' wrapperClassName='mb-12'/>}
            </Field>

            <Field name={CARRIER_URL_NAME} parse={trimStart}>
                {props => <Input {...props} placeholder='e.g.: www.bcbs.com' label={<LabeledTooltip title='Website Link' content={URL_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
            </Field>

            <Field name={CARRIER_URL_ALIAS} parse={trimStart}>
                {props => <Input {...props} placeholder='e.g.: Click here' label={<LabeledTooltip title='Website Alias' content={URL_ALIAS_TOOLTIP_CONTENT}/>}/>}
            </Field>
        </div>
    );
};

AncillaryCarrierInfo.propTypes = {
    isFinAccount: PropTypes.bool
};

export {recommendedFields, validateOnSubmit, AncillaryCarrierInfo as TestableAncillaryCarrierInfo};
export default React.memo(AncillaryCarrierInfo);
