import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import withPopup from '../../../HOC/withPopup';
import withToast from '../../../HOC/withToast';
import {
    requestEligibilityImportTerminatedMembers,
    requestImportsJsonrpc,
    requestPendingTerminations,
    requestPendingTerminationsMembers,
    requestPendingTerminationsNotesUpdating
} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {ROUTES} from '../../../constants';
import {
    equal,
    generateUniqueId,
    isEmpty,
    formatDate,
    saveFile,
    toCapitalize,
    convertDateToTimeZone,
    compose
} from '../../../utils';
import './index.scss';

const POPUP_ID = 'eligibilityImportTerminationThresholdPopup';

const TABLE_PAGE_SIZE = 20;

const TerminationThreshold = ({importData, groupAlias, openPopup, closePopup, showToast}) => {
    const dispatch = useDispatch();
    const {id: importId} = useParams();
    const {email: userEmail} = useSelector(getProfileInfo);
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0, filterBy: null});
    const [tableData, setTableData] = useState([]);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [isAllMembersSelected, setIsAllMembersSelected] = useState(false);
    const [toggleAllRowsSelected, setToggleAllRowsSelected] = useState(null);
    const selectedMembersCount = useMemo(() => {
        return isAllMembersSelected ? table?.unitCount : selectedEmployeeIds.length;
    }, [isAllMembersSelected, selectedEmployeeIds, table]);
    const title = useMemo(() => {
        const membersPercentage = Math.round((table?.unitCount / importData?.general_statistics?.all_members_count) * 100);

        return `${table?.unitCount} Members ${isFinite(membersPercentage) ? `(${membersPercentage}%)` : ''}`;
    }, [importData, table]);
    const [pendingTerminationNotes, setPendingTerminationNotes] = useState({message: '', updated_at: null, updated_by: null, error: null});
    const [companyMetadataId, setCompanyMetadataId] = useState(null);
    const [tableRowProps, setTableRowProps] = useState({});

    const setTerminatedMembersData = ({terminatedMembers = [], count, pageIndex, filterBy}) => {
        const enhancedTerminatedMembers = terminatedMembers.map(member => ({...member, full_name: `${member?.last_name}, ${member?.first_name}`}));
        const rowProps = enhancedTerminatedMembers.reduce((acc, {ext_id: extId}) => {
            return {...acc, ...!extId && {[extId]: {isSelectableDisabled: true}}};
        }, {});

        setTable({unitCount: count || terminatedMembers.length, pageIndex, filterBy});
        setTableData(enhancedTerminatedMembers);
        setTableRowProps(rowProps);
    };

    useEffect(() => {
        (async () => {
            if (!groupAlias) {
                return;
            }

            const {pendingTerminations} = await dispatch(requestPendingTerminations({company_alias: groupAlias, limit: 1}));
            const {pending_termination_notes: pendingTerminationNotes, id} = pendingTerminations[0] || {};

            setCompanyMetadataId(id);

            if (isEmpty(pendingTerminationNotes)) {
                return;
            }

            setPendingTerminationNotes(pendingTerminationNotes);
        })();
    }, []);

    const onUpdatePendingTerminationsNotes = async ({target}) => {
        const query = {message: target?.value, updated_at: new Date().toISOString(), updated_by: userEmail};
        const {data, isSuccess} = await dispatch(requestPendingTerminationsNotesUpdating(query, companyMetadataId));

        if (!isSuccess) {
            setPendingTerminationNotes(state => ({...state, error: data?.message}));

            return false;
        }

        setPendingTerminationNotes(data?.pending_termination_notes);
        showToast({content: 'Changes saved.'});
    };

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE, filterBy}) => {
        const query = {
            ...groupAlias ? {alias: groupAlias} : {id: importId},
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...filterBy && {
                name: filterBy,
                email: filterBy,
                employee_id: filterBy
            }
        };
        const requestTerminationsMembers = groupAlias ? requestPendingTerminationsMembers : requestEligibilityImportTerminatedMembers;
        const {isSuccess, terminatedMembers, count} = await dispatch(requestTerminationsMembers(query));

        if (!isSuccess) {
            return dispatch(redirectTo(ROUTES.eligibilityImports));
        }

        setTerminatedMembersData({terminatedMembers, count, pageIndex, filterBy});
    }, [importId, groupAlias, dispatch]);

    const onConfirmDownloadFile = onSaveFile => {
        const onConfirm = () => {
            closePopup();
            onSaveFile();
        };

        const popupProps = {
            title: 'EE IDs May Be SSN',
            actionBar: <div className='confirm-download-popup-action-bar'><Button data-testid='confirm-popup-button' type={BUTTON_TYPES.secondary} onClick={onConfirm}>Okay</Button></div>,
            children: 'The EE IDs provided may be SSNs. Therefore, the downloaded list of members will only show the last four digits of the EE ID provided (i.e. *1234) to avoid accidentally providing a client with sensitive information.'
        };
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const onDownloadPendingMembers = async ({selectedIds}) => {
        const isMembersSelectedPartly = selectedIds && !isAllMembersSelected;
        const jsonrpcObj = {
            jsonrpc: '2.0',
            method: groupAlias ? 'list_all_members_pending_termination' : 'list_session_members_pending_termination',
            id: generateUniqueId(),
            params: {
                format: 'csv',
                ...importId && {session_id: Number(importId)},
                ...groupAlias && {company_alias: groupAlias},
                ...isMembersSelectedPartly && {ext_ids: selectedIds.map(id => Number(id))}
            }
        };
        const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        if (isEmpty(jsonrpc?.result)) {
            return false;
        }

        const {csv_data: data, filename, masked_ssn: isMaskedSSN} = jsonrpc?.result || {};
        const onSaveFile = () => saveFile(`data:text/csv;charset=utf-8,${data}`, filename);

        if (isMaskedSSN) {
            onConfirmDownloadFile(onSaveFile);

            return false;
        }

        onSaveFile();
    };

    const getTableColumns = () => {
        const getRelationship = ({value: relationship}) => relationship && toCapitalize(relationship);

        return [
            ...groupAlias ? [{Header: 'Date Added', accessor: 'date_added', width: 200, Cell: ({value}) => formatDate(value, 'MM/dd/yyyy')}] : [],
            {Header: 'Name', accessor: 'full_name', width: 200},
            {Header: 'Email', accessor: 'email', width: 170, isSortable: false},
            {Header: 'Employee ID', accessor: 'employee_id', width: 170, isSortable: false},
            {Header: 'Relationship', accessor: 'relationship_field', width: 170, Cell: getRelationship, isSortable: false, isFilterable: false}
        ];
    };

    const onConfirmAccess = isMaintain => () => {
        const onConfirm = async () => {
            const jsonrpcObj = {
                jsonrpc: '2.0',
                method: isMaintain ? 'maintain_pending_termination_members' : 'terminate_pending_termination_members',
                id: generateUniqueId(),
                params: {
                    context: {
                        company_alias: groupAlias,
                        account_ext_ids: selectedEmployeeIds,
                        all_pending_members: isAllMembersSelected
                    }
                }
            };
            const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

            if (!equal(jsonrpc?.result, 'ok')) {
                return false;
            }

            closePopup();
            setSelectedEmployeeIds([]);
            onFetchData({pageIndex: table?.pageIndex, filterBy: table?.filterBy});
            // FYI: After removing members from table data selected rows are still in store. So we need to reset all selected rows manually (2.20.2024, Pasha)
            toggleAllRowsSelected(false);
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                {isMaintain
                    ? <Button data-testid='maintain-access-popup' type={BUTTON_TYPES.primary} onClick={onConfirm}>Yes, Maintain Access ({selectedMembersCount})</Button>
                    : <Button data-testid='terminate-access-popup' type={BUTTON_TYPES.destructive} onClick={onConfirm}>Yes, Terminate Access ({selectedMembersCount})</Button>}
            </React.Fragment>
        );
        const popupContent = (
            <React.Fragment>
                {isMaintain
                    ? <Text>Are you sure you want to <b>maintain access</b> for {selectedMembersCount} selected members? Members will continue to have access to HealthJoy. </Text>
                    : <Text>Are you sure you want to <b>terminate</b> and <b>remove access</b> for {selectedMembersCount} selected members? Members will no longer have access to HealthJoy. </Text>}
                <Text>This will remove them from the group’s pending termination list.</Text>
            </React.Fragment>
        );

        const popupProps = {title: 'Confirmation Required', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const filterActionBar = groupAlias ? (
        <div className={classnames('selected-pending-members', {'selected-pending-members_disabled': isEmpty(selectedEmployeeIds)})}>
            <Text className='selected-pending-members__members'>
                <Text className='selected-pending-members__count mr-5' type={TEXT_TYPES.helperBold}>{selectedMembersCount}</Text>members selected:
            </Text>
            <Button onClick={onConfirmAccess(true)} data-testid='maintain-access' type={BUTTON_TYPES.tertiarySuccess} disabled={isEmpty(selectedEmployeeIds)} iconLeft={<Icon type={ICON_TYPES.confirm}/>}>Maintain Access</Button>
            <Button onClick={onConfirmAccess()} data-testid='terminate-access' type={BUTTON_TYPES.tertiaryDestructive} disabled={isEmpty(selectedEmployeeIds)} iconLeft={<Icon type={ICON_TYPES.close}/>}>Terminate Access</Button>
            <Button onClick={() => onDownloadPendingMembers({selectedIds: selectedEmployeeIds})} data-testid='download-selected' type={BUTTON_TYPES.tertiary} disabled={isEmpty(selectedEmployeeIds)} iconLeft={<Icon type={ICON_TYPES.download}/>}>Download Selected</Button>
        </div>
    ) : React.Fragment;

    const onSelectRow = useCallback(({selectedRowIds, isAllSelected, toggleAllRowsSelected}) => {
        const filteredSelectedRowIds = Object.keys(selectedRowIds).map(JSON.parse).filter(Boolean);

        setIsAllMembersSelected(isAllSelected);
        setSelectedEmployeeIds(filteredSelectedRowIds);
        setToggleAllRowsSelected(() => toggleAllRowsSelected);
    }, []);

    const tableProps = {
        data: tableData,
        pageSize: TABLE_PAGE_SIZE,
        isSortable: true,
        isFilterable: true,
        columns: getTableColumns(),
        filterPlaceholder: 'Search by name, email, or employee ID',
        filterActionBar,
        onFetchData,
        ...groupAlias && {onSelectRow},
        ...table,
        tableConfig: {
            autoResetSelectedRows: false,
            getRowId: row => row.ext_id
        },
        rowProps: tableRowProps
    };

    return (
        <div className='termination-threshold'>
            <Alert className='mb-20' type={ALERT_TYPES.warning} description={<span>Review the termination surge for this import. Download pending termination list to confirm with appropriate team or go to <span className='pending-termination'>Pending Terminations</span> tab to confirm member status and/or view full list.</span>}/>

            <Row middle='xs' columnGap='xs'>
                <Column xs>
                    <Heading type={HEADING_TYPES['1']}>{title}</Heading>
                </Column>
                <Column constant>
                    <Button onClick={onDownloadPendingMembers} data-testid='download-members-button' type={BUTTON_TYPES.secondary} iconLeft={<Icon type={ICON_TYPES.download}/>}>Download List of Pending Members</Button>
                </Column>
            </Row>

            {groupAlias && (
                <div className='mt-20'>
                    {pendingTerminationNotes?.error && <Text type={TEXT_TYPES.helper} className='ml-10 mb-3'><span className='notes-error'><Icon type={ICON_TYPES.alert} className='mr-3'/> Notes not saved. {pendingTerminationNotes?.error}.</span></Text>}
                    <Textarea value={pendingTerminationNotes.message}
                        label='Notes'
                        description={pendingTerminationNotes.updated_by && pendingTerminationNotes.updated_at
                            && `Last updated ${formatDate(convertDateToTimeZone(pendingTerminationNotes.updated_at), 'MM/dd/yyyy H:mm')} CST by ${pendingTerminationNotes.updated_by}`}
                        maxLength={200}
                        onChange={({target}) => setPendingTerminationNotes(state => ({...state, message: target?.value}))}
                        onBlur={onUpdatePendingTerminationsNotes}
                        isValid={!pendingTerminationNotes?.error}
                        isComponentErrorHandlin
                        isTouched/>
                </div>
            )}

            <ContentSection className='mt-20'>
                {Array.isArray(tableData) && <Table {...tableProps}/>}
            </ContentSection>
        </div>
    );
};

TerminationThreshold.propTypes = {
    importData: PropTypes.shape({
        general_statistics: PropTypes.shape({
            all_members_count: PropTypes.number
        })
    }),
    groupAlias: PropTypes.string,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
    showToast: PropTypes.func
};

export {TerminationThreshold as TestableTerminationThreshold};
export default compose(
    withPopup(POPUP_ID),
    withToast
)(TerminationThreshold);
