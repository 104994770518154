import React from 'react';
import PropTypes from 'prop-types';
import CoreBenefitsStepHeader from '../../Components/Benefits/CoreBenefitsStepHeader';

const withCoreBenefitsStepLayout = Component => {
    const WithCoreBenefitsStepLayout = props => {
        const {stepName: planType} = props;

        return (
            <React.Fragment>
                <CoreBenefitsStepHeader planType={planType}/>
                <Component {...props}/>
            </React.Fragment>
        );
    };

    WithCoreBenefitsStepLayout.propTypes = {
        stepName: PropTypes.string
    };

    return WithCoreBenefitsStepLayout;
};

export {withCoreBenefitsStepLayout as testableWithCoreBenefitsStepLayout};
export default withCoreBenefitsStepLayout;
