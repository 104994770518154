import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Link from '@frontend/ui-kit/Components/Link';
import ImportGroupConfig from '../ImportGroupConfig';
import {requestFeatureFlag} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {parseQuery} from '../../../utils';
import {FEATURE_FLAGS, HOUSTON_URL} from '../../../constants';
import './index.scss';

const IndividualGroupConfig = ({configType, onChangeActiveStep}) => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const {search} = useLocation();
    const groupName = parseQuery(search).group_name;
    const [isAncillaryConfig, setIsAncillaryConfig] = useState(false);

    useEffect(() => {
        (async () => {
            const {flag: isAncillaryConfig} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.ancillaryConfigsManagement, {userEmail}));

            setIsAncillaryConfig(isAncillaryConfig);
        })();
    }, [dispatch, userEmail]);

    return (
        <React.Fragment>
            {!isAncillaryConfig && (
                <Alert className='mb-20 mt-20'
                    hasIcon={false}
                    type={ALERT_TYPES.info}
                    description={(
                        <span>
                            Ancillary plan configs are not yet supported within the Admin Portal. In the meantime, <span className='individual-config__underline'>configure ancillary plans</span> for {groupName} in Houston
                        </span>
                    )}>
                    <Link href={`${HOUSTON_URL}/admin`} target='_blank'>
                        <Icon type={ICON_TYPES.externalLink}/>
                    </Link>
                </Alert>
            )}

            <ImportGroupConfig configType={configType} onChangeActiveStep={onChangeActiveStep}/>
        </React.Fragment>
    );
};

IndividualGroupConfig.propTypes = {
    configType: PropTypes.string,
    onChangeActiveStep: PropTypes.func
};

export {IndividualGroupConfig as TestableIndividualGroupConfig};
export default IndividualGroupConfig;
