import React, {useCallback} from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import {Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import withEligibilityInfoForm from '../../../HOC/withEligibilityInfoForm';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {normalizeBoolean, validateRequired, equal} from '../../../utils';
import {OTHER} from '../../../constants';
import {COBRA_SENDING_OPTIONS, ELIGIBLE_OPTIONS, CONDITION_OPTIONS, UNIQUE_MEMBER_IDENTIFIER_OPTIONS} from '../../../options';
import './index.scss';

const HJ_ELIGIBLE_TOOLTIP_TEXT = 'Once a member no longer meets this criteria, he/she will lose access to HealthJoy by the next business day';
const UNIQUE_MEMBER_IDENTIFIER_TOOLTIP_TEXT = `
    This should be a piece of information that is present in each file and unique to each member.
    That lets us merge the files so that members are seeing only their benefits and so that eligibility updates are processed quickly.
`;

/* istanbul ignore next */
const validate = values => {
    const {has_cobra_enroll: isCobraEnroll} = values;

    return {
        hj_eligible: validateRequired(values.hj_eligible),
        cobra_enroll: isCobraEnroll ? validateRequired(values.cobra_enroll) : undefined
    };
};

const HealthjoyEligibilityStep = () => {
    const form = useForm();
    const {values} = useFormState();
    const {has_cobra_enroll: hasCobraEnroll, hj_eligible: hjEligible, unique_member_key: uniqKey} = values;

    const resetFields = useCallback(fields => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, [form]);

    return (
        <React.Fragment>
            <Row className='eligibility-wizard-step healthjoy-eligibility-step'>
                <Column sm={6}>
                    <Field name='hj_eligible' onChange={resetFields(['hj_eligible_description'])}>
                        {props => <Select {...props} options={ELIGIBLE_OPTIONS} label={<LabeledTooltip title='Who will be eligible for HealthJoy?' content={HJ_ELIGIBLE_TOOLTIP_TEXT}/>} isRequired wrapperClassName='mb-12'/>}
                    </Field>

                    {equal(hjEligible, OTHER) && (
                        <Field name='hj_eligible_description'>
                            {props => <Input {...props} placeholder='Describe eligibility definition...' label='Other description' wrapperClassName='mb-12'/>}
                        </Field>
                    )}

                    <Field name='unique_member_key' onChange={resetFields(['unique_member_key_description'])}>
                        {props => <Select {...props} options={UNIQUE_MEMBER_IDENTIFIER_OPTIONS} label={<LabeledTooltip title='How will we identify unique members across all file sources?' content={UNIQUE_MEMBER_IDENTIFIER_TOOLTIP_TEXT}/>} wrapperClassName='mb-12'/>}
                    </Field>

                    {equal(uniqKey, OTHER) && (
                        <Field name='unique_member_key_description'>
                            {props => <Input {...props} placeholder='Describe members uniqueness key...' label='Other description' wrapperClassName='mb-12'/>}
                        </Field>
                    )}

                    <Field name='has_cobra_enroll' parse={normalizeBoolean} onChange={resetFields(['cobra_enroll'])}>
                        {props => <RadioGroup {...props} options={CONDITION_OPTIONS} label='Will employees on COBRA also get HealthJoy?'/>}
                    </Field>

                    {hasCobraEnroll && (
                        <Field name='cobra_enroll'>
                            {props => <Select {...props} options={COBRA_SENDING_OPTIONS} label='How will you be sending COBRA enrollees?' isRequired wrapperClassName='mt-7'/>}
                        </Field>
                    )}
                </Column>
            </Row>
        </React.Fragment>
    );
};

export {HealthjoyEligibilityStep as TestableHealthjoyEligibilityStep};
export default withEligibilityInfoForm({validate})(HealthjoyEligibilityStep);
