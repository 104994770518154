import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import PlanFormHeader from '../PlanFormHeader';
import FileUploader from '../../shared/FileUploader';
import {Form, Field, FieldArray} from '../../shared/FormComponents';
import DetailsActionBar from '../../shared/DetailsActionBar';
import {requestAdditionalInfo, requestAdditionalInfoSetting} from '../../../actions/benefits';
import {equal, trimStart} from '../../../utils';
import {FORMS, ACCEPTABLE_TEXT_FORMATS, MEDICAL_TRAIT_LABEL_BY_TYPE} from '../../../constants';

const AdditionalPlanInfo = ({planId, title, onSuccess}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const {additionalInfo, isSuccess} = await dispatch(requestAdditionalInfo(planId));

            if (isSuccess) {
                setInitialValues(additionalInfo);
            }
        })();
    }, [dispatch, planId]);

    const getItems = ({fields}) => fields.map((field, index) => {
        const {trait: type} = fields.value[index];
        const label = MEDICAL_TRAIT_LABEL_BY_TYPE[type];
        const isLast = equal(index, fields.value.length - 1);

        return (
            <div className='additional-plan-info-item' key={type}>
                <Text type={TEXT_TYPES.bodyBold} className='additional-plan-info-item__trait-label'>{index + 1}. {label}</Text>

                <Field name={`${field}.trait`}>{props => <Input {...props} type='hidden'/>}</Field>

                <Row className='plan-details-form-row plan-details-form-row_nested'>
                    <Column className='plan-details-form-row__column' sm={6}>
                        <Field name={`${field}.files`}>
                            {props => <FileUploader {...props} isMultiple accept={ACCEPTABLE_TEXT_FORMATS} label='Upload Your File'/>}
                        </Field>
                    </Column>

                    <Column className='plan-details-form-row__column' sm={6}>
                        <Field name={`${field}.description`} parse={trimStart}>
                            {props => (
                                <Textarea {...props}
                                    className='additional-plan-info-item__description'
                                    placeholder='Please describe file content...'
                                    maxLength={500}
                                    label='Short description'/>
                            )}
                        </Field>
                    </Column>
                </Row>

                {!isLast && <Separator className='plan-details-form__separator plan-details-form__separator_dashed'/>}
            </div>
        );
    });

    const onSubmit = useCallback(async values => {
        const {meta, ...restValues} = values;
        const isCompleted = values.completed || meta.completed; // FYI: if plan is already completed according to requirements we need to send always true as "completed", even if it's save progress action (Pasha, 18.01.2021)

        const {
            additionalInfo,
            isSuccess,
            submissionErrors
        } = await dispatch(requestAdditionalInfoSetting(planId, {...restValues, completed: isCompleted}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(additionalInfo);
    }, [dispatch, planId]);

    return (
        <Form name={FORMS.additionalPlanInfo} initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit}) => {
                const actionBarProps = {onSuccess, onSubmit: handleSubmit};

                return (
                    <form noValidate>
                        <PlanFormHeader title={title}/>

                        <div className='plan-details-form additional-plan-info-form'>
                            <Row className='plan-details-form-row plan-details-form-row_nested'>
                                <Column className='plan-details-form-row__column' sm>
                                    <Text className='plan-details-form__description'>
                                        Please provide relevant documents and descriptions to each trait selected in the Overview process.
                                    </Text>

                                    <Separator className='plan-details-form__separator plan-details-form__separator_solid' type='solid'/>

                                    <FieldArray name='items'>{getItems}</FieldArray>
                                </Column>
                            </Row>
                        </div>

                        <DetailsActionBar {...actionBarProps}/>
                    </form>
                );
            }}
        </Form>
    );
};

AdditionalPlanInfo.propTypes = {
    planId: PropTypes.number,
    title: PropTypes.string,
    onSuccess: PropTypes.func
};

export {AdditionalPlanInfo as TestableAdditionalPlanInfo};
export default React.memo(AdditionalPlanInfo);
