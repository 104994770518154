import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Separator from '@frontend/ui-kit/Components/Separator';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {Form, Field} from '../FormComponents';
import {
    requestCategoryAndTrackingInfoDetailsConfig,
    requestCategoryAndTrackingInfoSetting,
    requestProcedureOptions
} from '../../../actions/benefits';
import {
    getItemKeyValue,
    getRegisteredFieldsValues,
    isEmpty,
    normalizeNumber,
    promisifyAsyncFunction,
    stringifyQueryParams,
    validateRequired
} from '../../../utils';
import {FORMS} from '../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const {category_and_tracking_info: categoryAndTrackingInfo} = values;

    return {
        category_and_tracking_info: {
            category_id: validateRequired(categoryAndTrackingInfo?.category_id),
            redirects_representation: {
                personalized_plan_card: !isEmpty(categoryAndTrackingInfo?.health_goals_mapping)
                    ? {
                        title: validateRequired(categoryAndTrackingInfo?.redirects_representation?.personalized_plan_card?.title),
                        body: validateRequired(categoryAndTrackingInfo?.redirects_representation?.personalized_plan_card?.body),
                        cta: validateRequired(categoryAndTrackingInfo?.redirects_representation?.personalized_plan_card?.cta)
                    } : undefined,
                find_care_search_results_card: ![categoryAndTrackingInfo?.card_specialty_group_ids, categoryAndTrackingInfo?.card_procedure_ids].every(isEmpty)
                    ? {
                        title: validateRequired(categoryAndTrackingInfo?.redirects_representation?.find_care_search_results_card?.title),
                        body: validateRequired(categoryAndTrackingInfo?.redirects_representation?.find_care_search_results_card?.body),
                        cta: validateRequired(categoryAndTrackingInfo?.redirects_representation?.find_care_search_results_card?.cta)
                    } : undefined
            }
        }
    };
};

const HEALTH_GOALS_MAPPING_DELIMITER = '__';

const getHealthGoalsQuestionGroupedOptions = healthGoalsQuestions => {
    return healthGoalsQuestions.map(healthGoalsQuestion => {
        const {title, question_category: questionCategory, answers} = healthGoalsQuestion;
        const answerOptions = answers.map(({title, answer_category: answerCategories}) => ({
            label: title,
            value: `${questionCategory}${HEALTH_GOALS_MAPPING_DELIMITER}${answerCategories}`
        }));

        return {label: title, options: answerOptions};
    });
};

const getFormattedHealthGoalsMapping = healthGoalsMapping => {
    const enhancedHealthGoalsMapping = healthGoalsMapping.reduce((acc, value) => {
        const [questionCategory, answerCategory] = value.split(HEALTH_GOALS_MAPPING_DELIMITER);
        const answerCategories = acc[questionCategory] ? [...acc[questionCategory].answer_categories, answerCategory] : [answerCategory];

        return {
            ...acc,
            [questionCategory]: {
                question_category: questionCategory,
                answer_categories: answerCategories
            }
        };
    }, {});

    return Object.values(enhancedHealthGoalsMapping);
};

const getParsedHealthGoalsMapping = healthGoalsMapping => {
    return healthGoalsMapping
        .map(({question_category: questionCategory, answer_categories: answerCategories}) => (
            answerCategories.map(answerCategory => `${questionCategory}${HEALTH_GOALS_MAPPING_DELIMITER}${answerCategory}`)
        ))
        .flat();
};

const getFormattedOption = ({id, name}) => ({value: id, label: name});

const CategoryAndTrackingManagementPopup = ({planId, cardView, onSave, onCancel}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [salesforceProductOptions, setSalesforceProductOptions] = useState([]);
    const [specialtyGroupOptions, setSpecialtyGroupOptions] = useState([]);
    const [healthGoalsQuestionGroupedOptions, setHealthGoalsQuestionGroupedOptions] = useState([]);

    const loadProceduresOptions = promisifyAsyncFunction(async (query, {proceduresList, isLoader}) => {
        const {procedureOptions} = await dispatch(requestProcedureOptions({query, proceduresList, isLoader}));

        return procedureOptions.map(({name, id}) => ({label: name, value: id}));
    });

    useEffect(() => {
        (async () => {
            const procedureIds = cardView?.category_and_tracking_info?.card_procedure_ids || [];
            const proceduresList = stringifyQueryParams({ids: procedureIds, arrayFormat: 'none'});

            const [
                procedures = [],
                {categories, specialtyGroups, salesforceProducts, healthGoalsQuestions}
            ] = await Promise.all([
                ...!isEmpty(procedureIds) ? [loadProceduresOptions(undefined, {proceduresList, isLoader: true})] : [Promise.resolve()],
                dispatch(requestCategoryAndTrackingInfoDetailsConfig())
            ]);

            const healthGoalsQuestionGroupedOptions = getHealthGoalsQuestionGroupedOptions(healthGoalsQuestions);
            const salesforceProductOptions = [
                {label: 'None', value: null},
                ...salesforceProducts.map(getFormattedOption)
            ];
            const [categoryOptions, specialtyGroupOptions] = [categories, specialtyGroups].map(list => {
                return list.map(getFormattedOption);
            });

            setCategoryOptions(categoryOptions);
            setSalesforceProductOptions([...salesforceProductOptions]);
            setSpecialtyGroupOptions(specialtyGroupOptions);
            setHealthGoalsQuestionGroupedOptions(healthGoalsQuestionGroupedOptions);
            setInitialValues({
                ...cardView,
                category_and_tracking_info: {
                    ...cardView.category_and_tracking_info,
                    card_procedure_ids: procedures.map(({label, value}) => ({name: label, id: value})),
                    health_goals_mapping: getParsedHealthGoalsMapping(cardView.category_and_tracking_info?.health_goals_mapping || []),
                    redirects_representation: {
                        personalized_plan_card: cardView.category_and_tracking_info?.redirects_representation?.personalized_plan_card || {cta: 'Get Started'},
                        find_care_search_results_card: cardView.category_and_tracking_info?.redirects_representation?.find_care_search_results_card || {cta: 'Get Started'}
                    }
                }
            });
        })();
    }, []);

    const onSubmit = useCallback(async (values, {getRegisteredFields}) => {
        const {id, category_and_tracking_info: categoryAndTrackingInfo} = getRegisteredFieldsValues(getRegisteredFields(), values);
        const {health_goals_mapping: healthGoalsMapping, card_procedure_ids: cardProcedureIds, ...restCategoryAndTrackingInfo} = categoryAndTrackingInfo;

        const {cardView, isSuccess, submissionErrors} = await dispatch(requestCategoryAndTrackingInfoSetting(planId, {
            id,
            category_and_tracking_info: {
                ...restCategoryAndTrackingInfo,
                health_goals_mapping: getFormattedHealthGoalsMapping(healthGoalsMapping),
                card_procedure_ids: cardProcedureIds.map(getItemKeyValue('id'))
            }
        }));

        if (!isSuccess) {
            return submissionErrors;
        }

        onSave(cardView);
    }, [planId, onSave, dispatch]);

    return (
        <Form name={FORMS.categoryAndTrackingInfo} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, values}) => {
                const isHasHealthGoals = !isEmpty(values.category_and_tracking_info?.health_goals_mapping);
                const isHasFindCareMapping = ![values.category_and_tracking_info?.card_specialty_group_ids, values.category_and_tracking_info?.card_procedure_ids].every(isEmpty);
                const actionBar = (
                    <React.Fragment>
                        <Button data-testid='cancel-button' onClick={onCancel} type={BUTTON_TYPES.secondary}>Cancel</Button>
                        <Button data-testid='save-button' type={BUTTON_TYPES.primary} onClick={handleSubmit}>Save</Button>
                    </React.Fragment>
                );
                const popupContentProps = {title: 'Steerage and Tracking management', actionBar};

                return (
                    <form noValidate data-testid='category-and-tracking-management-popup' className='category-and-tracking-management-popup'>
                        <PopupContent {...popupContentProps}>
                            <Field name='id'>{props => <Input {...props} type='hidden'/>}</Field>

                            <Row rowGap='xlg' columnGap='md'>
                                <Column sm={6}>
                                    <Field name='category_and_tracking_info.category_id'>
                                        {props => <Select {...props} options={categoryOptions} isMenuPortal label='Card category'/>}
                                    </Field>
                                </Column>

                                <Column sm={6}>
                                    <Field name='category_and_tracking_info.events_tracking'>
                                        {props => <Select {...props} options={[]} isMulti isMenuPortal label='Events considered as steerage'/>}
                                    </Field>
                                </Column>

                                <Column sm={6}>
                                    <Field name='category_and_tracking_info.roi' parse={normalizeNumber}>
                                        {props => <Input {...props} type='number' label='Monetary ROI value'/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Field name='category_and_tracking_info.product_alias'>
                                {props => <Autocomplete {...props} options={salesforceProductOptions} isMenuPortal label='SalesForce product or ICM'/>}
                            </Field>

                            <Separator/>

                            <Field name='category_and_tracking_info.is_find_care_eligible'>
                                {props => <Checkbox {...props} wrapperClassName='mt-16 mb-16' caption='Find Care My Care Team eligible'/>}
                            </Field>

                            <Row>
                                <Column sm={6}>
                                    <Field name='category_and_tracking_info.card_specialty_group_ids'>
                                        {props => <Autocomplete {...props} options={specialtyGroupOptions} isMulti isMenuPortal label='Find Care specialty mapping'/>}
                                    </Field>
                                </Column>

                                <Column sm={6}>
                                    <Field name='category_and_tracking_info.card_procedure_ids'>
                                        {props => (
                                            <AsyncAutocomplete {...props}
                                                formatValue={({name, id}) => ({label: name, value: id})}
                                                parseValue={({label, value}) => ({name: label, id: value})}
                                                filterOption={() => true}
                                                loadOptions={loadProceduresOptions}
                                                label='Find Care procedure mapping'
                                                isCreatable={false}
                                                isMulti
                                                isMenuPortal/>
                                        )}
                                    </Field>
                                </Column>
                            </Row>

                            {isHasFindCareMapping && (
                                <React.Fragment>
                                    <Field name='category_and_tracking_info.redirects_representation.find_care_search_results_card.title' wrapperClassName='mt-16 mb-16' maxLength={50}>
                                        {props => <Input {...props} label='Find Care Title' isRequired/>}
                                    </Field>
                                    <Field name='category_and_tracking_info.redirects_representation.find_care_search_results_card.body' wrapperClassName='mb-16' maxLength={150}>
                                        {props => <Input {...props} label='Find Care Body' isRequired/>}
                                    </Field>
                                    <Field name='category_and_tracking_info.redirects_representation.find_care_search_results_card.cta' maxLength={50}>
                                        {props => <Input {...props} label='Find Care CTA' isRequired/>}
                                    </Field>
                                </React.Fragment>
                            )}

                            <Separator/>

                            <Field name='category_and_tracking_info.health_goals_mapping' wrapperClassName='mb-16'>
                                {props => <Select {...props} options={healthGoalsQuestionGroupedOptions} label='Health Goals answers' isMulti isMenuPortal areGroupedOptions/>}
                            </Field>

                            {isHasHealthGoals && (
                                <React.Fragment>
                                    <Field name='category_and_tracking_info.redirects_representation.personalized_plan_card.title' wrapperClassName='mb-16' maxLength={60}>
                                        {props => <Input {...props} label='Personalized Plan item Title' isRequired/>}
                                    </Field>
                                    <Field name='category_and_tracking_info.redirects_representation.personalized_plan_card.body' wrapperClassName='mb-16' maxLength={250}>
                                        {props => <Input {...props} label='Personalized Plan item Body' isRequired/>}
                                    </Field>
                                    <Field name='category_and_tracking_info.redirects_representation.personalized_plan_card.cta' maxLength={20}>
                                        {props => <Input {...props} label='Personalized Plan item CTA' isRequired/>}
                                    </Field>
                                </React.Fragment>
                            )}
                        </PopupContent>
                    </form>
                );
            }}
        </Form>
    );
};

CategoryAndTrackingManagementPopup.propTypes = {
    planId: PropTypes.number.isRequired,
    cardView: PropTypes.shape({
        id: PropTypes.number,
        category_and_tracking_info: PropTypes.shape({
            name: PropTypes.string,
            roi: PropTypes.number,
            events_tracking: PropTypes.arrayOf(PropTypes.string),
            card_specialty_group_ids: PropTypes.arrayOf(PropTypes.number),
            card_procedure_ids: PropTypes.arrayOf(PropTypes.number),
            product_alias: PropTypes.string,
            health_goals_mapping: PropTypes.arrayOf(PropTypes.shape({
                question_category: PropTypes.string,
                answer_categories: PropTypes.arrayOf(PropTypes.string)
            })),
            is_find_care_eligible: PropTypes.bool,
            redirects_representation: PropTypes.shape({
                personalized_plan_card: PropTypes.shape({
                    title: PropTypes.string,
                    body: PropTypes.string,
                    cta: PropTypes.string
                }),
                find_care_search_results_card: PropTypes.shape({
                    title: PropTypes.string,
                    body: PropTypes.string,
                    cta: PropTypes.string
                })
            })
        })
    }),
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export {CategoryAndTrackingManagementPopup as TestableCategoryAndTrackingManagementPopup};
export default React.memo(CategoryAndTrackingManagementPopup);
