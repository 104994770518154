import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import history from './history';
import Pages from './Pages';
import withDashboardLayout from './HOC/withDashboardLayout';
import withAdminPortalLayout from './HOC/withAdminPortalLayout';
import withAuthorizationLayout from './HOC/withAuthorizationLayout';
import withAuthorizationControl from './HOC/withAuthorizationControl';
import {getActiveCompanyStageCriteria} from './selectors/general';
import {getAuthorizationStatus} from './selectors/authorization';
import {compose} from './utils';
import {COMPANY_STAGES, ROUTES, STEP_ALIASES, IS_IE_BROWSER} from './constants';

const RouteInnerDashboard = compose(withAuthorizationControl({isAuthorizationPage: false}), withDashboardLayout)(Route);
const RouteInnerAdminPortal = compose(withAuthorizationControl({isAuthorizationPage: false}), withAdminPortalLayout)(Route);
const RouteInnerAuthorization = compose(withAuthorizationControl({isAuthorizationPage: true}), withAuthorizationLayout)(Route);

const Router = () => {
    const isAuthorized = useSelector(getAuthorizationStatus);
    const isCompanyRenewing = useSelector(state => getActiveCompanyStageCriteria(state, COMPANY_STAGES.renewal));

    const coreBenefitsStepAlias = isCompanyRenewing ? STEP_ALIASES.renewalOverview : STEP_ALIASES.benefitsOverview;
    const ancillaryBenefitsStepAlias = isCompanyRenewing ? STEP_ALIASES.renewalOverview : STEP_ALIASES.benefitsOverview;
    const eligibilityOverviewStepAlias = isCompanyRenewing ? STEP_ALIASES.renewalOverview : STEP_ALIASES.benefitsOverview;

    return (
        <ConnectedRouter history={history}>
            {IS_IE_BROWSER && <Route path='*' component={Pages.IESunset}/>}

            {!IS_IE_BROWSER && (
                <Switch>
                    {/* FYI: artificial route for correct redirection to current home page depending on conditions */}
                    {isAuthorized && <RouteInnerDashboard path={ROUTES.root} exact/>}
                    {!isAuthorized && <RouteInnerAuthorization path={ROUTES.root} exact component={Pages.Login}/>}

                    <RouteInnerAuthorization path={ROUTES.login} component={Pages.Login}/>
                    <RouteInnerAuthorization path={ROUTES.locked} exact component={Pages.Locked}/>
                    {/* TODO: page is deprecated. Remove it and all related entities in 2-3 month (06.03.2024, Oleh); */}
                    {/* <RouteInnerAuthorization path={ROUTES.addPhone} exact component={Pages.AddPhoneNumber}/> */}

                    <RouteInnerAuthorization path={ROUTES.password} component={Pages.Activation}/>
                    <RouteInnerAuthorization path={ROUTES.activation} component={Pages.Activation}/>
                    <RouteInnerAuthorization path={ROUTES.passwordRecovery} component={Pages.PasswordRecovery}/>
                    <RouteInnerAuthorization path={ROUTES.verification} component={Pages.Verification}/>
                    <RouteInnerAuthorization path={ROUTES.resendCode} component={Pages.ResendCode}/>

                    <Route path={ROUTES.signOut} component={Pages.SignOut}/>

                    {/* FYI: artificial route for correct redirection to plan manager or root */}
                    <RouteInnerAdminPortal exact path={ROUTES.adminPortalRoot}/>
                    <RouteInnerAdminPortal exact path={ROUTES.planManager} component={Pages.PlanManager}/>
                    <RouteInnerAdminPortal path={`${ROUTES.planManager}/:id`} component={Pages.PlanManagerDetails}/>
                    <RouteInnerAdminPortal exact path={ROUTES.carrierManager} component={Pages.CarrierManager}/>
                    <RouteInnerAdminPortal exact path={ROUTES.createCarrier} component={Pages.CarrierManagerCreateDetails}/>
                    <RouteInnerAdminPortal path={`${ROUTES.carrierManager}/:id`} component={Pages.CarrierManagerDetails}/>
                    <RouteInnerAdminPortal exact path={ROUTES.timelineTemplates} component={Pages.TimelineTemplates}/>
                    <RouteInnerAdminPortal exact path={ROUTES.createTimelineTemplate} component={Pages.TimelineTemplatesCreation}/>
                    <RouteInnerAdminPortal path={`${ROUTES.timelineTemplates}/:id`} component={Pages.TimelineTemplatesDetails}/>
                    <RouteInnerAdminPortal exact path={ROUTES.appConfiguration} component={Pages.AppConfiguration}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.appConfiguration}/:type/:companyAlias`} component={Pages.AppConfigurationDetails}/>
                    <RouteInnerAdminPortal exact path={ROUTES.recommendationsManager} component={Pages.RecommendationsManager}/>
                    <RouteInnerAdminPortal exact path={ROUTES.createRecommendationCard} component={Pages.RecommendationCardCreation}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.recommendationsManager}/:id`} component={Pages.RecommendationCardDetails}/>
                    <RouteInnerAdminPortal exact path={ROUTES.eligibilityImports} component={Pages.EligibilityImports}/>
                    <RouteInnerAdminPortal exact path={ROUTES.importsTpa} component={Pages.AllClients}/>
                    <RouteInnerAdminPortal exact path={ROUTES.importsIndividual} component={Pages.AllClients}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias`} component={Pages.IndividualGroup}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/group_config`} component={Pages.IndividualGroupConfiguration}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/archived_group_config/:id`} component={Pages.IndividualGroupConfigurationArchived}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/validation_report/:id`} component={Pages.IndividualValidationReport}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/validation_report/:id/thresholds`} component={Pages.IndividualValidationReportThresholds}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/verification_report/:id`} component={Pages.IndividualVerificationReport}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/verification_report/:id/core_plans`} component={Pages.IndividualVerificationReportDetailedCorePlans}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/verification_report/:id/card_links`} component={Pages.IndividualVerificationReportCardLinks}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsIndividual}/:group_alias/pending_terminations`} component={Pages.PendingTerminationsMembers}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner`} component={Pages.TPAPartner}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/tpa_config`} component={Pages.TPAPartnerConfiguration}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/archived_tpa_config/:id`} component={Pages.TPAPartnerConfigurationArchived}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/all`} component={Pages.TPAGroupsAll}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/configure_tpa_groups`} component={Pages.ConfigureTPAGroups}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/:group`} component={Pages.TPAGroupDetails}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/pre_processing_report/:session_id`} component={Pages.PreprocessingReport}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/pre_processing_report/:session_id/:error_index`} component={Pages.PreprocessingReportError}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/validation_report/:id`} component={Pages.TPAValidationReport}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/validation_report/:id/thresholds`} component={Pages.TPAValidationReportThresholds}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/verification_report/:id`} component={Pages.TPAVerificationReport}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/verification_report/:id/core_plans`} component={Pages.TPAVerificationReportDetailedCorePlans}/>
                    <RouteInnerAdminPortal exact path={`${ROUTES.importsTpa}/:partner/verification_report/:id/card_links`} component={Pages.TPAVerificationReportCardLinks}/>

                    <RouteInnerDashboard path={ROUTES.externalRedirection} component={Pages.ZendeskRedirection}/>

                    <RouteInnerDashboard path={ROUTES.timeline} component={Pages.Timeline}/>
                    <RouteInnerDashboard path={ROUTES.timelineGeneration} component={Pages.TimelineGeneration}/>

                    <RouteInnerDashboard path={ROUTES.companyInfo} component={Pages.CompanyInfo} stepAlias={STEP_ALIASES.benefitsOverview}/>
                    <RouteInnerDashboard path={ROUTES.contactInfo} component={Pages.ContactInfo} stepAlias={STEP_ALIASES.benefitsOverview}/>
                    <RouteInnerDashboard path={ROUTES.baseContactInfo} component={Pages.BaseContactInfo}/>
                    <RouteInnerDashboard path={ROUTES.contacts} component={Pages.Contacts} stepAlias={STEP_ALIASES.benefitsOverview}/>

                    <RouteInnerDashboard path={`${ROUTES.benefits}/:planPeriodId/core_benefits`} component={Pages.CoreBenefits} stepAlias={coreBenefitsStepAlias}/>
                    <RouteInnerDashboard path={`${ROUTES.benefits}/:planPeriodId/core_benefits_details`} component={Pages.CoreBenefitsDetails} stepAlias={STEP_ALIASES.gatheringBenefits}/>
                    <RouteInnerDashboard path={`${ROUTES.benefits}/:planPeriodId/ancillary_benefits`} component={Pages.AncillaryBenefits} stepAlias={ancillaryBenefitsStepAlias}/>
                    <RouteInnerDashboard path={`${ROUTES.benefits}/:planPeriodId/ancillary_benefits_details`} component={Pages.AncillaryBenefitsDetails} stepAlias={STEP_ALIASES.gatheringBenefits}/>
                    <RouteInnerDashboard path={`${ROUTES.benefits}/:planPeriodId/preview`} component={Pages.BenefitsPreview}/>

                    <RouteInnerDashboard path={`${ROUTES.eligibility}/:planPeriodId/implementation`} component={Pages.EligibilityOverview} stepAlias={eligibilityOverviewStepAlias}/>
                    <RouteInnerDashboard path={`${ROUTES.eligibility}/:planPeriodId/details`} component={Pages.EligibilityDetails} stepAlias={STEP_ALIASES.gatheringEligibility}/>
                    <RouteInnerDashboard path={`${ROUTES.eligibility}/:planPeriodId/export`} component={Pages.EligibilityExport}/>

                    <RouteInnerDashboard path={ROUTES.benefitsOverview} component={Pages.BenefitsOverview} stepAlias={STEP_ALIASES.gatheringBenefits}/>
                    <RouteInnerDashboard path={ROUTES.openEnrollment} component={Pages.OpenEnrollment} stepAlias={STEP_ALIASES.renewalOverview}/>

                    <RouteInnerDashboard path={ROUTES.summaryReporting} component={Pages.Summary}/>
                    <RouteInnerDashboard path={ROUTES.programSavingsReporting} component={Pages.ProgramSavings}/>
                    <RouteInnerDashboard path={ROUTES.utilizationReporting} component={Pages.Utilization}/>
                    <RouteInnerDashboard path={ROUTES.memberActivationReporting} component={Pages.MemberActivation}/>
                    <RouteInnerDashboard path={ROUTES.reportDownloadsReporting} component={Pages.DownloadReports}/>
                    {/* <RouteInnerDashboard path={ROUTES.lookerReporting} component={Pages.Looker}/> */}
                    {/* <RouteInnerDashboard path={ROUTES.lookerBrokerReporting} component={Pages.LookerBroker}/> */}

                    <RouteInnerDashboard path={`${ROUTES.educationAndMarketing}/:id`} component={Pages.EducationAndMarketing}/>

                    {/* FYI: make sure that routes are equal to listed within UnavailabilityMap (general selector) (08.09.2020, Oleh) */}
                    <RouteInnerDashboard exact path={ROUTES.broadcasterWelcome} component={Pages.BroadcasterWelcome}/>
                    <RouteInnerDashboard path={`${ROUTES.broadcastEditor}/:id?`} component={Pages.BroadcastEditor}/>
                    <RouteInnerDashboard path={`${ROUTES.broadcastDetails}/:id`} component={Pages.BroadcastDetails}/>

                    <Route path={ROUTES.privacyInfo} component={Pages.PrivacyInfo}/>
                    <Route path={ROUTES.legalInfo} component={Pages.LegalInfo}/>

                    <Route path={ROUTES.pageNotFound} component={Pages.PageNotFound}/>
                    <Route path={`${ROUTES.serverError}/:errorCode`} component={Pages.ServerError}/>

                    <Route path={`${ROUTES.pdfTemplate}/:templateType/:companyAlias/:dataPath+`} component={Pages.PdfTemplate}/>

                    <Route path='*' component={Pages.PageNotFound}/>
                </Switch>
            )}
        </ConnectedRouter>
    );
};

export default Router;
