import React, {useEffect, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Separator from '@frontend/ui-kit/Components/Separator';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import Table from '@frontend/ui-kit/Components/Table';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Input from '@frontend/ui-kit/Components/Input';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {redirectTo, requestCompanyByAlias, requestTimeline} from '../../../actions/general';
import {
    requestAppConfiguration,
    requestEligibilityImportSession,
    requestVerificationReport,
    requestVerificationReportEmailsMatchedUpdating
} from '../../../actions/adminPortal';
import {isEmpty, getEqual, getItemKeyValue, equal} from '../../../utils';
import {REDASH_URL, ROUTES} from '../../../constants';
import {SYNC_DEDUCTIBLE_TYPES_OPTIONS} from '../../../options';
import {requestImplementationSpecialistsList, requestBasicTimelineUpdating} from '../../../actions/home';
import {getImplementationSpecialists} from '../../../selectors/home';
import './index.scss';

const COLLAPSES = {
    generalInformation: 'generalInformation',
    importConfigurationReview: 'importConfigurationReview',
    userAndCorePlanCounts: 'userAndCorePlanCounts',
    ancillaryPlans: 'ancillaryPlans',
    emails: 'emails'
};

const INITIATOR_TYPES = {
    danger: 'danger',
    warning: 'warning',
    success: 'success',
    caution: 'caution'
};

const ELIGIBILITY_LEAD_MAX_COUNT = 2;
const DEFAULT_OPEN_COLLAPSE_INDEX = 0;
const SUCCESS_EMAILS_PERCENTAGE = 90;

const VerificationReport = () => {
    const dispatch = useDispatch();
    const {id: importId} = useParams();
    const {search} = useLocation();
    const [importSession, setImportSession] = useState({});
    const [verificationReport, setVerificationReport] = useState({});
    const [timeline, setTimeline] = useState({implementation_specialists: []});
    const collapsesRefs = useRef({});
    const [collapseStates, setCollapseStates] = useState({[Object.keys(COLLAPSES)[DEFAULT_OPEN_COLLAPSE_INDEX]]: true});
    const [appConfig, setAppConfig] = useState({});
    const [isEligibilityLeadEdit, setIsEligibilityLeadEdit] = useState(false);
    const [eligibilityLeadValue, setEligibilityLeadValue] = useState([]);
    const implementationSpecialistsList = useSelector(getImplementationSpecialists);
    const implementationSpecialistsOptions = useMemo(() => {
        return eligibilityLeadValue.length >= ELIGIBILITY_LEAD_MAX_COUNT
            ? implementationSpecialistsList.map(specialist => ({...specialist, isDisabled: !eligibilityLeadValue.includes(specialist.value)}))
            : implementationSpecialistsList;
    }, [implementationSpecialistsList, eligibilityLeadValue]);

    const userCountsData = useMemo(() => {
        const {
            eligible_employees__file: eligibleEmployeesFile,
            eligible_employees__db: eligibleEmployeesDBCount,
            eligible_dependents__file: eligibleDependentsFile,
            eligible_dependents__db: eligibleDependentsDB,
            duplicate_users__file: duplicateUsersFile,
            duplicate_users__db: duplicateUsersDB
        } = verificationReport || {};

        return [
            {category: 'Eligible Employee', eligibility_file: eligibleEmployeesFile?.count, database: eligibleEmployeesDBCount, status: equal(eligibleEmployeesFile?.count, eligibleEmployeesDBCount)},
            {category: 'Eligible Dependents', eligibility_file: eligibleDependentsFile?.total, database: eligibleDependentsDB?.total, status: equal(eligibleDependentsFile?.total, eligibleDependentsDB?.total)},
            {category: 'Number of Duplicate Users in the DB', eligibility_file: duplicateUsersFile?.length, database: duplicateUsersDB?.length, status: equal(duplicateUsersFile?.length, duplicateUsersDB?.length)}
        ];
    }, [verificationReport]);

    const corePlansData = useMemo(() => {
        const {core_plans__file: corePlansFile = [], core_plans__db: corePlansDB = []} = verificationReport || {};
        const combinedPlans = corePlansFile.map(({category, plans}) => {
            const {plans: plansDB} = corePlansDB.find(getEqual(category, 'category')) || {};
            const [fileCount, databaseCount] = [plans, plansDB].map(plans => plans.reduce((acc, {count}) => acc + count, 0));

            return {category, eligibility_file: fileCount, database: databaseCount, status: equal(fileCount, databaseCount)};
        });
        const uniqDBPlans = corePlansDB
            .filter(({category: categoryDB}) => !combinedPlans.some(({category}) => equal(categoryDB, category)))
            .map(({category, count}) => ({category, eligibility_file: 0, database: count, status: equal(0, count)}));

        return [...combinedPlans, ...uniqDBPlans];
    }, [verificationReport]);

    const detailedCorePlansData = useMemo(() => {
        const {core_plans__file: corePlansFile = [], core_plans__db: corePlansDB = []} = verificationReport || {};
        const isError = corePlansFile.some(({category, plans}) => {
            const {plans: plansDB} = corePlansDB.find(getEqual(category, 'category')) || {};
            const [fileCount, databaseCount] = [plans, plansDB].map(plans => plans.reduce((acc, {count}) => acc + count, 0));
            return !equal(fileCount, databaseCount);
        });

        return [{category: 'Employees Enrolled in each Core Plan', status: !isError}];
    }, [verificationReport]);

    const ancillaryPlansData = useMemo(() => {
        const {ancillary_plans__file: ancillaryPlansFile = [], ancillary_plans__db: ancillaryPlansDB = []} = verificationReport || {};
        const [fileCount, databaseCount] = [ancillaryPlansFile, ancillaryPlansDB].map(plans => plans.reduce((acc, {count}) => acc + count, 0));

        return [{category: 'Count of Members Enrolled in Ancillary Plans', eligibility_file: fileCount, database: databaseCount, status: equal(fileCount, databaseCount)}];
    }, [verificationReport]);

    const emailsData = useMemo(() => {
        const {employee_emails__db: employeeEmailsDB, employee_emails__file: emailsFile = 0} = verificationReport || {};
        const {email: emailsDB = 0, contact_email: contactEmails = 0} = employeeEmailsDB || {};
        const emailPercentage = Math.round((emailsFile / emailsDB) * 100);

        return [{category: 'Employee Emails', status: equal(emailsFile, emailsDB), eligibility_file: emailsFile, email: emailsDB, contact_email: contactEmails, emails_status: emailPercentage >= SUCCESS_EMAILS_PERCENTAGE}];
    }, [verificationReport]);

    const getDataHasError = data => data.some(({status}) => equal(status, false));

    const isUserAndCorePlanCountsError = useMemo(() => [userCountsData, corePlansData].map(getDataHasError).filter(Boolean).length, [userCountsData, corePlansData]);

    useEffect(() => {
        (async () => {
            const {data: importSession} = await dispatch(requestEligibilityImportSession(importId));
            const [
                {verificationReport},
                {data: appConfig},
                {data: {timeline_id: timelineId} = {}}
            ] = await Promise.all([
                dispatch(requestVerificationReport({session_id: importId})),
                dispatch(requestAppConfiguration({companyAlias: importSession?.company_alias, type: 'platform'})),
                dispatch(requestCompanyByAlias(importSession?.company_alias)),
                dispatch(requestImplementationSpecialistsList())
            ]);

            setAppConfig(appConfig);
            setImportSession(importSession);
            setVerificationReport(verificationReport);

            if (!timelineId) {
                setTimeline(null);
                return false;
            }

            const {timeline} = await requestTimeline(timelineId);
            setTimeline(timeline);
            setEligibilityLeadValue(timeline.implementation_specialists.map(getItemKeyValue('id')));
        })();
    }, [importId, dispatch]);

    const onChangeCollapseState = key => state => setCollapseStates(states => ({...states, [key]: state}));

    const getCollapseProps = collapse => ({
        hasCollapseIcon: true,
        collapseIconClassName: 'collapse-icon',
        className: 'verification-report__collapse',
        isOpened: collapseStates[collapse],
        onChange: onChangeCollapseState(collapse)
    });

    const getInitiator = (title, collapse) => {
        const ref = element => {
            collapsesRefs.current[collapse] = element;
        };

        return (
            <span ref={ref} className='collapse-initiator'>
                <Heading type={HEADING_TYPES['2']}>{title}</Heading>
            </span>
        );
    };

    const getDetails = (key, value) => (
        <div className='details mt-20'>
            <div className='details__key mr-5'><Text>{key}:</Text></div>
            <div className='details__value'>{value}</div>
        </div>
    );

    const onGoToValidateFile = () => {
        const route = importSession?.company_data?.tpa_partner_id
            ? `${ROUTES.importsTpa}/${importSession?.company_data?.tpa_partner_id}/validation_report/${importId}${search}`
            : `${ROUTES.importsIndividual}/${importSession?.company_alias}/validation_report/${importId}${search}`;

        dispatch(redirectTo(route));
    };

    const onGoToReUploadFile = () => {
        const route = importSession?.company_data?.tpa_partner_id
            ? `${ROUTES.importsTpa}/${importSession?.company_data?.tpa_partner_id}/tpa_config${search}#upload_file`
            : `${ROUTES.importsIndividual}/${importSession?.company_alias}/group_config${search}&is_launch=true#upload_file`;

        dispatch(redirectTo(route));
    };

    const onGoToVerificationReportDetails = page => {
        const route = importSession?.company_data?.tpa_partner_id
            ? `${ROUTES.importsTpa}/${importSession?.company_data?.tpa_partner_id}/verification_report/${importId}/${page}${search}`
            : `${ROUTES.importsIndividual}/${importSession?.company_alias}/verification_report/${importId}/${page}${search}`;

        dispatch(redirectTo(route));
    };

    const onGoToAppConfig = () => dispatch(redirectTo(`${ROUTES.appConfiguration}/sftp/${importSession?.company_alias}`));

    const onSaveEligibilityLead = async () => {
        const query = {
            timelineId: timeline?.id,
            timelineInfo: {
                implementation_specialist_ids: eligibilityLeadValue,
                owner_id: timeline?.owner?.id
            }
        };
        const {timeline: updatedTimeline, isSuccess} = await dispatch(requestBasicTimelineUpdating(query));

        if (!isSuccess) {
            return false;
        }

        setTimeline(updatedTimeline);
        setIsEligibilityLeadEdit(false);
    };

    const getEligibilityLead = () => {
        if (equal(timeline, null)) {
            return <Text>Timeline does not exist</Text>;
        }

        return isEligibilityLeadEdit ? (
            <div className='select-eligibility-lead'>
                <Autocomplete value={eligibilityLeadValue} onChange={setEligibilityLeadValue} wrapperClassName='select-eligibility-lead__field' options={implementationSpecialistsOptions} isMulti label='Select Eligibility Lead'/>
                <Button onClick={onSaveEligibilityLead} className='select-eligibility-lead__button mt-10'>Save Update</Button>
            </div>
        ) : (
            <div className='eligibility-lead'>
                {!isEmpty(timeline.implementation_specialists) ? (
                    <React.Fragment>
                        <div className='eligibility-lead__list'>
                            {timeline.implementation_specialists.map(({id, first_name: firstName, last_name: lastName}) => (
                                <div key={id} className='eligibility-lead__item mt-5'>
                                    <Text type={TEXT_TYPES.bodyBold} className='eligibility-lead__circle mr-5'>{firstName[0]} {lastName[0]}</Text>
                                    <Text type={TEXT_TYPES.bodyBold}>{`${firstName} ${lastName}`}</Text>
                                </div>
                            ))}
                        </div>
                        <Button onClick={() => setIsEligibilityLeadEdit(true)} type={BUTTON_TYPES.tertiary} iconLeft={<Icon type={ICON_TYPES.edit}/>} className='eligibility-lead__button'>Edit</Button>
                    </React.Fragment>
                ) : <Button onClick={() => setIsEligibilityLeadEdit(true)} type={BUTTON_TYPES.tertiary} iconLeft={<Icon type={ICON_TYPES.add}/>} className='eligibility-lead__button'>Add Eligibility Lead</Button>}
            </div>
        );
    };

    const getSyncDeductible = () => {
        const {label} = SYNC_DEDUCTIBLE_TYPES_OPTIONS.find(getEqual(appConfig?.sync_deductible_type, 'value')) || {};
        return label || 'N/A';
    };

    const getMarkedText = (text, isMarked) => <Text><Icon className={classnames('marked-text-icon', 'mr-5', {'marked-text-icon_marked': isMarked})} type={isMarked ? ICON_TYPES.confirm : ICON_TYPES.close}/>{text}</Text>;

    const getTitleWithIcon = (title, initiatorType) => {
        const iconType = {
            [INITIATOR_TYPES.danger]: ICON_TYPES.alert,
            [INITIATOR_TYPES.warning]: ICON_TYPES.info,
            [INITIATOR_TYPES.caution]: ICON_TYPES.alert,
            [INITIATOR_TYPES.success]: ICON_TYPES.checkCircle
        }[initiatorType];

        return (
            <React.Fragment>
                <Icon className={classnames('title-icon', 'mr-5', {[`title-icon_${initiatorType}`]: initiatorType})} type={iconType}/>
                {title}
            </React.Fragment>
        );
    };

    const getWarningText = (text, className = 'mt-20') => <Text className={className} type={TEXT_TYPES.bodyBold}><Icon className='warning-icon mr-5' type={ICON_TYPES.alert}/>{text}</Text>;

    const AncillaryCardLinksComponent = ({text, ...props}) => (
        <Alert {...props} description={<Text>{text} <span className='ancillary_card_links' onClick={() => onGoToVerificationReportDetails('card_links')}>Ancillary card links</span>.</Text>} type={ALERT_TYPES.warning} hasIcon={false}>
            <Button className='in-text-button' onClick={() => onGoToVerificationReportDetails('card_links')} type={BUTTON_TYPES.tertiary}><Icon type={ICON_TYPES.externalLink}/></Button>
        </Alert>
    );

    const onConfirmEmailsMatched = async value => {
        const {isSuccess} = await dispatch(requestVerificationReportEmailsMatchedUpdating({session_id: importId, confirmation: value}));

        if (!isSuccess) {
            return false;
        }

        const {verificationReport} = await dispatch(requestVerificationReport({session_id: importId}));
        setVerificationReport(verificationReport);
    };

    const basicTableProps = {
        isFilterable: false,
        isSortable: false,
        className: 'mt-10',
        isCellTooltip: false
    };

    const getTableColumns = isCount => {
        const getStatus = ({value: status}) => <Sticker type={status ? STICKER_TYPES.success : STICKER_TYPES.danger}>{status ? 'Success' : 'Error'}</Sticker>;

        return [
            {Header: 'Category', accessor: 'category'},
            {Header: 'Status', accessor: 'status', Cell: getStatus},
            ...isCount ? [
                {Header: 'Eligibility File', accessor: 'eligibility_file'},
                {Header: 'Database', accessor: 'database'}
            ] : []
        ];
    };

    const getEmailsTableColumns = () => {
        const getStatus = ({value: status}) => <Sticker type={status ? STICKER_TYPES.success : STICKER_TYPES.danger}>{status ? 'Success' : 'Error'}</Sticker>;

        const getEmailsStatus = ({value: status}) => <Sticker type={status ? STICKER_TYPES.success : STICKER_TYPES.warning}>{status ? 'OK' : 'Warning'}</Sticker>;

        const emailsStatusHeader = (
            <Text>
                {'< 10% emails'}
                <Tooltip className='ml-3' content={<span>If more than 10% of emails are missing in the database, then ‘Warning’ status appears.<br/> If less than 10% of emails are missing in the database, then ‘OK’ status appears.</span>}>
                    <Icon className='emails-status-icon' type={ICON_TYPES.info}/>
                </Tooltip>
            </Text>
        );

        return [
            {Header: 'Category', accessor: 'category'},
            {Header: 'Status', accessor: 'status', Cell: getStatus},
            {Header: 'Eligibility File', accessor: 'eligibility_file'},
            {Header: 'Email', accessor: 'email'},
            {Header: 'Contact Email', accessor: 'contact_email'},
            {Header: emailsStatusHeader, accessor: 'emails_status', Cell: getEmailsStatus}
        ];
    };

    const getEmailsDetailsTableColumns = () => {
        return [
            {Header: 'First Name', accessor: 'first_name'},
            {Header: 'Last Name', accessor: 'last_name'},
            {Header: 'Email', accessor: 'email'}
        ];
    };

    return (
        <div className='verification-report'>
            <Row middle='xs'>
                <Column sm>
                    <Heading type={HEADING_TYPES['5']} className='mb-5'>{importSession?.additional_data?.company_name}</Heading>
                    <Heading type={HEADING_TYPES['1']}>{importSession?.company_alias}</Heading>
                </Column>
                <Column constant className='pr-5'>
                    <Button onClick={onGoToValidateFile} type={BUTTON_TYPES.secondary}>View Validation File</Button>
                    <Button onClick={onGoToReUploadFile} className='ml-10'>Re-upload File</Button>
                </Column>
            </Row>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(COLLAPSES.generalInformation)}
                    initiator={getInitiator('General Information', COLLAPSES.generalInformation)}
                    content={(
                        <div className='pb-10'>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    {/* FYI: Renewal config type will be implemented after renewal imports are completed for v2 (05.13.2024) */}
                                    {getDetails('Config Type', <Sticker type={STICKER_TYPES.default}>{importSession?.company_data?.group_launched ? 'Maintenance' : 'New Launch'}</Sticker>)}
                                    {getDetails('Eligibility Definition', <Text type={TEXT_TYPES.bodyBold}><Tooltip content='Coming soon'><Icon type={ICON_TYPES.info}/></Tooltip></Text>)}
                                </Column>
                                <Column sm={6}>
                                    {getDetails('Allowable Condition', isEmpty(importSession?.import_config?.allowable_condition)
                                        ? <Text type={TEXT_TYPES.bodyBold}>N/A</Text>
                                        : <Input value={JSON.stringify(importSession?.import_config?.allowable_condition)} disabled/>)}
                                    {getDetails('Eligibility Lead', getEligibilityLead())}
                                </Column>
                            </Row>
                            <Separator/>
                            <Row middle='xs'>
                                <Column sm>
                                    <Heading type={HEADING_TYPES['3']} className='mb-5'>Campaign Settings</Heading>
                                </Column>
                                <Column constant>
                                    <Button onClick={onGoToAppConfig} type={BUTTON_TYPES.tertiary} iconLeft={<Icon type={ICON_TYPES.externalLink}/>}>Go to App Configurations</Button>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    {getDetails('SFTP Automation Status', <Text type={TEXT_TYPES.bodyBold}>{appConfig?.automation_status}</Text>)}
                                </Column>
                                <Column sm={6}>
                                    {getDetails('Deductible Sync', <Text type={TEXT_TYPES.bodyBold}>{getSyncDeductible()}</Text>)}
                                </Column>
                            </Row>
                        </div>
                    )}/>
            </ContentSection>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(COLLAPSES.importConfigurationReview)}
                    initiator={getInitiator('Import Configuration Review', COLLAPSES.importConfigurationReview)}
                    content={(
                        <div className='pb-10 mt-10'>
                            <Heading type={HEADING_TYPES['5']}>Empty Fields Conditions</Heading>
                            <Row className='mt-10'>
                                <Column sm={4}>{getMarkedText('Delete Plans If Empty', importSession?.import_config?.delete_plans_if_empty)}</Column>
                                <Column sm={4}>{getMarkedText('Delete Ancillary Plans If Empty', importSession?.import_config?.delete_ancillary_plans_if_empty)}</Column>
                                <Column sm={4}>{getMarkedText('Terminate if Not in File', importSession?.import_config?.terminate_if_not_in_file)}</Column>
                            </Row>
                            <Heading type={HEADING_TYPES['5']} className='mt-15'>Inherit Conditions</Heading>
                            <Row className='mt-10'>
                                <Column sm={4}>{getMarkedText('Inherit Core Plans', importSession?.import_config?.inherit_core_plans)}</Column>
                                <Column sm={4}>{getMarkedText('Inherit Ancillary Plans', importSession?.import_config?.inherit_ancillary_plans)}</Column>
                                <Column sm={4}>{getMarkedText('Inherit Member Ids for Dependents', importSession?.import_config?.inherit_member_ids_for_dependents)}</Column>
                            </Row>
                        </div>
                    )}/>
            </ContentSection>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(COLLAPSES.userAndCorePlanCounts)}
                    initiator={getInitiator(getTitleWithIcon('User and Core Plan Counts', isUserAndCorePlanCountsError ? INITIATOR_TYPES.danger : INITIATOR_TYPES.success), COLLAPSES.userAndCorePlanCounts)}
                    description={isUserAndCorePlanCountsError && !collapseStates[COLLAPSES.userAndCorePlanCounts] ? <Alert description='Investigation and fix required.' type={ALERT_TYPES.danger} hasIcon={false}/> : null}
                    content={(
                        <div className='pb-10'>
                            {getDataHasError(userCountsData) && <Alert className='mb-20' description='Investigation and fix required.' type={ALERT_TYPES.danger} hasIcon={false}/>}
                            <Row className='mt-10'>
                                <Column sm>
                                    <Heading type={HEADING_TYPES['3']}>User Counts</Heading>
                                </Column>
                                <Column constant>
                                    <Link href={`${REDASH_URL}/queries/3407/source?p_campaign_alias=${importSession?.company_alias}&p_config_type=Comparison&p_group_vol_by_name=No&p_show_jsondata=No`} target='_blank'>
                                        <Icon className='mr-3' type={ICON_TYPES.externalLink}/> Open Overall Query
                                    </Link>
                                </Column>
                            </Row>
                            <Table {...basicTableProps} data={userCountsData} columns={getTableColumns(true)}/>
                            {getDataHasError(corePlansData) && <Alert className='mt-20' description='Investigation and fix required.' type={ALERT_TYPES.danger} hasIcon={false}/>}
                            <Row className='mt-20'>
                                <Column sm>
                                    <Heading type={HEADING_TYPES['3']}>Core Plans</Heading>
                                </Column>
                                <Column constant>
                                    <Link href={`${REDASH_URL}/queries/3411?p_alias=${importSession?.company_alias}&p_config_type=Regular&p_plan_condition=Current`} target='_blank'>
                                        <Icon className='mr-3' type={ICON_TYPES.externalLink}/> Open Employees Enrolled in each Core Plan Query
                                    </Link>
                                </Column>
                            </Row>
                            <Table {...basicTableProps} data={corePlansData} columns={getTableColumns(true)}/>
                            {getWarningText('Confirm a side-by-side comparison of the values in the eligibility file vs the values in the database for the number of EE enrolled in each core plan.')}
                            <Row className='mt-20' end='xs'>
                                <Column constant>
                                    <Button onClick={() => onGoToVerificationReportDetails('core_plans')} type={BUTTON_TYPES.tertiary} iconLeft={<Icon type={ICON_TYPES.externalLink}/>}>View and Confirm Detailed Core Plans</Button>
                                </Column>
                            </Row>
                            <Table {...basicTableProps} data={detailedCorePlansData} columns={getTableColumns()}/>
                        </div>
                    )}/>
            </ContentSection>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(COLLAPSES.ancillaryPlans)}
                    initiator={getInitiator(getTitleWithIcon('Ancillary Plans', getDataHasError(ancillaryPlansData) ? INITIATOR_TYPES.danger : INITIATOR_TYPES.warning), COLLAPSES.ancillaryPlans)}
                    description={getDataHasError(ancillaryPlansData)
                        ? <Alert description='Investigation and fix required.' type={ALERT_TYPES.danger} hasIcon={false}/>
                        : (!collapseStates[COLLAPSES.ancillaryPlans] && <AncillaryCardLinksComponent text='View and confirm'/>)}
                    content={(
                        <div className='pb-10 mt-10'>
                            <Row end='sm'>
                                <Column constant className='pr-5'>
                                    <Link href={`${REDASH_URL}/queries/3419?p_alias=${importSession?.company_alias}&p_group_vol_by_name=No`} target='_blank'>
                                        <Icon className='mr-3' type={ICON_TYPES.externalLink}/> Open Ancillary Plans Import Correctly Query
                                    </Link>
                                </Column>
                            </Row>
                            <Table {...basicTableProps} data={ancillaryPlansData} columns={getTableColumns(true)}/>
                            <AncillaryCardLinksComponent className='mt-20' text='Confirm'/>
                        </div>
                    )}/>
            </ContentSection>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(COLLAPSES.emails)}
                    initiator={getInitiator(getTitleWithIcon('Emails', emailsData[0].emails_status ? INITIATOR_TYPES.caution : INITIATOR_TYPES.warning), COLLAPSES.emails)}
                    description={!emailsData[0].emails_status && <Alert description='An investigation or fix is NOT required. But, it is recommended to inform Customer Success for additional confirmation.' type={ALERT_TYPES.warning} hasIcon={false}/>}
                    content={(
                        <div className='pb-10 mt-10'>
                            <Row end='sm'>
                                <Column constant className='pr-5'>
                                    <Link href={`${REDASH_URL}/queries/3769?p_alias=${importSession?.company_alias}`} target='_blank'>
                                        <Icon className='mr-3' type={ICON_TYPES.externalLink}/> Open Employee Emails Query
                                    </Link>
                                </Column>
                            </Row>
                            <Table {...basicTableProps} data={emailsData} columns={getEmailsTableColumns()}/>
                            {!importSession?.company_data?.group_launched && (
                                <Collapse hasCollapseIcon
                                    isOpened
                                    className='mt-10'
                                    initiator={(
                                        <Text type={TEXT_TYPES.bodyBold}>
                                            <Icon className={classnames('emails-matched-icon', 'mr-5', {'emails-matched-icon__success': verificationReport?.emails_matched_confirmation})} type={verificationReport?.emails_matched_confirmation ? ICON_TYPES.checkCircle : ICON_TYPES.alert}/>
                                            Confirm {verificationReport?.emails_matched?.length} emails match the EE’s information
                                        </Text>
                                    )}
                                    content={(
                                        <React.Fragment>
                                            {verificationReport?.emails_matched_confirmation ? (
                                                <Alert type={ALERT_TYPES.success} description={`All ${verificationReport?.emails_matched?.length} emails were confirmed and matched the EE's information.`}>
                                                    <Button onClick={() => onConfirmEmailsMatched(false)} className='in-text-button' type={BUTTON_TYPES.tertiarySuccess} iconLeft={<Icon type={ICON_TYPES.close}/>}>Undo Confirmation</Button>
                                                </Alert>
                                            ) : (
                                                <Row end='xs'>
                                                    <Column constant className='pr-5'>
                                                        <Button onClick={() => onConfirmEmailsMatched(true)} type={BUTTON_TYPES.secondary} iconLeft={<Icon type={ICON_TYPES.confirm}/>}>Emails match the EE’s information</Button>
                                                    </Column>
                                                </Row>
                                            )}
                                            <Table {...basicTableProps} data={verificationReport?.emails_matched} columns={getEmailsDetailsTableColumns()}/>
                                        </React.Fragment>
                                    )}/>
                            )}
                        </div>
                    )}/>
            </ContentSection>
        </div>
    );
};

export {VerificationReport as TestableVerificationReport};
export default VerificationReport;
