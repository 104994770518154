import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import {Form, Field} from '../../shared/FormComponents';
import {getItemKeyValue, validateRequired} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {requestImplementationManagersList, requestTimelineUpdating, requestTimelineCreating, requestCsmList, requestImplementationSpecialistsList} from '../../../actions/home';
import {requestTimelineTemplates} from '../../../actions/adminPortal';
import {getActiveCompanyStageCriteria, getTimeline, getTimelineExistenceStatus} from '../../../selectors/general';
import {getImplementationManagersList, getImplementationSpecialists, getCsmList} from '../../../selectors/home';
import {FORMS, ROUTES, COMPANY_STAGES, TIMELINE_TEMPLATE_TYPES} from '../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => ({
    owner_id: validateRequired(values.owner_id),
    launch_date: validateRequired(values.launch_date),
    implementation_manager_id: validateRequired(values.implementation_manager_id)
});

const TimelinePreparingForm = () => {
    const dispatch = useDispatch();
    const implementationManagersList = useSelector(getImplementationManagersList);
    const implementationSpecialistsList = useSelector(getImplementationSpecialists);
    const csmList = useSelector(getCsmList);
    const isTimelineExisted = useSelector(getTimelineExistenceStatus);
    const isCompanyLaunching = useSelector(state => getActiveCompanyStageCriteria(state, COMPANY_STAGES.launch));
    const {owner, launch_date: launchDate, implementation_manager: implementationManager, implementation_specialists: implementationSpecialists} = useSelector(getTimeline);
    const [initialValues, setInitialValues] = useState({
        launch_date: launchDate,
        owner_id: owner?.id,
        implementation_manager_id: implementationManager?.id,
        implementation_specialist_ids: implementationSpecialists?.map(getItemKeyValue('id')) || []
    });
    const [availableDates, setAvailableDate] = useState([]);

    useEffect(() => {
        dispatch(requestCsmList());
        dispatch(requestImplementationManagersList());
        dispatch(requestImplementationSpecialistsList());
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const {data: timelineTemplates} = await dispatch(requestTimelineTemplates({type: TIMELINE_TEMPLATE_TYPES.implementation, status: 'active'}));
            const launchDates = timelineTemplates.map(({launch_date: launchDate}) => launchDate);
            setAvailableDate(launchDates);
        })();
    }, [dispatch]);

    const onSubmit = useCallback(async values => {
        const submit = isTimelineExisted ? requestTimelineUpdating : requestTimelineCreating;
        const {isSuccess, submissionErrors} = await dispatch(submit(values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(values);
    }, [isTimelineExisted, dispatch]);
    const onSubmitSuccess = () => dispatch(redirectTo(ROUTES.timeline));

    return (
        <ContentSection className='timeline-preparing-form'>
            <Form name={FORMS.timelinePreparing} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
                {({handleSubmit}) => {
                    return (
                        <form data-testid='timeline-preparing-form' onSubmit={handleSubmit} noValidate>
                            <Text className='timeline-preparing-form__title' type={TEXT_TYPES.bodyBold}>Generate Implementation Timeline</Text>

                            <Field name='owner_id'>
                                {props => <Select {...props} options={csmList} label='Select CSM' isRequired wrapperClassName='mb-12'/>}
                            </Field>

                            <Field name='launch_date'>
                                {props => (
                                    <DatePicker {...props}
                                        includeDates={availableDates}
                                        disabled={!isCompanyLaunching}
                                        minDate={new Date()}
                                        label='Select Launch Date'
                                        placeholder='Choose date...'
                                        wrapperClassName='mb-12'
                                        isRequired/>
                                )}
                            </Field>

                            <Field name='implementation_manager_id'>
                                {props => <Select {...props} options={implementationManagersList} label='Select Implementation Manager' isRequired wrapperClassName='mb-12'/>}
                            </Field>

                            <Field name='implementation_specialist_ids'>{props => {
                                const {value} = props;
                                const options = value.length >= 2
                                    ? implementationSpecialistsList.map(specialist => ({...specialist, isDisabled: !value.includes(specialist.value)}))
                                    : implementationSpecialistsList;

                                return <Autocomplete {...props} options={options} isMulti label='Select Implementation Specialist(s) (2 max)' wrapperClassName='mb-12'/>;
                            }}
                            </Field>

                            <div className='timeline-preparing-form-action-bar'>
                                <Button className='timeline-preparing-form-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>View timeline</Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ContentSection>
    );
};

export {TimelinePreparingForm as TestableTimelinePreparingForm};
export default React.memo(TimelinePreparingForm);
