import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import {CONTACT_STATUSES} from '../../../constants';
import './index.scss';

const getContentByStatus = (firstName, companyName) => ({
    [CONTACT_STATUSES.created]: {
        title: 'Invitation Sent',
        text: `${firstName} was sent an email with instructions for logging into the HealthJoy dashboard. If they haven’t received the email, make sure they check their Spam folder.`,
        buttonTitle: 'Go back to contact list'
    },
    [CONTACT_STATUSES.added]: {
        title: 'Success',
        text: `${firstName} was sent an email that they now have access to the dashboard for ${companyName}.`,
        buttonTitle: 'Go to contact list'
    },
    [CONTACT_STATUSES.addedRemindActivation]: {
        title: 'Invitation Sent',
        text: `${firstName} was sent an email that they’ve been added to the dashboard for ${companyName}. If they haven’t received the email, make sure they check their Spam folder.`,
        buttonTitle: 'Remind to activate'
    },
    [CONTACT_STATUSES.existed]: {
        title: 'This is existed contact in company',
        text: `${firstName} was not sent an email because they have already been added to the dashboard for ${companyName} and have logged in.`,
        buttonTitle: 'Go to contact list'
    },
    [CONTACT_STATUSES.existedRemindActivation]: {
        title: 'This is existed contact',
        text: `${firstName} has already been added to the dashboard for ${companyName}. Would you like us to send a reminder email?`,
        buttonTitle: 'Remind to activate'
    }
});

const NewContactInfoPopup = ({status, firstName, companyName, onAct}) => {
    const CONTENT_BY_STATUS = getContentByStatus(firstName, companyName);
    const {title, text, buttonTitle} = CONTENT_BY_STATUS[status];
    const actionBar = <Button onClick={onAct}>{buttonTitle}</Button>;
    const props = {
        title: <span data-testid='new-contact-popup-title'>{title}</span>,
        actionBar
    };

    return (
        <PopupContent {...props}>
            <Text className='new-contact-info-popup-body'>{text}</Text>
        </PopupContent>
    );
};

NewContactInfoPopup.propTypes = {
    status: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    onAct: PropTypes.func.isRequired
};

export {NewContactInfoPopup as TestableNewContactInfoPopup};
export default React.memo(NewContactInfoPopup);
