import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import Input from '@frontend/ui-kit/Components/Input';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {formatList, normalizeList, trimStart} from '../../../utils';

const HEALTHCARE_TOOLTIP_CONTENT = 'Selecting this toggle will display the IRS contribution limit for Healthcare FSA on the card';
const LIMITED_PURPOSE_TOOLTIP_CONTENT = 'Selecting this toggle will display the IRS contribution limit for Limited purpose FSA on the card';
const DEPENDENT_CARE_TOOLTIP_CONTENT = 'Selecting this toggle will display the IRS contribution limit for Dependent care FSA on the card';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'main_paragraph', label: 'Main Paragraph'},
    {name: 'card_list', label: 'List'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const FsaForm = ({card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isFinAccount/>

            <CardHeaderInfo/>

            <CopyableField name='main_paragraph' parse={trimStart}>
                {props => <Textarea {...props} placeholder='Please enter...' label='Main Paragraph' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='display_card_settings.show_employer_contribution'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption='Show Employer Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='individual_contribution' parse={trimStart}>
                {props => <Input {...props} placeholder='Enter Employer Individual Contribution...' label='Employer Individual Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='family_contribution' parse={trimStart}>
                {props => <Input {...props} placeholder='Enter Employer Family Contribution...' label='Employer Family Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <Text className='ancillary-plan-details-form__irs-title' type={TEXT_TYPES.bodyBold}>IRS Contribution Limit</Text>

            <CopyableField name='display_card_settings.show_healthcare'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption={<LabeledTooltip title='Show Healthcare FSA' content={HEALTHCARE_TOOLTIP_CONTENT}/>}/>}
            </CopyableField>

            <Separator type='dashed'/>

            <CopyableField name='display_card_settings.show_limited_purpose'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption={<LabeledTooltip title='Show Limited Purpose FSA' content={LIMITED_PURPOSE_TOOLTIP_CONTENT}/>}/>}
            </CopyableField>

            <Separator type='dashed'/>

            <CopyableField name='display_card_settings.show_dependent_care'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption={<LabeledTooltip title='Show Dependent Care FSA' content={DEPENDENT_CARE_TOOLTIP_CONTENT}/>} wrapperClassName='mb-13'/>}
            </CopyableField>

            <CopyableField name='card_list' parse={normalizeList} format={formatList}>
                {props => <Textarea {...props} placeholder='List' label='List'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

FsaForm.propTypes = {
    card: PropTypes.string
};

export {FsaForm as TestableFsaForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(FsaForm);
