import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import MultipleChoice from '../MultipleChoice';
import AppConfigurationStatusSticker from '../AppConfigurationStatusSticker';
import ConsultFeeReviewPopup from '../ConsultFeeReviewPopup';
import withPopup from '../../../HOC/withPopup';
import withToast from '../../../HOC/withToast';
import {getCompaniesMap} from '../../../selectors/general';
import {getMultipleChoiceValues} from '../../../selectors/adminPortal';
import {requestAppConfigurations, setMultipleChoiceValues} from '../../../actions/adminPortal';
import {redirectTo, requestActiveCompany, requestCompanies} from '../../../actions/general';
import {formatDate, promisifyAsyncFunction, compose, parseQuery} from '../../../utils';
import {APP_CONFIG_STATUSES, ROUTES} from '../../../constants';
import './index.scss';

const POPUP_ID = 'consultFeePopup';

const AppConfigurationLayout = ({openPopup, closePopup, showToast}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname, search} = useLocation();
    const [addonsData, setAddonsData] = useState({});
    const [companyAlias, setCompanyAlias] = useState();
    const companies = useSelector(getCompaniesMap);
    const activeCompany = useSelector(getMultipleChoiceValues);

    const loadCompanyOptions = promisifyAsyncFunction(async query => {
        const {companies} = await dispatch(requestCompanies(query, {isInactiveIncluded: true}));
        return companies.map(({name: value}) => ({label: value, value}));
    });

    const requestProducts = useCallback(async ({company_alias, company_name}) => {
        const companyAlias = company_alias ?? companies[company_name]?.alias;
        const {data, isSuccess} = await dispatch(requestAppConfigurations(companyAlias));
        setCompanyAlias(companyAlias);

        if (!isSuccess) {
            return;
        }

        setAddonsData(data);
    }, [companies, dispatch]);

    useEffect(() => {
        const {toast_content: toastContent} = parseQuery(search);

        if (!toastContent) {
            return;
        }

        showToast({content: toastContent});
        history.replace(pathname);
    }, []);

    useEffect(() => {
        (async () => {
            if (activeCompany.company_name && companies[activeCompany.company_name]) {
                return requestProducts(activeCompany);
            }

            const {data: currentActiveCompany} = await dispatch(requestActiveCompany());
            dispatch(setMultipleChoiceValues({company_name: currentActiveCompany.name}));
            requestProducts({company_alias: currentActiveCompany.alias});
        })();
    }, [dispatch]);

    const openConsultFeePopup = () => {
        const popupProps = {
            companyAlias,
            onCancel: closePopup,
            isEditDisabled: !addonsData.is_active
        };

        openPopup({type: POPUP_TYPES.right, children: <ConsultFeeReviewPopup {...popupProps}/>});
    };

    const getFormattedDate = date => formatDate(date, 'M/d/yyyy');

    const multipleChoiceProps = {
        choices: [{name: 'Company', type: 'company_name', loadOptions: loadCompanyOptions}],
        onChange: requestProducts
    };

    const onEdit = type => () => dispatch(redirectTo(`${ROUTES.appConfiguration}/${type}/${companyAlias}`));

    const getAddonStatus = status => addonsData.is_active ? status?.toLowerCase() : APP_CONFIG_STATUSES.terminated;

    const getRowItem = ({title, type, start_date: startDate, end_date: endDate, status}) => (
        <ContentSection className='addon-item' key={type}>
            <Row middle='xs' between='xs'>
                <Column xs>
                    <Text type={TEXT_TYPES.bodyBold}>{title}</Text>
                </Column>
                <Column xs constant>
                    <Text type={TEXT_TYPES.bodyBold} className={!startDate && 'addon-item__empty-date'}>
                        {startDate ? (
                            <React.Fragment>
                                <Text className='addon-item__date-label'>Start Date</Text>
                                {getFormattedDate(startDate)}
                            </React.Fragment>
                        ) : 'Start Date'}
                    </Text>
                </Column>
                <Column constant>
                    <Icon type={ICON_TYPES.sort} className={`addon-item__dates-divider ${startDate || endDate ? 'addon-item__dates-divider-active' : ''}`}/>
                </Column>
                <Column xs>
                    <Text type={TEXT_TYPES.bodyBold} className={!endDate && 'addon-item__empty-date'}>
                        {endDate ? (
                            <React.Fragment>
                                <Text className='addon-item__date-label'>End Date</Text>
                                {getFormattedDate(endDate)}
                            </React.Fragment>
                        ) : 'End Date'}
                    </Text>
                </Column>
                <Column xs constant>
                    <div className='addon-item__status'>
                        <AppConfigurationStatusSticker status={getAddonStatus(status)}/>
                    </div>
                </Column>
                <Column xs constant>
                    <Button type={BUTTON_TYPES.tertiary} className='addon-item__edit' onClick={onEdit(type)}>
                        View & Edit
                    </Button>
                </Column>
            </Row>
        </ContentSection>
    );

    return (
        <div className='app-configurations'>
            <Heading className='mb-20' type={HEADING_TYPES['1']}>App Configurations</Heading>

            <div className='search'>
                <Row bottom='xs' columnGap='xs'>
                    <Column xs>
                        <Heading className='search__title' type={HEADING_TYPES['4']}>Search</Heading>
                    </Column>
                </Row>

                <ContentSection className='search__content-section'>
                    <MultipleChoice {...multipleChoiceProps}/>
                </ContentSection>
            </div>

            <Row middle='xs' className='mt-24 mb-20'>
                <Column xs>
                    <Heading type={HEADING_TYPES['3']}>Platform Configuration</Heading>
                </Column>
            </Row>
            {addonsData?.platform_config?.map(getRowItem)}

            <Row middle='xs' className='mt-24'>
                <Column xs>
                    <Heading type={HEADING_TYPES['3']}>Add-on Products</Heading>
                </Column>
                <Column xs constant>
                    <Button type={BUTTON_TYPES.secondary} className='app-configurations__consult-fee' onClick={openConsultFeePopup} data-testid='open-consult-fee-popup'>
                        <Icon type={ICON_TYPES.money} className='consult-fee-icon'/> Consult Fee Review
                    </Button>
                </Column>
            </Row>
            {addonsData?.vendor_products?.map(({vendor, products}) => (
                <React.Fragment>
                    <Heading type={HEADING_TYPES['4']} className='app-configurations__subtitle'>{vendor}</Heading>
                    {products.map(getRowItem)}
                </React.Fragment>
            ))}
        </div>
    );
};

AppConfigurationLayout.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
    showToast: PropTypes.func
};

export {AppConfigurationLayout as TestableAppConfigurationLayout};
export default compose(
    withPopup(POPUP_ID),
    withToast
)(AppConfigurationLayout);
