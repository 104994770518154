import apiServices from '../apiServices';
import {
    CLEAR_FORM_REVISIONS,
    CLOSE_POPUP,
    FINISH_LOADING,
    GO_NEXT_WIZARD_STEP,
    GO_PREV_WIZARD_STEP,
    OPEN_POPUP,
    RECEIVE_ANCILLARY_CARRIERS,
    RECEIVE_CORE_CARRIERS,
    RECEIVE_FORM_REVISIONS,
    SET_DIRTY_FORMS,
    SET_WIZARD_ACTIVE_STEP,
    START_LOADING
} from './actionTypes';

const receiveCoreCarriers = coreCarriers => ({type: RECEIVE_CORE_CARRIERS, coreCarriers});

const receiveAncillaryCarriers = ancillaryCarriers => ({type: RECEIVE_ANCILLARY_CARRIERS, ancillaryCarriers});

export const setDirtyForms = dirtyForms => ({type: SET_DIRTY_FORMS, dirtyForms});

export const receiveFormRevisions = formRevisions => ({type: RECEIVE_FORM_REVISIONS, formRevisions});

export const clearFormRevisions = () => ({type: CLEAR_FORM_REVISIONS});

export const startLoading = isLoader => ({type: START_LOADING, isLoader});

export const finishLoading = () => ({type: FINISH_LOADING});

export const openPopup = params => ({type: OPEN_POPUP, params});

export const closePopup = ({id}) => ({type: CLOSE_POPUP, id});

export const setWizardActiveStep = activeStep => ({type: SET_WIZARD_ACTIVE_STEP, activeStep});

export const goNextWizardStep = () => (dispatch, getState) => {
    const {shared: {wizard}} = getState();
    const {activeStep} = wizard;

    dispatch({type: GO_NEXT_WIZARD_STEP, activeStep: activeStep + 1});
};

export const goPrevWizardStep = () => (dispatch, getState) => {
    const {shared: {wizard}} = getState();
    const {activeStep} = wizard;

    dispatch({type: GO_PREV_WIZARD_STEP, activeStep: activeStep - 1});
};

export const requestCoreCarriers = carrierName => async dispatch => {
    const query = {all: carrierName};
    const {data, isSuccess} = await apiServices.getMedtoolIssuers({query}); // FYI: we use MedtoolIssuers instead CoreCarriers according to transfer api to the new one (Pasha, 27.05.2021)
    const coreCarriers = isSuccess ? data : [];

    dispatch(receiveCoreCarriers(coreCarriers));

    return coreCarriers;
};

export const requestAncillaryCarriers = query => async dispatch => {
    const {data, isSuccess} = await apiServices.getAncillaryCarrierList({query});
    const ancillaryCarriers = isSuccess ? data : [];

    dispatch(receiveAncillaryCarriers(ancillaryCarriers));

    return ancillaryCarriers;
};
