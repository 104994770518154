import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Select from '@frontend/ui-kit/Components/Select';
import {Field} from '../../shared/FormComponents';
import withEligibilityInfoForm from '../../../HOC/withEligibilityInfoForm';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {requestBenAdminList, requestBenAdminCreating} from '../../../actions/eligibility';
import {getIsSuperAdmin} from '../../../selectors/general';
import {validateRequired, equal, getEqual, pass, promisifyAsyncFunction} from '../../../utils';
import {ENROLMENT_SOURCE_OPTIONS} from '../../../options';

const EMPLOYEE_NAVIGATOR = 'employee_navigator';
const BEN_ADMIN = 'ben_admin';

/* istanbul ignore next */
const validate = values => {
    const {enrollment_source: enrollmentSource, enrollment_system: enrollmentSystem} = values;
    const isEmployeeNavigator = equal(enrollmentSource, EMPLOYEE_NAVIGATOR);

    return {
        enrollment_source: validateRequired(enrollmentSource),
        enrollment_system: !isEmployeeNavigator ? validateRequired(enrollmentSystem) : undefined
    };
};

const SourceStep = () => {
    const dispatch = useDispatch();
    const form = useForm();
    const {values} = useFormState();
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const {enrollment_source: enrollmentSource} = values;
    const [isEmployeeNavigator, isBenAdmin] = [EMPLOYEE_NAVIGATOR, BEN_ADMIN].map(getEqual(enrollmentSource));

    const loadBenAdminOptions = useCallback(promisifyAsyncFunction(async query => {
        const {benAdminList} = await dispatch(requestBenAdminList(query));

        return benAdminList.map(({name: label}) => ({label, value: label}));
    }), []);

    const onCreateBenAdmin = name => {
        dispatch(requestBenAdminCreating(name));
        form.change('enrollment_system', name);
    };

    const onChangeEnrollmentSource = () => {
        form.change('enrollment_system', null);
    };

    return (
        <Row className='eligibility-wizard-step eligibility-wizard-step_source'>
            <Column sm={6}>
                <Field name='enrollment_source' onChange={onChangeEnrollmentSource}>
                    {props => <Select {...props} options={ENROLMENT_SOURCE_OPTIONS} placeholder='Select one...' label='How do you collect employee enrollment information?' isRequired wrapperClassName='mb-12'/>}
                </Field>

                {isEmployeeNavigator && (
                    <Text>Please reach out to your Employee Navigator contact to request a Data Exchange with HealthJoy as the vendor</Text>
                )}

                {!isEmployeeNavigator && (
                    <React.Fragment>
                        {isBenAdmin && (
                            <Field name='enrollment_system'>
                                {props => (
                                    <AsyncAutocomplete {...props}
                                        isCreatable={isSuperAdmin}
                                        onCreateOption={onCreateBenAdmin}
                                        filterOption={pass}
                                        loadOptions={loadBenAdminOptions}
                                        placeholder='Type to select Benefit Administration Software...'
                                        label='Which System?'
                                        isRequired/>
                                )}
                            </Field>
                        )}

                        {!isBenAdmin && (
                            <Field name='enrollment_system'>{props => <Input {...props} placeholder='Please Enter...' label='Which System?' isRequired/>}</Field>
                        )}
                    </React.Fragment>
                )}
            </Column>
        </Row>
    );
};

export {SourceStep as TestableSourceStep};
export default withEligibilityInfoForm({validate})(SourceStep);
