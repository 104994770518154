import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {splitIntoChunks} from '../../../utils';
import './index.scss';

const ANCILLARY_ITEMS_COLUMN_COUNT = 10;

const ImportCardDetails = ({title, data, isAncillary}) => {
    const [ancillaryColumnCount, isAncillaryFuture] = useMemo(() => ([
        Math.ceil(data.length / ANCILLARY_ITEMS_COLUMN_COUNT),
        data.some(({future}) => future > 0)
    ]), [data]);

    const getDetail = ({key, value}) => (
        <Row className='detail' key={key}>
            <Column sm={10} className='detail__key'><Text>{key}</Text></Column>
            <Column sm={2} className='detail__value'><Text>{value}</Text></Column>
        </Row>
    );

    const getAncillaryDetails = (details, index) => {
        const valueColumnSize = isAncillaryFuture ? 3 : 2;

        return (
            <Column sm={12 / ancillaryColumnCount} key={index}>
                {isAncillaryFuture && (
                    <Row end='sm' className='ancillary-title'>
                        <Column sm={valueColumnSize} className='detail__value'><Text type={TEXT_TYPES.bodyBold}>Active</Text></Column>
                        <Column sm={valueColumnSize} className='detail__value'><Text type={TEXT_TYPES.bodyBold}>Future</Text></Column>
                    </Row>
                )}
                {details.map(({key, value, future}) => (
                    <Row className='detail'>
                        <Column sm className='detail__key'><Text>{key}</Text></Column>
                        <Column sm={valueColumnSize} className='detail__value'><Text>{value}</Text></Column>
                        {isAncillaryFuture && <Column sm={valueColumnSize} className='detail__value'><Text>{future}</Text></Column>}
                    </Row>
                ))}
            </Column>
        );
    };

    const Component = (
        <div className='import-card-details'>
            {!isAncillaryFuture && (
                <div className='import-card-details__title-box'>
                    <Heading className='import-card-details__title' type={HEADING_TYPES['5']}>{title}</Heading>
                </div>
            )}

            <div className='import-card-details__data'>
                {isAncillary
                    ? <Row>{splitIntoChunks(data, ANCILLARY_ITEMS_COLUMN_COUNT).map(getAncillaryDetails)}</Row>
                    : data.map(getDetail)}
            </div>
        </div>
    );

    return isAncillary
        ? (
            <Row>
                <Column sm={3 * ancillaryColumnCount}>
                    {Component}
                </Column>
            </Row>
        )
        : Component;
};

ImportCardDetails.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    data: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
    })),
    isAncillary: PropTypes.bool
};

ImportCardDetails.defaultProps = {
    data: []
};

export {ImportCardDetails as TestableImportCardDetails};
export default ImportCardDetails;
