import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import LabeledTooltip from '../../shared/LabeledTooltip';
import MedicalPlanBasicInfo, {
    validate as validateMedicalPlanBasicInfo,
    validateOnSubmit as validateMedicalPlanBasicInfoOnSubmit
} from '../MedicalPlanBasicInfo';
import ProviderNetworks, {validateOnSubmit as validateProviderNetworksOnSubmit} from '../ProviderNetworks';
import withCorePlanDetails from '../../../HOC/withCorePlanDetails';
import CopyableField from '../CopyableField';
import PharmacyBenefitManagerInfo, {validateOnSubmit as validatePharmacyBenefitManagerOnSubmit} from '../PharmacyBenefitManagerInfo';
import CoreCarrierInfo, {validateOnSubmit as validateCoreCarrierOnSubmit} from '../CoreCarrierInfo';
import {equal, trimStart, validateRequired} from '../../../utils';
import {FIN_ACCOUNT_TYPES} from '../../../constants';

const formularySearchUrlTooltipContent = 'Please enter the link to this plan’s formulary, such as a link from the PBM’s website.';
const formularySearchUrlAliasTooltipContent = 'This is how the link will appear on the wallet card.';

/* istanbul ignore next */
const validateOnSubmit = values => {
    const {is_financial_account: isFinAccount, financial_account_type: finAccountType} = values;
    const isHsaFinAccount = equal(finAccountType, FIN_ACCOUNT_TYPES.hsa);

    return ({
        ...validateCoreCarrierOnSubmit(values),
        ...validatePharmacyBenefitManagerOnSubmit(values),
        ...validateMedicalPlanBasicInfoOnSubmit(values),
        ...validateProviderNetworksOnSubmit(values),
        name: validateRequired(values.name),
        type: validateRequired(values.type),
        sbc_url: validateRequired(values.sbc_url),
        group_number: validateRequired(values.group_number),
        carrier_phone: validateRequired(values.carrier_phone),
        drug_list_name: validateRequired(values.drug_list_name),
        formulary_url: validateRequired(values.formulary_url),
        financial_account_type: isFinAccount ? validateRequired(values.financial_account_type) : undefined,
        hsa_telemed_consult_fee: isHsaFinAccount ? validateRequired(values.hsa_telemed_consult_fee?.toString()) : undefined
    });
};

/* istanbul ignore next */
const validate = values => {
    return ({
        ...validateMedicalPlanBasicInfo(values),
        name: validateRequired(values.name)
    });
};

const MedicalPlanForm = ({onChangeValidatableFile}) => {
    return (
        <div className='plan-details-form medical-plan-form'>
            <CoreCarrierInfo/>

            <Separator className='plan-details-form__separator plan-details-form__separator_dashed'/>

            <MedicalPlanBasicInfo onChangeValidatableFile={onChangeValidatableFile}/>

            <Row className='plan-details-form-row plan-details-form-row_bottom'>
                <Column className='plan-details-form-row__column' sm={6}>
                    <ProviderNetworks/>
                </Column>

                <Column className='plan-details-form-row__column' sm={6}>
                    <PharmacyBenefitManagerInfo/>

                    <CopyableField name='drug_list_name' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' className='plan-details-form-label' label='Drug List Name' isRequired wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='rx_pcn' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' className='plan-details-form-label' label='Rx PCN' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='rx_group' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' className='plan-details-form-label' label='Rx Group Number' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='rx_bin' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' className='plan-details-form-label' wrapperClassName='mb-12' label='Rx BIN'/>}
                    </CopyableField>

                    <CopyableField name='formulary_url' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' className='plan-details-form-label' label={<LabeledTooltip title='Formulary Search URL' content={formularySearchUrlTooltipContent}/>} isRequired wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='formulary_url_alias' parse={trimStart}>
                        {props => <Input {...props} placeholder='e.g. Prescription Coverage List' maxLength={24} className='plan-details-form-label' label={<LabeledTooltip title='Formulary Search URL Alias' content={formularySearchUrlAliasTooltipContent}/>}/>}
                    </CopyableField>
                </Column>
            </Row>
        </div>
    );
};

MedicalPlanForm.propTypes = {
    onChangeValidatableFile: PropTypes.func
};

export {MedicalPlanForm as TestableMedicalPlanForm};
export default withCorePlanDetails({validate, validateOnSubmit})(MedicalPlanForm);
