import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {trimStart} from '../../../utils';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'main_paragraph', label: 'Main Paragraph'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const HraForm = ({card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isFinAccount/>

            <CardHeaderInfo/>

            <CopyableField name='main_paragraph' parse={trimStart}>
                {props => <Textarea {...props} placeholder='Please enter...' label='Main Paragraph'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

HraForm.propTypes = {
    card: PropTypes.string
};

export {HraForm as TestableHraForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(HraForm);
