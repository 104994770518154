import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {Field} from '../FormComponents';
import CustomizablePopup from '../CustomizablePopup';
import MissingFieldsPopup from '../MissingFieldsPopup';
import withPopup from '../../../HOC/withPopup';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {goNextInternalMenuItem, goPrevInternalMenuItem, markInternalMenuItemAsDone, removeInternalMenuItem} from '../../../actions/internalMenu';
import {getItemKeyValue} from '../../../utils';
import './index.scss';

const POPUP_ID = 'detailsFormActionBarPopup';

const DetailsActionBar = ({onSubmit, onSuccess, recommendedFields, openPopup, closePopup, onDelete}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const {values} = useFormState();
    const {completed, id, category} = values;
    const internalMenuItemId = id ?? category;

    const handleSubmitStep = async event => {
        const {completed: isMetaCompleted} = form.getState().values.meta || {};
        await onSubmit(event);

        // FYI: it's important to get fresh getState object right after submit
        // to see is form valid/invalid  (Yuri, 11.12.2023)
        if (form.getState().invalid || !isMetaCompleted) {
            return;
        }

        const {items} = dispatch(markInternalMenuItemAsDone(internalMenuItemId));
        const areItemsCompleted = items.every(getItemKeyValue('completed'));

        return areItemsCompleted ? onSuccess() : dispatch(goNextInternalMenuItem());
    };

    const markAsDone = async event => {
        form.change('meta.completed', true);
        handleSubmitStep(event);
    };
    const saveProgress = event => {
        form.change('meta.completed', false);
        handleSubmitStep(event);
    };

    const onOpenMissingFieldsPopup = (event, missingFieldLabels) => {
        const onMarkAsDone = () => {
            closePopup();
            markAsDone(event);
        };
        const popupProps = {missingFieldLabels, onClose: closePopup, onMarkAsDone};
        const children = <MissingFieldsPopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenDeletionPopup = () => {
        const onDeleteItem = async () => {
            form.restart();

            // FYI: after onDelete we need go back for one step in InternalMenu (business requirement). goPrevItem is called only after promise, because isDirtyForm prop (withDashboardLayout HOC) has a little delay after destroyForm. Without delay onWarnAboutUnsavedForm is shown. (12.11.20, Pasha)
            const {isSuccess} = await onDelete(values);

            if (isSuccess) {
                dispatch(goPrevInternalMenuItem()); // FYI: important rule - use goPrevItem before update internal menu items because we can`t find removing activeItem and set new activeItem correctly (Pasha, 21.12.2020)
                dispatch(removeInternalMenuItem(internalMenuItemId));
            }

            closePopup();
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDeleteItem} data-testid='popup-delete-button'>Delete</Button>
            </React.Fragment>
        );
        const popupProps = {title: 'Delete', content: 'Are you sure you want to delete this?', actionBar};
        const children = <CustomizablePopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onMarkAsDone = event => {
        const missingFieldLabels = recommendedFields.filter(({name}) => !values[name]).map(getItemKeyValue('label'));

        return missingFieldLabels.length ? onOpenMissingFieldsPopup(event, missingFieldLabels) : markAsDone(event);
    };

    return (
        <div className='details-form-action-bar'>
            <Field name='meta.completed'>{props => <Input {...props} type='hidden'/>}</Field>

            <Separator className='details-form-action-bar__separator' type='solid'/>

            <div className='action-bar'>
                {onDelete && <Button className='action-bar__button' data-testid='delete-button' type={BUTTON_TYPES.destructive} onClick={onOpenDeletionPopup}>Delete</Button>}

                <div className='success-actions'>
                    <Button className='action-bar__button' data-testid='save-button' type={BUTTON_TYPES.secondary} onClick={saveProgress}>Save progress</Button>
                    <Button className='action-bar__button' data-testid='done-button' onClick={onMarkAsDone} type={BUTTON_TYPES.primary}>
                        {completed && <React.Fragment><Icon type={ICON_TYPES.confirm}/> Marked as Done</React.Fragment>}
                        {!completed && 'Save & Mark as Done'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

DetailsActionBar.propTypes = {
    recommendedFields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    })),
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onSuccess: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

DetailsActionBar.defaultProps = {
    recommendedFields: []
};

export {DetailsActionBar as TestableDetailsActionBar};
export default withPopup(POPUP_ID)(DetailsActionBar);
