import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Tweak from './Tweak';
import {FieldArray, Form} from '../../shared/FormComponents';
import {redirectToBack, requestCompaniesByTPAPartner, requestFeatureFlag} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {
    equal,
    generateUniqueId,
    getEqual,
    getItemKeyValue,
    getRegisteredFieldsValues,
    isEmpty,
    isString,
    parseQuery,
    promisifyAsyncFunction,
    validateJSON,
    validateJSONObject
} from '../../../utils';
import {FEATURE_FLAGS, FORMS, IMPORT_CONFIG_TYPES, TPA_GROUPS_FIELD_TYPES} from '../../../constants';
import {
    requestImportsJsonrpc,
    requestTPAPartnerConfig,
    requestTPAPartnerImportSessions
} from '../../../actions/adminPortal';
import {getParsedImportConfigData, getStringifiedImportConfigData} from '../../../helpers';
import apiServices from '../../../apiServices';
import './index.scss';

const ADDITIONAL_FIELDS = {
    inherit_member_ids_for_dependents: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Inherit Member IDs for Dependents'},
    inherit_core_plans: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Inherit Core Plans'},
    inherit_ancillary_plans: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Inherit Ancillary Plans'},
    invite_relatives: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Invite Relatives'},
    inherit_core_condition: {type: TPA_GROUPS_FIELD_TYPES.jsonInput, name: 'Inherit Core Condition'},
    ignore_fields: {type: TPA_GROUPS_FIELD_TYPES.jsonInput, name: 'Ignore Fields'},
    custom_fields: {type: TPA_GROUPS_FIELD_TYPES.multiSelectInput, name: 'Custom Fields'},
    ignore_termination_date_condition: {type: TPA_GROUPS_FIELD_TYPES.jsonInput, name: 'Ignore Termination Date Condition'},
    delete_plans_if_empty: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Delete Plans if Empty'},
    delete_ancillary_plans_if_empty: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Delete Ancillary Plans if Empty'},
    terminate_if_not_in_file: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'Terminate if Not in File'},
    cobra_in_separate_file: {type: TPA_GROUPS_FIELD_TYPES.checkbox, name: 'COBRA in Separate File'},
    cobra_condition: {type: TPA_GROUPS_FIELD_TYPES.jsonInput, name: 'COBRA Condition'},
    cobra_file_pattern: {type: TPA_GROUPS_FIELD_TYPES.input, name: 'Cobra Manual File Pattern'}
};

const TWEAK_FIELDS_WITH_DEFAULT_VALUE = ['allowable_condition', 'not_allowable_condition', 'allowable_condition_or', 'not_empty_fields', 'not_empty_fields_or', 'empty_fields', 'empty_fields_or', 'ignore_members'];

const baseJsonFields = ['allowable_condition', 'not_allowable_condition', 'allowable_condition_or', 'ignore_members'];

const JSON_OBJECT_FIELDS = ['ignore_members', 'ignore_fields', 'cobra_condition'];

const jsonFields = Object.keys(ADDITIONAL_FIELDS)
    .map(field => field)
    .filter(field => equal(ADDITIONAL_FIELDS[field].type, TPA_GROUPS_FIELD_TYPES.jsonInput))
    .concat(baseJsonFields);

const IMPORT_COMPLETE_STATUSES = ['failed', 'success', 'cancelled'];

const TWEAKS_NAME = 'import_configs_tweaks';

/* istanbul ignore next */
const validate = values => {
    const getGroupValidation = group => ({
        ...jsonFields.reduce((acc, field) => {
            const validateFunc = JSON_OBJECT_FIELDS.some(getEqual(field)) ? validateJSONObject : validateJSON;

            return ({
                ...acc,
                [field]: validateFunc(group?.[field])
            });
        }, {})
    });

    return {
        import_configs_tweaks: values?.import_configs_tweaks?.map(getGroupValidation)
    };
};

const getFormattedSelectedGroups = search => {
    const selectedGroupsSearch = parseQuery(search).groups || [];
    return isString(selectedGroupsSearch) ? [selectedGroupsSearch] : selectedGroupsSearch;
};

const ConfigureTPAGroups = () => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const {search} = useLocation();
    const {partner} = useParams();
    const selectedGroups = getFormattedSelectedGroups(search);
    const [initialValues, setInitialValues] = useState({});
    const [partnerConfig, setPartnerConfig] = useState({});
    const [groups, setGroups] = useState([]);
    const [importSession, setImportSession] = useState({});
    const [filterBy, setFilterBy] = useState({[IMPORT_CONFIG_TYPES.launch]: true, [IMPORT_CONFIG_TYPES.maintenance]: true});
    const [isConfigVersionsHistory, setIsConfigVersionsHistory] = useState(false);

    const getGroupIsPending = (company_id, importConfig = importSession) => {
        const {groups_individual_import_sessions: groupsIndividualImportSessions = []} = importConfig || {};
        return groupsIndividualImportSessions.some(group => {
            return equal(group?.company_data?.id, company_id) && !IMPORT_COMPLETE_STATUSES.includes(group.status);
        });
    };

    const getTweakDefaultValues = config => {
        return TWEAK_FIELDS_WITH_DEFAULT_VALUE.reduce((acc, value) => ({...acc, ...config[value] ? {[value]: config[value]} : {}}), {});
    };

    const onSearchChange = fields => alias => {
        const {id} = groups.find(group => equal(group.alias, alias));
        const isPending = getGroupIsPending(id);
        const groupTweaks = importSession.import_configs_tweaks.find(tweakedGroup => equal(tweakedGroup.company_id, id)) || {};

        fields.push({
            ...isEmpty(groupTweaks) ? getTweakDefaultValues(partnerConfig) : groupTweaks,
            alias,
            isPending,
            company_id: id
        });
    };

    const runTPAImport = async ({import_configs_tweaks: importConfigsTweaks = []}) => {
        const groupIdsToImport = importConfigsTweaks.map(getItemKeyValue('company_id'));
        const jsonrpcObj = {jsonrpc: '2.0', method: 'run_tpa_import', id: generateUniqueId(), params: {tpa_import_session_id: importSession.id, group_ids_to_import: groupIdsToImport}};
        const {jsonrpc, isSuccess} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        return {
            jsonrpc,
            isSuccess: equal(jsonrpc?.result, 'ok') && isSuccess
        };
    };

    const parseTweak = value => {
        const registeredJsonFields = jsonFields.filter(jsonField => Object.keys(value).includes(jsonField));

        return getParsedImportConfigData(registeredJsonFields, {import_config: value}).import_config;
    };

    const stringifyTweak = tweak => {
        const registeredJsonFields = jsonFields.filter(jsonField => Object.keys(tweak).includes(jsonField));

        return getStringifiedImportConfigData(registeredJsonFields, {import_config: tweak}).import_config;
    };

    const getEnhancedTweaks = tweaks => {
        const {import_configs_tweaks: importConfigsTweaks} = importSession || {};
        const filteredTweaks = tweaks.map(tweak => {
            const index = importConfigsTweaks.findIndex(getEqual(tweak.company_id, 'company_id'));

            if (equal(index, -1)) {
                return tweak;
            }

            importConfigsTweaks[index] = tweak;
            return null;
        });
        return [...filteredTweaks.filter(Boolean), ...importConfigsTweaks].map(parseTweak);
    };

    const onSubmit = async ({is_submit: isSubmit, allGroupsInFile, ...values}, {getRegisteredFields}) => {
        const registeredFields = getRegisteredFields().filter(field => !equal(field, TWEAKS_NAME));
        const registeredFieldsValues = getRegisteredFieldsValues(registeredFields, values);

        const {data, isSuccess} = await apiServices.updateTPAPartnerImportSession({session: {import_configs_tweaks: getEnhancedTweaks(registeredFieldsValues.import_configs_tweaks)}, sessionId: importSession.id});
        const stringifiedTweaks = data.import_configs_tweaks.map(stringifyTweak);

        if (isSuccess) {
            const initialValues = registeredFieldsValues.import_configs_tweaks.map(groupTweak => {
                const isPending = getGroupIsPending(groupTweak.company_id);
                const {alias} = groups.find(group => equal(group.id, groupTweak.company_id));

                return {
                    ...groupTweak,
                    alias,
                    isPending
                };
            });

            setInitialValues({import_configs_tweaks: initialValues, allGroupsInFile});
            setImportSession({...data, import_configs_tweaks: stringifiedTweaks});
        }

        if (isSubmit) {
            await runTPAImport(values);
            dispatch(redirectToBack());
        }
    };

    const getTweak = (field, index) => {
        const tweakProps = {
            tweaksName: TWEAKS_NAME,
            allAdditionalFields: ADDITIONAL_FIELDS,
            partnerConfig,
            groups,
            index,
            filterBy
        };

        return <Tweak {...tweakProps}/>;
    };

    const getBaseImportConfig = async () => {
        const {data} = await dispatch(requestTPAPartnerConfig({tpaPartnerId: partner}));
        const configData = data?.[0] || {import_config: {}};
        const {id, ...stringifiedImportConfig} = getStringifiedImportConfigData(jsonFields, configData).import_config;

        return stringifiedImportConfig;
    };

    const getGroups = async () => {
        const {groupsData: {data: groups}} = await dispatch(requestCompaniesByTPAPartner({partnerId: partner, onlyNewLaunch: true, limit: 100}));

        return groups;
    };

    const getImportSession = async () => {
        const {sessions} = await dispatch(requestTPAPartnerImportSessions({limit: 1, tpa_partner_id: partner, only_active: false}));
        const data = sessions.data?.[0] || {};
        const stringifiedTweaks = data.import_configs_tweaks.map(stringifyTweak);

        return {
            ...data,
            import_configs_tweaks: stringifiedTweaks
        };
    };

    useEffect(() => {
        (async () => {
            const [
                groups,
                stringifiedImportConfig,
                stringifiedSessionData,
                {flag: isConfigVersionsHistory}
            ] = await Promise.all([
                getGroups(),
                getBaseImportConfig(),
                getImportSession(),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.configVersionsHistory, {userEmail}))
            ]);
            const {all_groups_in_file: allGroupsInFile} = stringifiedSessionData?.preprocessing_report || {};

            const initialValues = selectedGroups.map(alias => {
                const {id} = groups?.find(group => equal(group.alias, alias)) || {};
                const isPending = getGroupIsPending(id, stringifiedSessionData);
                const groupTweaks = stringifiedSessionData.import_configs_tweaks.find(tweakedGroup => equal(tweakedGroup.company_id, id)) || {};

                return {
                    ...isEmpty(groupTweaks) ? getTweakDefaultValues(stringifiedImportConfig) : groupTweaks,
                    alias,
                    isPending,
                    company_id: id
                };
            });

            setGroups(groups);
            setImportSession(stringifiedSessionData);
            setPartnerConfig(stringifiedImportConfig);
            setInitialValues({import_configs_tweaks: initialValues, allGroupsInFile});
            setIsConfigVersionsHistory(isConfigVersionsHistory);
        })();
    }, [userEmail]);

    const loadCompanyAliasOptions = promisifyAsyncFunction(async (query, form) => {
        const {values} = form.getState();
        const {groupsData: {data}} = await dispatch(requestCompaniesByTPAPartner({alias: query, partnerId: partner, onlyNewLaunch: true, limit: 20}));
        const aliases = values.import_configs_tweaks.map(({alias}) => alias);
        const filteredGroups = data
            .filter(({alias}) => values.allGroupsInFile.includes(alias))
            .filter(({alias}) => !aliases.includes(alias));

        return filteredGroups.map(({alias, title}) => ({label: title, value: alias}));
    });

    const onFilterChange = type => setFilterBy(state => ({...state, [type]: !state[type]}));

    return (
        <div className='configure-tpa-groups'>
            {isConfigVersionsHistory && <Alert className='mb-20' type={ALERT_TYPES.warning} description='Renewals are not supported in the Admin Portal at this time. Please see Houston to configure and upload other remaining groups'/>}

            <Heading className='mb-10' type={HEADING_TYPES['1']}>Configure TPA Groups</Heading>
            <Text className='mt-5'>Search and add specific groups from within this TPA to apply specific configurations where values may differ from the rest of the TPA partner groups. To add a group, search and select the group alias.</Text>

            <div className='mt-20 filters'>
                <Heading type={HEADING_TYPES['5']}>Filter by</Heading>
                <Sticker onClick={() => onFilterChange(IMPORT_CONFIG_TYPES.launch)} type={filterBy[IMPORT_CONFIG_TYPES.launch] ? STICKER_TYPES.primary : STICKER_TYPES.default} className='ml-8 filters__sticker'>🚀 Launch</Sticker>
                <Sticker onClick={() => onFilterChange(IMPORT_CONFIG_TYPES.maintenance)} type={filterBy[IMPORT_CONFIG_TYPES.maintenance] ? STICKER_TYPES.primary : STICKER_TYPES.default} className='ml-8 filters__sticker'>🛠️ Maintenance</Sticker>
            </div>

            <Form name={FORMS.configureTPAGroups} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, form, values}) => {
                    const tweakAliases = values.import_configs_tweaks?.map(getItemKeyValue('alias')).sort() || [];
                    const isSearchAvailable = !values.allGroupsInFile?.sort().every((alias, index) => equal(alias, tweakAliases[index]));

                    const onSubmitClicked = () => {
                        form.change('is_submit', true);
                        handleSubmit();
                    };

                    const onSaveClicked = () => {
                        form.change('is_submit', false);
                        handleSubmit();
                    };

                    return (
                        <form noValidate data-testid='configure-tpa-groups'>
                            <FieldArray name={TWEAKS_NAME}>
                                {({fields}) => (
                                    <React.Fragment>
                                        {fields.map(getTweak)}

                                        {isSearchAvailable && (
                                            <AsyncAutocomplete className='mt-20'
                                                onChange={onSearchChange(fields)}
                                                loadOptions={query => loadCompanyAliasOptions(query, form)}
                                                filterConfig={{stringify: option => `${option.label} ${option.value}`}}
                                                isCreatable={false}/>
                                        )}

                                        <div className='configure-tpa-groups__action-bar mt-20'>
                                            <Button data-testid='save-tweaks-button' className='configure-tpa-groups__action-bar__button' onClick={onSaveClicked} type={BUTTON_TYPES.secondary}>Save Progress</Button>
                                            <Button data-testid='submit-tweaks-button' className='configure-tpa-groups__action-bar__button' onClick={onSubmitClicked} disabled={!fields.length}>Submit</Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </FieldArray>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export {ConfigureTPAGroups as TestableConfigureTPAGroups};
export default React.memo(ConfigureTPAGroups);
