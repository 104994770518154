import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import ContactInfoBasicForm from '../ContactInfoBasicForm';
import NewContactInfoPopup from '../NewContactInfoPopup';
import withPopup from '../../../HOC/withPopup';
import {redirectTo} from '../../../actions/general';
import {requestContactAdding, requestContactInvitation} from '../../../actions/contacts';
import {getActiveCompanyName} from '../../../selectors/general';
import {ROUTES, FORMS, CONTACT_STATUSES} from '../../../constants';

const POPUP_ID = 'newContactInfoPopup';

const ACTIVATED_STATUSES = [CONTACT_STATUSES.existed, CONTACT_STATUSES.added];
const INACTIVATED_STATUSES = [CONTACT_STATUSES.existedRemindActivation, CONTACT_STATUSES.addedRemindActivation];

const NewContactInfoForm = ({type, openPopup}) => {
    const dispatch = useDispatch();
    const companyName = useSelector(getActiveCompanyName);
    const [initialValues, setInitialValues] = useState({});

    const goToBoard = () => dispatch(redirectTo(ROUTES.contacts));

    const getStatus = ({isCreated, isActive, hadCompany}) => {
        const statuses = isActive ? ACTIVATED_STATUSES : INACTIVATED_STATUSES;
        const statusIndex = Number(hadCompany);

        return isCreated ? CONTACT_STATUSES.created : statuses[statusIndex];
    };

    const invite = async id => {
        const {isSuccess} = await dispatch(requestContactInvitation(id));

        if (!isSuccess) {
            return false;
        }

        goToBoard();
    };

    const openSuccessPopup = ({id, status, first_name: firstName}) => {
        const onAct = () => INACTIVATED_STATUSES.includes(status) ? invite(id) : goToBoard();
        const popupProps = {status, firstName, companyName, onAct};
        const children = <NewContactInfoPopup {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const onSubmit = async values => {
        const {data, isSuccess, submissionErrors} = await dispatch(requestContactAdding({...values, user_type: type}));

        if (!isSuccess) {
            return submissionErrors;
        }

        const {user, is_created: isCreated, already_active: isActive, already_had_company: hadCompany} = data;
        const status = getStatus({isCreated, isActive, hadCompany});

        setInitialValues(data.user);
        openSuccessPopup({status, ...user});
    };

    const basicFormProps = {type, initialValues, formName: FORMS.newContactInfo, onSubmit};

    return <ContactInfoBasicForm {...basicFormProps}/>;
};

NewContactInfoForm.propTypes = {
    type: PropTypes.string.isRequired,
    openPopup: PropTypes.func
};

export {NewContactInfoForm as TestableNewContactInfoForm};
export default withPopup(POPUP_ID)(NewContactInfoForm);
