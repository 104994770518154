import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Table, {ROW_HIGHLIGHT_TYPES} from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import MultipleChoice from '../MultipleChoice';
import {requestImportsJsonrpc, clearMultipleChoice, requestPendingTerminations} from '../../../actions/adminPortal';
import {redirectTo, requestCompanies} from '../../../actions/general';
import {formatDate, generateUniqueId, promisifyAsyncFunction} from '../../../utils';
import {ROUTES} from '../../../constants';
import './index.scss';

const TABLE_PAGE_SIZE = 20;

const PendingTerminations = () => {
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0});
    const [tableData, setTableData] = useState([]);
    const rowProps = useMemo(() => {
        return tableData.reduce((acc, {pending_termination_visited: isVisited, company_alias: alias}) => {
            return {...acc, ...!isVisited ? {[alias]: {highlightType: ROW_HIGHLIGHT_TYPES.success}} : {}};
        }, {});
    }, [tableData]);
    const [search, setSearch] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearMultipleChoice());
        };
    }, []);

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE, ...search}) => {
        const query = {
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...search
        };
        const {pendingTerminations, count, isSuccess} = await dispatch(requestPendingTerminations(query));

        if (!isSuccess) {
            return false;
        }

        setTable({unitCount: count, pageIndex});
        setTableData(pendingTerminations);
    }, []);

    const onFetchTableData = useCallback(tableData => {
        onFetchData({...tableData, ...search});
    }, [search]);

    const loadCompanyOptions = promisifyAsyncFunction(async (query, value) => {
        const {companies} = await dispatch(requestCompanies(query));

        return companies.map(company => ({label: company[value], value: company[value]}));
    });

    const terminationsFilteringChoices = [
        {name: 'Company', type: 'company_name', loadOptions: query => loadCompanyOptions(query, 'name')},
        {name: 'Alias', type: 'company_alias', loadOptions: query => loadCompanyOptions(query, 'alias')}
    ];

    const getTableColumns = () => {
        const getDate = ({value: date}) => date && formatDate(date, 'MM/dd/yyyy');

        const getAction = ({cell}) => {
            const {company_alias: companyAlias, company_name: companyName, pending_termination_visited: isGroupVisited} = cell?.row?.original || {};
            const onGoToPendingTerminations = () => {
                if (!isGroupVisited) {
                    const jsonrpcObj = {jsonrpc: '2.0', method: 'mark_pending_termination_visited', id: generateUniqueId(), params: {alias: companyAlias, value: true}};
                    dispatch(requestImportsJsonrpc(jsonrpcObj));
                }

                dispatch(redirectTo(`${ROUTES.importsIndividual}/${companyAlias}/pending_terminations?group_name=${encodeURIComponent(companyName)}`));
            };

            return <Button data-testid='action-button' onClick={onGoToPendingTerminations} type={BUTTON_TYPES.tertiary}>View</Button>;
        };

        const getCompany = ({value, cell}) => {
            return <div>{!cell?.row?.original?.pending_termination_visited && <Icon type={ICON_TYPES.circle} className='pending-termination-not-visited mr-2'/>} {value}</div>;
        };

        return [
            {Header: 'Date', accessor: 'pending_termination_last_change_date', Cell: getDate},
            {Header: 'Company', accessor: 'company_name', Cell: getCompany},
            {Header: 'Alias', accessor: 'company_alias'},
            {Header: '# of Members', accessor: 'pending_termination_members_count', isSortable: false, width: 170},
            {Header: 'Notes', accessor: 'pending_termination_notes.message'},
            {Header: 'Action', isSortable: false, Cell: getAction}
        ];
    };

    const tableProps = {
        data: tableData,
        pageSize: TABLE_PAGE_SIZE,
        isSortable: true,
        isFilterable: false,
        columns: getTableColumns(),
        filterPlaceholder: 'Search by name, email, or employee ID',
        className: 'mt-20',
        onFetchData: onFetchTableData,
        uniqueDataIdentifier: 'company_alias',
        rowProps,
        ...table
    };

    return (
        <React.Fragment>
            <MultipleChoice choices={terminationsFilteringChoices} onChange={setSearch}/>

            <Table {...tableProps}/>
        </React.Fragment>
    );
};

export {PendingTerminations as TestablePendingTerminations};
export default React.memo(PendingTerminations);
