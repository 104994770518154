import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import Link from '@frontend/ui-kit/Components/Link';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import EmptyData from '../../../shared/EmptyData';
import {requestEligibilityImportSessionErrors} from '../../../../actions/adminPortal';
import {isEmpty} from '../../../../utils';

const FileErrors = ({import_info: importInfo = {}}) => {
    const [{blockers = [], action_required: actionRequired = [], informational = []}, setFileErrors] = useState({});
    const {id: importId} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const {data, isSuccess} = await dispatch(requestEligibilityImportSessionErrors(importId));

            if (isSuccess) {
                setFileErrors(data);
            }
        })();
    }, [importId]);

    const getTableColumns = () => {
        const getActions = () => <Link data-testid='view-button' href={importInfo?.additional_data?.slack_logs_url} target='_blank'>View</Link>;

        return [
            {Header: 'Description', accessor: 'error_type'},
            {Header: 'Explanation', accessor: 'explanation'},
            {Header: '# of Members', accessor: 'ees_count', width: 100},
            {Header: 'Actions', width: 100, Cell: getActions}
        ];
    };

    const errorsTableProps = {
        isFilterable: false,
        isSortable: false,
        isCellTooltip: false,
        className: 'pb-10',
        columns: getTableColumns()
    };

    const getErrorsComponent = (data, title) => {
        return <Collapse isOpened className='mt-10' hasCollapseIcon initiator={<Heading type={HEADING_TYPES['4']}>{title} ({data.length})</Heading>} content={<Table {...errorsTableProps} data={data}/>}/>;
    };

    return (
        <div className='file-errors'>
            {isEmpty(blockers) && isEmpty(actionRequired) && isEmpty(informational) && <EmptyData title='Great job! There are no errors to review with this file.'/>}

            {!isEmpty(blockers) && (
                <React.Fragment>
                    <Alert type={ALERT_TYPES.danger} title='Blockers' description='The following errors need to be corrected in order to submit the file to database.'/>

                    {getErrorsComponent(blockers, 'Blockers')}
                </React.Fragment>
            )}

            {!isEmpty(actionRequired) && (
                <React.Fragment>
                    <Alert className='mt-20' type={ALERT_TYPES.warning} title='Action required' description='These errors require corrections to be made but will not be a blocker to file submission and committing to the database.'/>

                    {getErrorsComponent(actionRequired, 'Action Required')}
                </React.Fragment>
            )}

            {!isEmpty(informational) && getErrorsComponent(informational, 'Informational')}
        </div>
    );
};

FileErrors.propTypes = {
    import_info: PropTypes.shape({
        additional_data: PropTypes.shape({
            slack_logs_url: PropTypes.string
        })
    })
};

export {FileErrors as TestableFileErrors};
export default FileErrors;
