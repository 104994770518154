import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import EligibilityImportsSearch from '../EligibilityImportsSearch';
import ImportConfigVersions from '../ImportConfigVersions';
import CustomTerminationsThresholds from '../CustomTerminationsThresholds';
import {getProfileInfo} from '../../../selectors/general';
import {redirectTo, requestCompanyByAlias, requestFeatureFlag} from '../../../actions/general';
import {equal, parseQuery} from '../../../utils';
import {FEATURE_FLAGS, ROUTES} from '../../../constants';

const TPAGroupDetails = () => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const {partner, group} = useParams();
    const {search} = useLocation();
    const [company, setCompany] = useState({});
    const [isConfiguredOnce, setIsConfiguredOnce] = useState(false);
    const [isGroupModified, setIsGroupModified] = useState(false);
    const [isCanModifyGroup, setIsCanModifyGroup] = useState(false);
    const partnerDisplayName = parseQuery(search).partner_name || partner;
    const groupDisplayName = parseQuery(search).group_name || group;
    const [isConfigVersionsHistory, setIsConfigVersionsHistory] = useState(false);

    const onGoToModifyGroupConfig = () => dispatch(redirectTo(`${ROUTES.importsTpa}/${partner}/configure_tpa_groups?groups=${group}&partner_name=${encodeURIComponent(partnerDisplayName)}`));

    const setLastSessionData = (sessions = [], {offset}) => {
        if (!equal(offset, 0)) {
            return false;
        }

        const {import_configs_tweaks: importConfigsTweaks, preprocessing_report: preprocessingReport} = sessions[0] || {};
        const isModified = importConfigsTweaks?.some(({company_id}) => equal(company_id, company.id));

        setIsConfiguredOnce(!!sessions.length);
        setIsCanModifyGroup(!preprocessingReport?.global_errors?.length);
        setIsGroupModified(isModified);
    };

    useEffect(() => {
        (async () => {
            const [
                {data: company},
                {flag: isConfigVersionsHistory}
            ] = await Promise.all([
                dispatch(requestCompanyByAlias(group)),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.configVersionsHistory, {userEmail}))
            ]);

            setCompany(company);
            setIsConfigVersionsHistory(isConfigVersionsHistory);
        })();
    }, [dispatch, group, userEmail]);

    return (
        <div className='tpa-group-details'>
            {isConfigVersionsHistory && <Alert className='mb-20' type={ALERT_TYPES.warning} description='Renewals are not supported in the Admin Portal at this time. Please see Houston to configure and upload other remaining groups'/>}

            {!isConfiguredOnce && (
                <Alert className='mb-20' type={ALERT_TYPES.warning} description='This group is part of TPA that has not yet been launched. Please see the parent TPA page to complete the initial configuration fro this group'/>
            )}

            <Row>
                <Column sm>
                    <Heading type={HEADING_TYPES['3']} className='mb-5' data-testid='group-name'>{groupDisplayName}</Heading>
                    <Heading type={HEADING_TYPES['1']} className='mb-20' data-testid='partner-name'>{partnerDisplayName}</Heading>
                </Column>

                <Column className='pr-5'>
                    <ImportConfigVersions/>

                    {isConfiguredOnce && (
                        <Button className='ml-8' data-testid='config-button' disabled={!isCanModifyGroup} onClick={onGoToModifyGroupConfig}>{isGroupModified ? 'Continue Config' : 'Modify Group Config'}</Button>
                    )}
                </Column>
            </Row>

            <Row className='mb-20'>
                {company?.address && (
                    <Column sm={6}>
                        <ContentSection data-testid='company-address'>
                            <Text type={TEXT_TYPES.helper} className='mb-2'>Group Address</Text>
                            <Text>{company?.address}</Text>
                        </ContentSection>
                    </Column>
                )}
                <Column sm={6}>
                    <CustomTerminationsThresholds groupAlias={group}/>
                </Column>
            </Row>

            <ContentSection>
                <EligibilityImportsSearch setImportsData={setLastSessionData} groupAlias={group}/>
            </ContentSection>
        </div>
    );
};

export {TPAGroupDetails as TestableTPAGroupDetails};
export default React.memo(TPAGroupDetails);
