import React from 'react';
import PropTypes from 'prop-types';
import Wizard from '../../../Containers/shared/Wizard';
import PlanStep from '../CoreWizardSteps/PlanStep';
import MedicalTraitsStep from '../CoreWizardSteps/MedicalTraitsStep';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {CORE_PLAN_LABELS_BY_TYPE, CORE_PLAN_TYPES} from '../../../constants';

const WIZARD_STEPS = [
    {StepComponent: PlanStep, stepName: CORE_PLAN_TYPES.medical, stepTitle: CORE_PLAN_LABELS_BY_TYPE.medical},
    {StepComponent: PlanStep, stepName: CORE_PLAN_TYPES.dental, stepTitle: CORE_PLAN_LABELS_BY_TYPE.dental},
    {StepComponent: PlanStep, stepName: CORE_PLAN_TYPES.vision, stepTitle: CORE_PLAN_LABELS_BY_TYPE.vision},
    {StepComponent: MedicalTraitsStep, stepName: CORE_PLAN_TYPES.additional, stepTitle: CORE_PLAN_LABELS_BY_TYPE.additional}
];

const CoreWizard = ({onComplete}) => (
    <div className='benefits-core-form'>
        <Wizard steps={WIZARD_STEPS} onSuccess={onComplete}/>
    </div>
);

CoreWizard.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export {CoreWizard as TestableCoreWizard};
export default withSubStepCompletion(CoreWizard);
