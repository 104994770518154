import {SET_PDF_TEMPLATE_SUBJECT, RECEIVE_PDF_REPORT_INFO} from './actionTypes';
import {getAppId, getAppSecret} from '../selectors/authorization';
import {getPdfTemplateCompanyAlias, getPdfTemplateDataPath} from '../selectors/pdfTemplate';
import apiServices from '../apiServices';
import {getReportingMetricInfo, formatChartLabels} from '../utils';
import {PDF_TEMPLATE_TYPES} from '../constants';

const receivePdfReportInfo = (companyData, metrics) => ({type: RECEIVE_PDF_REPORT_INFO, companyData, metrics});

const formatMonthlyChartData = (chartData = {}, companyData = {}) => {
    const {datasets = [], labels = []} = chartData;
    const formattedLabels = formatChartLabels(labels, 'MMMM yyyy');
    const enhancedDataset = datasets.map(({id, ...dataset}) => ({...dataset, label: getReportingMetricInfo(id, companyData)?.label}));

    return {labels: formattedLabels, datasets: enhancedDataset};
};
const formatProportionChartData = (chartData = {}, companyData = {}) => {
    const {datasets = [], labels = []} = chartData;
    const formattedLabels = labels.map(id => getReportingMetricInfo(id, companyData)?.label);

    return {labels: formattedLabels, datasets};
};

const formatPerformanceReportMetrics = metrics => {
    const {
        employee_activation_chart: employeeActivationChart,
        member_utilization_chart: memberUtilizationChart,
        benefits_wallet_utilization_chart: benefitsWalletUtilizationChart,
        total_service_requests_chart: totalServiceRequestsChart,
        telemedicine_consults_chart: telemedicineConsultsChart,
        teladoc_health_utilization_chart: teladocHealthUtilizationChart,
        provider_recommendation_utilization_chart: providerRecommendationUtilizationChart,
        appointment_scheduling_utilization_chart: appointmentSchedulingUtilizationChart,
        facility_recommendation_utilization_chart: facilityRecommendationUtilizationChart,
        find_care_search_utilization_chart: findCareSearchUtilizationChart,
        rx_savings_utilization_chart: rxSavingsUtilizationChart,
        medical_bill_review_utilization_chart: medicalBillReviewUtilizationChart,
        benefits_support_utilization_chart: benefitsSupportUtilizationChart,
        total_savings_chart: totalSavingsChart,
        roi_chart: roiChart,
        productivity_savings_chart: productivitySavingsChart,
        precert_chart: preCertificationChart,
        precert_total_chart: preCertificationTotalChart,
        msk_enrollments_chart: mskEnrollmentsChart,
        teladoc_general_medical_consults: teladocGeneralMedicalConsultsCount,
        teladoc_mental_health_consults: teladocMentalHealthConsultsCount,
        teladoc_gen_med_annualized_telemed: teladocGeneralMedicalAnnualizedRate,
        teladoc_mental_health_annualized_telemed: teladocMentalHealthAnnualizedRate,
        precert_total_cases: preCertificationCasesCount,
        total_savings: totalSavings,
        bill_savings: medicalBillReviewSavings,
        rx_savings: rxSavings,
        telemed_savings: telemedSavings,
        teladoc_general_medical_savings: teladocGeneralMedicalSavings,
        teladoc_mental_health_savings: teladocMentalHealthSavings,
        msk_savings: mskSavings,
        precert_savings: preCertificationSavings,
        provider_facility_savings: providerFacilitySavings,
        roi_rate: roi,
        utilization_tickets_count: utilizationTicketsCount,
        current_activation_count_activated: activatedEmployeesCount,
        current_activation_count_eligible: eligibleEmployeesCount,
        activation_rating: activationRating,
        satisfaction_rating: memberSatisfaction,
        satisfaction_total_reviews: satisfactionTotalReviews
    } = metrics;

    return {
        summary: {
            activationChartData: formatMonthlyChartData(employeeActivationChart),
            totalSavings,
            roi,
            utilizationTicketsCount,
            activatedEmployeesCount,
            eligibleEmployeesCount,
            activationRating,
            memberSatisfaction,
            satisfactionTotalReviews
        },
        memberUtilization: {
            memberUtilizationChartData: formatMonthlyChartData(memberUtilizationChart),
            benefitsWalletUtilizationChartData: formatMonthlyChartData(benefitsWalletUtilizationChart),
            totalServiceRequestsChartData: formatMonthlyChartData(totalServiceRequestsChart)
        },
        serviceUtilization: {
            telemedUtilizationChartData: formatMonthlyChartData(telemedicineConsultsChart),
            teladocHealthUtilizationChartData: formatMonthlyChartData(teladocHealthUtilizationChart),
            providerRecommendationUtilizationChartData: formatMonthlyChartData(providerRecommendationUtilizationChart),
            appointmentUtilizationChartData: formatMonthlyChartData(appointmentSchedulingUtilizationChart),
            facilityRecommendationUtilizationChartData: formatMonthlyChartData(facilityRecommendationUtilizationChart),
            findCareSearchUtilizationChartData: formatMonthlyChartData(findCareSearchUtilizationChart),
            rxSavingsUtilizationChartData: formatMonthlyChartData(rxSavingsUtilizationChart),
            medicalBillReviewUtilizationChartData: formatMonthlyChartData(medicalBillReviewUtilizationChart),
            benefitsSupportUtilizationChartData: formatMonthlyChartData(benefitsSupportUtilizationChart),
            preCertificationChartData: formatMonthlyChartData(preCertificationChart),
            preCertificationTotalChartData: formatProportionChartData(preCertificationTotalChart),
            mskEnrollmentsChartData: formatMonthlyChartData(mskEnrollmentsChart),
            preCertificationCasesCount,
            preCertificationSavings,
            teladocGeneralMedicalConsultsCount,
            teladocMentalHealthConsultsCount,
            teladocGeneralMedicalAnnualizedRate,
            teladocMentalHealthAnnualizedRate
        },
        programSavings: {
            medicalBillReviewSavings,
            rxSavings,
            telemedSavings,
            teladocGeneralMedicalSavings,
            teladocMentalHealthSavings,
            mskSavings,
            preCertificationSavings,
            providerFacilitySavings,
            totalSavingsChartData: formatMonthlyChartData(totalSavingsChart),
            roiChartData: formatMonthlyChartData(roiChart),
            savingsByServiceChartData: formatMonthlyChartData(productivitySavingsChart)
        }
    };
};

const formatMskReportMetrics = metrics => {
    const {
        msk_applicants: mskApplicants,
        msk_enrollments: mskEnrollments,
        msk_active_members: mskActiveMembers,
        msk_pain_rate_improvement: mskPainImprovement,
        msk_grad_pain_rate_improvement: mskGraduatedPainImprovement,
        msk_function_rate_improvement: mskFunctionImprovement,
        msk_grad_function_rate_improvement: mskGraduatedFunctionImprovement,
        msk_savings: mskSavings,
        msk_surgery_likelihood_reduction: mskSurgeryLikelihoodReduction,
        msk_depression_anxiety_reduction: mskDepressionAnxietyReduction,
        msk_productivity_improvement: mskProductivityImprovement,
        msk_satisfaction_rating: mskSatisfactionRating,
        msk_enrollments_chart: mskEnrollmentsChart,
        msk_user_testimonials: mskTestimonials
    } = metrics;
    return {
        mskSummary: {
            mskApplicants,
            mskEnrollments,
            mskActiveMembers,
            mskPainImprovement,
            mskGraduatedPainImprovement,
            mskFunctionImprovement,
            mskGraduatedFunctionImprovement,
            mskSavings,
            mskSurgeryLikelihoodReduction,
            mskDepressionAnxietyReduction,
            mskProductivityImprovement,
            mskSatisfactionRating,
            mskEnrollmentsChartData: formatMonthlyChartData(mskEnrollmentsChart)
        },
        mskMemberTestimonials: {mskTestimonials}
    };
};

export const setPdfTemplateSubject = (companyAlias, dataPath) => ({type: SET_PDF_TEMPLATE_SUBJECT, companyAlias, dataPath});

export const requestPdfReportInfo = reportType => async (dispatch, getState) => {
    const state = getState();
    const [companyAlias, dataPath, appId, appSecret] = [
        getPdfTemplateCompanyAlias,
        getPdfTemplateDataPath,
        getAppId,
        getAppSecret
    ].map(selector => selector(state));

    const {data, isSuccess} = await apiServices.getPdfReportInfo({reportType, companyAlias, dataPath, appId, appSecret});

    if (!isSuccess) {
        return false;
    }

    const {company_data: companyData, metrics} = data;
    const formatPdfReportMetrics = {
        [PDF_TEMPLATE_TYPES.performanceReport]: formatPerformanceReportMetrics,
        [PDF_TEMPLATE_TYPES.mskReport]: formatMskReportMetrics
    }[reportType];
    const formattedMetrics = formatPdfReportMetrics(metrics);

    return dispatch(receivePdfReportInfo(companyData, formattedMetrics));
};
