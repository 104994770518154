import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import FileSource, {validateOnSubmit as validateFileSourceOnSubmit} from '../FileSource';
import withEligibilityDetailsForm from '../../../HOC/withEligibilityDetailsForm';
import useFormState from '../../../hooks/useFormState';
import {requestCorePlansInfo} from '../../../actions/eligibility';
import {getPlans} from '../../../selectors/eligibility';
import {getEqual, toCapitalize} from '../../../utils';
import {ROUTES, SIMPLE_ANSWERS} from '../../../constants';
import {SYSTEM_NAME_OPTIONS, UNIQUE_MEMBER_IDENTIFIER_OPTIONS} from '../../../options';

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateFileSourceOnSubmit(values)});

const DentalVisionIds = () => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const {values} = useFormState();
    const {dental: dentalPlans, vision: visionPlans} = useSelector(getPlans);
    const {label: uniqMemberKeyName} = UNIQUE_MEMBER_IDENTIFIER_OPTIONS.find(getEqual(values.unique_member_key, 'value')) || {};

    useEffect(() => {
        dispatch(requestCorePlansInfo(planPeriodId));
    }, [dispatch, planPeriodId]);

    const getPlan = ({name, source, plan_kind: planType}, index) => {
        const {has_unique_member_ids: isUniqueId, system_name} = source;
        const {label: systemName} = SYSTEM_NAME_OPTIONS.find(getEqual(system_name, 'value')) || {};

        return (
            <Row className='eligibility-details-form-row' key={index}>
                <Column sm={10} className='overview-selection-body eligibility-details-form-row__column'>
                    <Row className='eligibility-details-form-row'>
                        <Column sm={6} className='eligibility-details-form-row__column'>Plan name:</Column>
                        <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{name}</Column>
                    </Row>

                    <Row className='eligibility-details-form-row'>
                        <Column sm={6} className='eligibility-details-form-row__column'>Does this plan assign unique member ID's?</Column>
                        <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column' data-testid='unique-member-id'>
                            {isUniqueId ? SIMPLE_ANSWERS.yes : SIMPLE_ANSWERS.no}
                        </Column>
                    </Row>

                    {isUniqueId && (
                        <Row className='eligibility-details-form-row'>
                            <Column sm={6} className='eligibility-details-form-row__column'>Where will the {toCapitalize(planType)} ID's be coming from?</Column>
                            <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{systemName}</Column>
                        </Row>
                    )}
                </Column>
            </Row>
        );
    };

    return (
        <div className='eligibility-details-form dental-ids-file'>
            <Separator/>

            <Row className='eligibility-details-form-row'>
                <Column className='eligibility-details-form-row__column' sm>
                    <Row className='eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <FileSource/>
                        </Column>
                    </Row>

                    <Separator/>

                    <Row className='overview-selection eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={12}>
                            <Row className='overview-selection-heading eligibility-details-form-row'>
                                <Column className='eligibility-details-form-row__column' sm={10}>
                                    <Text className='overview-selection-heading__title'>Overview Selections</Text>
                                </Column>

                                <Column sm={2} className='edit-wrapper eligibility-details-form-row__column'>
                                    <Link className='edit-wrapper-link' href={`${ROUTES.eligibility}/${planPeriodId}/implementation`}>
                                        <Icon className='edit-wrapper-link__link' type={ICON_TYPES.edit}/>

                                        <Text className='edit-wrapper-link__title'>Edit</Text>
                                    </Link>
                                </Column>

                                <Column sm={10} className='overview-selection-heading__subtitle eligibility-details-form-row__column'>
                                    <Row className='eligibility-details-form-row'>
                                        <Column sm={10} className='overview-selection-body eligibility-details-form-row__column'>
                                            <Row className='eligibility-details-form-row'>
                                                <Column className='eligibility-details-form-row__column' sm={6}>Unique member identifier</Column>
                                                <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{uniqMemberKeyName}</Column>
                                            </Row>
                                        </Column>
                                    </Row>
                                </Column>

                                <Column className='overview-selection-heading__subtitle eligibility-details-form-row__column' sm={10}>Dental:</Column>

                                <Column className='eligibility-details-form-row__column' sm={10}>{dentalPlans.map(getPlan)}</Column>
                            </Row>

                            <Row className='eligibility-details-form-row'>
                                <Column className='eligibility-details-form-row__column' sm={10}><Separator type='solid'/></Column>

                                <Column className='eligibility-details-form-row__column' sm={10}>
                                    <Text className='overview-selection-heading__subtitle'>Vision:</Text>
                                </Column>

                                <Column className='eligibility-details-form-row__column' sm={10}>{visionPlans.map(getPlan)}</Column>
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </div>
    );
};

export {DentalVisionIds as TestableDentalVisionIds};
export default withEligibilityDetailsForm({validateOnSubmit})(DentalVisionIds);
