import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {goNextWizardStep, goPrevWizardStep} from '../../../actions/shared';
import useForm from '../../../hooks/useForm';
import {equal} from '../../../utils';
import './index.scss';

const AssignableActionBar = ({activeStep, isStepSkippable, stepsCount, onSuccess, onSubmitStep}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const goNextStep = useCallback(() => dispatch(goNextWizardStep()), [dispatch]);
    const goPrevStep = useCallback(() => dispatch(goPrevWizardStep()), [dispatch]);
    const onSkip = useCallback(() => {
        form.reset();

        return activeStep < stepsCount - 1 ? goNextStep() : onSuccess();
    }, [form, activeStep, stepsCount, goNextStep, onSuccess]);

    const handleSubmitStep = async event => {
        await onSubmitStep(event);

        if (form.getState().invalid) {
            return;
        }

        return activeStep < stepsCount - 1 ? goNextStep() : onSuccess();
    };

    const isFirstStep = equal(activeStep, 0);

    return (
        <div className='assignable-action-bar'>
            <Separator type='solid'/>

            <div className='action-bar-buttons'>
                {!isFirstStep && <Button data-testid='go-back' className='action-bar-buttons__button' onClick={goPrevStep} type={BUTTON_TYPES.secondary}>Go back</Button>}
                {isStepSkippable && <Button data-testid='skip' className='action-bar-buttons__button' onClick={onSkip} type={BUTTON_TYPES.secondary}>Skip</Button>}
                <Button data-testid='save-and-continue' className='action-bar-buttons__button' onClick={handleSubmitStep} type={BUTTON_TYPES.primary}>Save & continue</Button>
            </div>
        </div>
    );
};

AssignableActionBar.propTypes = {
    isStepSkippable: PropTypes.bool,
    stepsCount: PropTypes.number,
    activeStep: PropTypes.number,
    onSubmitStep: PropTypes.func.isRequired,
    onSuccess: PropTypes.func
};

AssignableActionBar.defaultProps = {
    isStepSkippable: true
};

export {AssignableActionBar as TestableAssignableActionBar};
export default AssignableActionBar;
