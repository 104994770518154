import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Form as SimpleForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import FormSpy from '../FormSpy';
import scrollToErrorDecorator from './scrollToErrorDecorator';
import {setDirtyForms} from '../../../../actions/shared';
import {getDirtyForms} from '../../../../selectors/shared';
import {equal, getEqual, negateFunc} from '../../../../utils';

const FORM_SPY_SUBSCRIPTION = {dirty: true, submitSucceeded: true};

const Form = ({name, children, mutators, decorators = [], onSubmitSuccess, isScrollToError = true, ...restProps}) => {
    const dispatch = useDispatch();
    const dirtyForms = useSelector(getDirtyForms);
    const formDirtinessRef = useRef(false);
    const dirtyFormsRef = useRef(dirtyForms);

    const updateDirtyForms = isDirty => {
        const updatedDirtyForms = isDirty ? [...dirtyFormsRef.current, name] : dirtyFormsRef.current.filter(negateFunc(getEqual(name)));

        dispatch(setDirtyForms(updatedDirtyForms));
    };

    const onChangeFormSpy = formState => ({dirty: isDirty, submitSucceeded: isSubmitSucceeded}) => {
        if (!equal(formDirtinessRef.current, isDirty)) {
            updateDirtyForms(isDirty);

            formDirtinessRef.current = isDirty;
        }

        if (isDirty || !isSubmitSucceeded || !onSubmitSuccess) {
            return false;
        }

        onSubmitSuccess(formState);
    };

    useEffect(() => () => updateDirtyForms(false), []);
    useEffect(() => {
        dirtyFormsRef.current = dirtyForms;
    }, [dirtyForms]);

    const enhancedDecorators = useMemo(() => {
        return [...decorators, isScrollToError ? scrollToErrorDecorator : null].filter(Boolean);
    }, [decorators, isScrollToError]);

    const formProps = {
        mutators: {...mutators, ...arrayMutators},
        decorators: enhancedDecorators,
        ...restProps
    };

    return (
        <SimpleForm {...formProps}>
            {props => (
                <React.Fragment>
                    <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onChangeFormSpy(props)}/>

                    {children(props)}
                </React.Fragment>
            )}
        </SimpleForm>
    );
};

Form.propTypes = {
    name: PropTypes.string.isRequired,
    isScrollToError: PropTypes.bool,
    children: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}),
    decorators: PropTypes.arrayOf(PropTypes.func),
    mutators: PropTypes.shape({}),
    validate: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func
};

export default React.memo(Form);
