import React, {useEffect, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Text from '@frontend/ui-kit/Components/Text';
import Badge from '@frontend/ui-kit/Components/Badge';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import Sticker from '@frontend/ui-kit/Components/Sticker';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import PlanCopyPopup from '../PlanCopyPopup';
import {Form, Field, FieldArray, FormSpy} from '../../shared/FormComponents';
import CarrierAutocomplete from '../../shared/CarrierAutocomplete';
import AssignableActionBar from '../../shared/AssignableActionBar';
import LabeledTooltip from '../../shared/LabeledTooltip';
import SuccessfulPlanCopyingAlert from '../SuccessfulPlanCopyingAlert';
import withCoreBenefitsStepLayout from '../../../HOC/withCoreBenefitsStepLayout';
import withPopup from '../../../HOC/withPopup';
import withPlanCopying from '../../../HOC/withPlanCopying';
import {requestCorePlansData, requestCorePlansSetting} from '../../../actions/benefits';
import {getCompaniesMap, getIsPlanAdditionAndDeletionAllowed} from '../../../selectors/general';
import {equal, compose, toCapitalize, validateRequired, generateUniqueId} from '../../../utils';
import {CORE_PLAN_TYPES, FORMS} from '../../../constants';
import {PLAN_TYPE_OPTIONS, MEDICAL_PLAN_TYPE_OPTIONS, CONDITION_OPTIONS} from '../../../options';
import './index.scss';

const POPUP_ID = 'planCopyPopup';

const MIN_COPY_PLAN_COUNT = 2;

/* istanbul ignore next */
const validate = planType => ({plans = []}) => {
    const isMedicalPlan = equal(planType, CORE_PLAN_TYPES.medical);

    return {
        plans: plans.map(plan => ({
            carrier_name: validateRequired(plan.carrier_name),
            name: validateRequired(plan.name),
            type: validateRequired(plan.type),
            deductible_embedded: isMedicalPlan ? validateRequired(plan.deductible_embedded, {isBooleanExpected: true}) : undefined
        }))
    };
};

const PlanStep = props => {
    const {
        stepName: planType,
        getCopiedPlanDetails,
        openPopup,
        closePopup,
        ...restProps
    } = props;
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const [copiedPlanName, setCopiedPlanName] = useState('');
    const [copiedPlansDetailsMap, setCopiedPlansDetailsMap] = useState({});
    const [isSuccessfullyCopiedAlert, setIsSuccessfullyCopiedAlert] = useState(false);
    const {planPeriodId} = useParams();
    const companiesMap = useSelector(getCompaniesMap);
    const isPlanAdditionAndDeletionAllowed = useSelector(state => getIsPlanAdditionAndDeletionAllowed(state, planPeriodId));
    const isMedicalPlan = useMemo(() => equal(planType, CORE_PLAN_TYPES.medical), [planType]);
    const isCopyPlan = useMemo(() => Object.keys(companiesMap).length >= MIN_COPY_PLAN_COUNT, [companiesMap]);

    useEffect(() => {
        (async () => {
            const {isPlan, plans} = await dispatch(requestCorePlansData(planPeriodId, planType));

            setInitialValues({is_plan: isPlan, plans});
        })();
    }, [dispatch, planType, planPeriodId]);

    const onSwitchPlan = useCallback(form => ({target}) => {
        const {checked: isPlan} = target;
        form.change('plans', isPlan ? [{}] : []);
    }, []);

    const copyPlan = useCallback(plans => (planDetails, isCopyingAcrossDifferentCompanies) => {
        const {name: copiedPlanName} = planDetails;
        const copiedPlanDetails = getCopiedPlanDetails(planDetails, isCopyingAcrossDifferentCompanies);
        const {
            carrier_name: carrierName,
            name: planName,
            app_name: appName,
            type: planType
        } = copiedPlanDetails;
        const cid = generateUniqueId(); // FYI: Copying can be applied several times, so we use cid to identify copied plans (Vlad, 06.10.2021)

        plans.push({isCopiedPlan: true, cid, carrier_name: carrierName, name: planName, app_name: appName, type: planType});
        setIsSuccessfullyCopiedAlert(true);
        setCopiedPlansDetailsMap(prevCopiedPlansDetailsMap => ({...prevCopiedPlansDetailsMap, [cid]: copiedPlanDetails}));
        setCopiedPlanName(copiedPlanName);
    }, [getCopiedPlanDetails]);

    const onOpenPlanCopyPopup = useCallback(plans => () => {
        const popupProps = {planType, isCorePlan: true, planPeriodId, onClose: closePopup, onSave: copyPlan(plans)};
        const children = <PlanCopyPopup {...popupProps}/>;

        setIsSuccessfullyCopiedAlert(false);
        openPopup({type: POPUP_TYPES.simple, children});
    }, [closePopup, copyPlan, openPopup, planPeriodId, planType]);

    const getPlans = ({fields}) => {
        const onAddPlan = () => fields.push({});

        return (
            <React.Fragment>
                {fields.map((plan, index) => {
                    const isRemovable = isPlanAdditionAndDeletionAllowed && fields.value.length > 1;
                    const onRemove = () => fields.remove(index);
                    const typeOptions = isMedicalPlan ? MEDICAL_PLAN_TYPE_OPTIONS : PLAN_TYPE_OPTIONS;

                    return (
                        <div key={plan} className='plan'>
                            <Row className='mb-16' between='sm' middle='sm'>
                                <Column sm>
                                    <Sticker className='mr-4'>{index + 1}</Sticker> <Text isInline>{toCapitalize(planType)} Plan</Text>
                                </Column>

                                <Column constant>
                                    {isRemovable && (
                                        <Button type={BUTTON_TYPES.tertiary} onClick={onRemove} data-testid='remove-plan-button'>
                                            <Icon type={ICON_TYPES.delete} className='mr-4'/> Remove Plan
                                        </Button>
                                    )}
                                </Column>
                            </Row>

                            <Row top='sm' rowGap='xlg'>
                                <Column sm={3}>
                                    <CarrierAutocomplete label='Carrier' isRequired isCore={isMedicalPlan} name={`${plan}.carrier_name`} data-testid={`carrier_name-${index}`}/>
                                </Column>

                                <Column sm={3}>
                                    <Field name={`${plan}.name`}>
                                        {props => <Input {...props} label='Plan Name' description='As indicated in the carrier contract or SPD' isRequired data-testid={`name-${index}`}/>}
                                    </Field>
                                </Column>

                                <Column sm={3}>
                                    <Field name={`${plan}.app_name`}>
                                        {props => <Input {...props} label='Plan Name Shown in the App' description='Leave blank if same as carrier plan name' data-testid={`app_name-${index}`}/>}
                                    </Field>
                                </Column>

                                <Column sm={3}>
                                    <Field name={`${plan}.type`}>
                                        {props => <Select {...props} label='Plan Type' isRequired options={typeOptions} data-testid={`type-${index}`}/>}
                                    </Field>
                                </Column>

                                {isMedicalPlan && (
                                    <Column sm={3}>
                                        <Field name={`${plan}.deductible_embedded`}>
                                            {props => <Select {...props} label='Does Embedded Deductible Apply?' isRequired options={CONDITION_OPTIONS} data-testid={`deductible_embedded-${index}`}/>}
                                        </Field>
                                    </Column>
                                )}
                            </Row>

                            <Separator type='dashed'/>
                        </div>
                    );
                })}

                {isSuccessfullyCopiedAlert && <SuccessfulPlanCopyingAlert className='benefits-plan-step__alert' copiedPlanName={copiedPlanName}/>}

                {isPlanAdditionAndDeletionAllowed && (
                    <div className='plans-action-bar' data-testid='plans-action-bar'>
                        <Button className='plans-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onAddPlan}>
                            <Icon className='mr-4' type={ICON_TYPES.circlePlus}/> Add {planType} plan
                        </Button>

                        {isCopyPlan && <Button className='plans-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onOpenPlanCopyPopup(fields)}>Copy plan from another company</Button>}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const handleOnFormChange = useCallback(() => setIsSuccessfullyCopiedAlert(false), []);

    const onSubmit = useCallback(async values => {
        const {isSuccess, submissionErrors} = await dispatch(requestCorePlansSetting(planPeriodId, planType, values.plans, copiedPlansDetailsMap));

        if (!isSuccess) {
            return {plans: submissionErrors};
        }

        setInitialValues(values);
    }, [copiedPlansDetailsMap, dispatch, planPeriodId, planType]);

    return (
        <Form name={FORMS.planStep} initialValues={initialValues} validate={validate(planType)} onSubmit={onSubmit}>
            {({handleSubmit, form, values}) => {
                const {is_plan: isPlan, plans} = values;
                const actionBarProps = {...restProps, onSubmitStep: handleSubmit};

                return (
                    <React.Fragment>
                        <FormSpy subscription={{values: true}} onChange={handleOnFormChange}/>

                        <form onSubmit={handleSubmit} className='benefits-wizard-step benefits-plan-step' noValidate>
                            <div className='plans-header'>
                                <Field name='is_plan' onChange={onSwitchPlan(form)}>
                                    {props => (
                                        <Switcher {...props}
                                            caption={<LabeledTooltip title={toCapitalize(planType)} content={!isPlanAdditionAndDeletionAllowed ? 'Please reach out to your HealthJoy contact to modify current plan period.' : null}/>}
                                            disabled={!isPlanAdditionAndDeletionAllowed}
                                            data-testid='plan-switcher'/>
                                    )}
                                </Field>

                                {isPlan && <Badge className='plans-header__counter'>{plans.length}</Badge>}
                            </div>

                            {isPlan && <FieldArray name='plans'>{getPlans}</FieldArray>}
                        </form>

                        <AssignableActionBar {...actionBarProps}/>
                    </React.Fragment>
                );
            }}
        </Form>
    );
};

PlanStep.propTypes = {
    stepName: PropTypes.string,
    getCopiedPlanDetails: PropTypes.func,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {PlanStep as TestablePlanStep};
export default compose(
    withCoreBenefitsStepLayout,
    withPopup(POPUP_ID),
    withPlanCopying
)(PlanStep);
