import React, {useEffect, useState} from 'react';
import Toast from '@frontend/ui-kit/Components/Toast';

const DEFAULT_VISIBILITY_TIME = 3000;

const withToast = Component => {
    return props => {
        const [content, setContent] = useState('');
        const [visibilityTime, setVisibilityTime] = useState(DEFAULT_VISIBILITY_TIME);
        const [isVisible, setIsVisible] = useState(false);

        useEffect(() => {
            if (isVisible && visibilityTime) {
                setTimeout(() => setIsVisible(false), visibilityTime);
            }
        }, [isVisible, visibilityTime, setIsVisible]);

        const showToast = ({content, visibilityTime = DEFAULT_VISIBILITY_TIME}) => {
            setContent(content);
            setVisibilityTime(visibilityTime);
            setIsVisible(true);
        };

        return (
            <React.Fragment>
                <Toast content={content} isVisible={isVisible}/>
                <Component {...props} showToast={showToast}/>
            </React.Fragment>
        );
    };
};

export {withToast as testableWithToast};
export default withToast;
