import React, {useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading from '@frontend/ui-kit/Components/Heading';
import Button from '@frontend/ui-kit/Components/Button';
import HjWebviewPreview from '@frontend/ui-kit/Components/HjWebviewPreview';
import Select from '@frontend/ui-kit/Components/Select';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import PreviewPlanInfoAlert from '../PreviewPlanInfoAlert';
import CardsReorderingPopup from '../CardsReorderingPopup';
import {Form, Field} from '../../shared/FormComponents';
import UnsupportedBrowserAlert from '../../shared/UnsupportedBrowserAlert';
import UnavailableEligibilityDataAlert from '../../shared/UnavailableEligibilityDataAlert';
import withPopup from '../../../HOC/withPopup';
import {requestAllWalletCardsPreviewParams, requestCorePlansList, requestEmployeePreviewParams, requestPlanPreviewParams} from '../../../actions/benefits';
import {requestCompanyEmployeeCount, requestCompanyEmployees} from '../../../actions/company';
import {getActiveCompany, getActiveCompanyPlanPeriodById, getActiveCompanyPlanPeriods, getActiveCompanyStageCriteria} from '../../../selectors/general';
import {getWebviewParams} from '../../../selectors/benefits';
import {getCompanyDetails} from '../../../selectors/company';
import {pass, equal, getEqual, isEmpty, negateFunc, promisifyAsyncFunction, stringifyQueryParams} from '../../../utils';
import {COMPANY_STAGES, FORMS, PLAN_PERIOD_TYPES, CORE_PLAN_TYPES, IS_MS_BROWSER, PREVIEW_CONTEXT} from '../../../constants';
import analyticsService from '../../../analyticsService';
import './index.scss';

const POPUP_ID = 'cardsReorderingPopup';

const PreviewLayout = ({openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const {benefits_url: benefitsUrl, wallet_url: walletUrl} = useSelector(getWebviewParams);
    const {employees, employeeCount, demoUserCount} = useSelector(getCompanyDetails);
    const {alias: companyAlias} = useSelector(getActiveCompany);
    const planPeriods = useSelector(getActiveCompanyPlanPeriods);
    const planPeriod = useSelector(state => getActiveCompanyPlanPeriodById(state, planPeriodId));
    const isCompanyRenewing = useSelector(state => getActiveCompanyStageCriteria(state, COMPANY_STAGES.renewal));
    const [plans, setPlans] = useState([]);

    const {start_date: periodStartDate, end_date: periodEndDate, status} = planPeriod;
    const [formattedPeriodStartDate, formattedPeriodEndDate] = useMemo(() => [periodStartDate, periodEndDate].map(date => new Date(date)), [periodStartDate, periodEndDate]);
    const areEmployees = useMemo(() => [employeeCount, demoUserCount].some(negateFunc(getEqual(0))), [employeeCount, demoUserCount]);
    const planOptions = useMemo(() => {
        return [CORE_PLAN_TYPES.medical, CORE_PLAN_TYPES.dental, CORE_PLAN_TYPES.vision].reduce((acc, planType) => {
            const options = plans.filter(getEqual(planType, 'plan_kind')).map(({name: label, id: value}) => ({label, value}));

            return {...acc, [planType]: options};
        }, {});
    }, [plans]);

    const loadEmployeeOptions = useCallback(promisifyAsyncFunction(async query => {
        const {employees} = await dispatch(requestCompanyEmployees(query));

        return employees.map(({display: label}) => ({label, value: label}));
    }), [dispatch]);

    const onChangePlan = useCallback((value, form) => {
        dispatch(requestPlanPreviewParams(planPeriodId, value));
        form.batch(() => ['employee', 'date'].forEach(field => form.change(field, null)));
    }, [dispatch, planPeriodId]);

    const onChangeWalletPreview = useCallback((value, form) => {
        const {values} = form.getState();
        const {medical_plan_id: medicalPlanId} = values;
        dispatch(requestPlanPreviewParams(planPeriodId, medicalPlanId));
        form.batch(() => ['employee', 'date'].forEach(field => form.change(field, null)));
    }, [dispatch, planPeriodId]);

    const onChangeEmployee = useCallback((value, form) => {
        (async () => {
            const {id: userId} = employees.find(getEqual(value, 'display')) || {};
            await dispatch(requestEmployeePreviewParams(planPeriodId, userId));

            form.batch(() => ['medical_plan_id', 'dental_plan_id', 'vision_plan_id', 'date'].forEach(field => form.change(field, null)));
        })();
    }, [dispatch, planPeriodId, employees]);

    const onChangeDate = useCallback((value, form) => {
        const {values} = form.getState();
        const {id: userId} = employees.find(getEqual(values.employee, 'display')) || {};

        dispatch(requestEmployeePreviewParams(planPeriodId, userId, value));
    }, [dispatch, planPeriodId, employees]);

    const saveCardsReordering = useCallback(form => {
        dispatch(requestAllWalletCardsPreviewParams(planPeriodId));
        form.batch(() => ['plan', 'employee', 'date'].forEach(field => form.change(field, null)));

        closePopup();
    }, [dispatch, planPeriodId, closePopup]);

    const openCardsReorderingPopup = useCallback(form => {
        const onSaveCardsReordering = () => saveCardsReordering(form);
        const children = <CardsReorderingPopup onSave={onSaveCardsReordering} onCancel={closePopup}/>;

        openPopup({type: POPUP_TYPES.right, children});
    }, [openPopup, closePopup, saveCardsReordering]);

    useEffect(() => {
        (async () => {
            dispatch(requestAllWalletCardsPreviewParams(planPeriodId));
            dispatch(requestCompanyEmployeeCount());

            const {plans} = await dispatch(requestCorePlansList({
                company_alias: companyAlias,
                plan_period_id: planPeriodId
            }));
            setPlans(plans);
        })();
    }, [dispatch, companyAlias, planPeriodId]);

    return (
        <div className='preview-layout'>
            {IS_MS_BROWSER && <UnsupportedBrowserAlert className='preview-layout__alert'/>}

            <PreviewPlanInfoAlert planPeriod={planPeriod} planPeriods={planPeriods} isCompanyRenewing={isCompanyRenewing}/>

            <Form name={FORMS.benefitsPreview} onSubmit={pass}>
                {({values, form}) => {
                    const onReorderCards = ({target}) => {
                        analyticsService.trackClicked(target.textContent);
                        openCardsReorderingPopup(form);
                    };
                    const {medical_plan_id: medicalPlanId, dental_plan_id: dentalPlanId, vision_plan_id: visionPlanId} = values;
                    const corePlansQuery = stringifyQueryParams({
                        medical_plan_id: medicalPlanId,
                        dental_plan_id: dentalPlanId,
                        vision_plan_id: visionPlanId
                    });
                    const contextQuery = stringifyQueryParams({previewContext: PREVIEW_CONTEXT});
                    const walletUrlWithCoreQuery = `${walletUrl}&${corePlansQuery}`;
                    const [contextedBenefitsUrl, contextedWalletUrl] = [benefitsUrl, walletUrlWithCoreQuery].map(url => `${url}&${contextQuery}`);
                    const updatedBenefitsUrl = benefitsUrl ? contextedBenefitsUrl : benefitsUrl;

                    return (
                        <form noValidate>
                            <Row className='preview-controls'>
                                <Column sm={4}>
                                    <ContentSection className='mb-12'>
                                        <Text type={TEXT_TYPES.bodyBold} className='preview-controls__title'>Select Medical Plan</Text>

                                        <Field name='medical_plan_id' onChange={onChangePlan}>
                                            {props => (
                                                <Select {...props}
                                                    disabled={isEmpty(planOptions[CORE_PLAN_TYPES.medical])}
                                                    options={planOptions[CORE_PLAN_TYPES.medical]}
                                                    label='Select a medical plan to view your benefits summary'/>
                                            )}
                                        </Field>
                                    </ContentSection>
                                    <ContentSection className='mb-12'>
                                        <Text type={TEXT_TYPES.bodyBold} className='preview-controls__title'>Select Dental Plan</Text>

                                        <Field name='dental_plan_id' onChange={onChangeWalletPreview}>
                                            {props => (
                                                <Select {...props}
                                                    disabled={isEmpty(planOptions[CORE_PLAN_TYPES.dental])}
                                                    options={planOptions[CORE_PLAN_TYPES.dental]}
                                                    label='Select a dental plan to view your benefits summary'/>
                                            )}
                                        </Field>
                                    </ContentSection>
                                    <ContentSection>
                                        <Text type={TEXT_TYPES.bodyBold} className='preview-controls__title'>Select Vision Plan</Text>

                                        <Field name='vision_plan_id' onChange={onChangeWalletPreview}>
                                            {props => (
                                                <Select {...props}
                                                    disabled={isEmpty(planOptions[CORE_PLAN_TYPES.vision])}
                                                    options={planOptions[CORE_PLAN_TYPES.vision]}
                                                    label='Select a vision plan to view your benefits summary'/>
                                            )}
                                        </Field>
                                    </ContentSection>
                                </Column>

                                <Column sm={8}>
                                    <ContentSection>
                                        {!areEmployees && <UnavailableEligibilityDataAlert className='preview-controls__alert'/>}

                                        <Row>
                                            <Column sm={8}>
                                                <Text type={TEXT_TYPES.bodyBold} className='preview-controls__title'>Preview an Individual Employee’s Benefits</Text>

                                                <Field name='employee' onChange={onChangeEmployee}>
                                                    {props => (
                                                        <AsyncAutocomplete {...props}
                                                            isCreatable={false}
                                                            disabled={!areEmployees}
                                                            loadOptions={loadEmployeeOptions}
                                                            placeholder='Type employee name or email...'
                                                            wrapperClassName='mb-12'
                                                            label='Select an employee to see their Benefits Wallet and Plan Summary'/>
                                                    )}
                                                </Field>

                                                <Field name='date' onChange={onChangeDate}>
                                                    {props => (
                                                        <DatePicker {...props}
                                                            isClearable
                                                            disabled={!values.employee}
                                                            minDate={equal(status, PLAN_PERIOD_TYPES.renewal) ? formattedPeriodStartDate : new Date()}
                                                            maxDate={formattedPeriodEndDate}
                                                            label='Date'/>
                                                    )}
                                                </Field>
                                            </Column>

                                            {areEmployees && (
                                                <Column sm={4} className='preview-controls-employees'>
                                                    <div className='employees-numbers'>
                                                        <Text className='employees-numbers__title'>Number of Eligible Employees:</Text>
                                                        <div className='employees-numbers__counter'>{employeeCount}</div>
                                                    </div>
                                                </Column>
                                            )}
                                        </Row>
                                    </ContentSection>

                                    <ContentSection className='reorder-section'>
                                        <Text>Change the order in which cards appear in your employees' benefits wallet.</Text>
                                        <Button className='reorder-section__button' onClick={onReorderCards}>Reorder</Button>
                                    </ContentSection>
                                </Column>
                            </Row>

                            <Row className='preview-screens'>
                                <Column sm={6} className='preview-screens-phone'>
                                    <Heading className='preview-screens-phone__heading'>Plan Benefits Preview</Heading>

                                    <div className='modal-wrapper'>
                                        <HjWebviewPreview type='benefits' src={updatedBenefitsUrl}/>
                                    </div>
                                </Column>

                                <Column sm={6} className='preview-screens-phone'>
                                    <Heading className='preview-screens-phone__heading'>Benefit Wallet Preview</Heading>

                                    <div className='modal-wrapper'>
                                        <HjWebviewPreview type='wallet' src={contextedWalletUrl}/>
                                    </div>
                                </Column>
                            </Row>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

PreviewLayout.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {PreviewLayout as TestablePreviewLayout};
export default withPopup(POPUP_ID)(PreviewLayout);
