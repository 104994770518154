import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Migration from './Migration';
import TerminationThreshold from '../TerminationThreshold';
import {requestEligibilityImportSessionStats} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {ELIGIBILITY_IMPORT_STATUSES, ROUTES} from '../../../constants';
import {equal, getEqual} from '../../../utils';
import './index.scss';

const THRESHOLDS = [
    {key: '#migration', component: Migration},
    {key: '#termination', component: TerminationThreshold}
];

const EligibilityImportThresholds = () => {
    const [isEditable, setIsEditable] = useState(null);
    const [importData, setImportData] = useState({});
    const {id: importId} = useParams();
    const {hash: thresholdType} = useLocation();
    const dispatch = useDispatch();
    const {component} = useMemo(() => THRESHOLDS.find(getEqual(thresholdType, 'key')), [thresholdType]);
    const Component = component || {};

    useEffect(() => {
        (async () => {
            const {isSuccess, data: importData} = await dispatch(requestEligibilityImportSessionStats(importId));

            if (!isSuccess) {
                return dispatch(redirectTo(ROUTES.eligibilityImports));
            }

            setIsEditable(equal(ELIGIBILITY_IMPORT_STATUSES.pending_confirmation, importData?.import_info?.status));
            setImportData(importData);
        })();
    }, [importId, dispatch]);

    return (
        <div className='eligibility-import-thresholds'>
            <div className='mt-15'>
                <Component isEditable={isEditable} importData={importData}/>
            </div>
        </div>
    );
};

export {EligibilityImportThresholds as TestableEligibilityImportThresholds};
export default EligibilityImportThresholds;
