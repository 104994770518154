import React from 'react';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import './index.scss';

const RolePermissions = () => (
    <ContentSection className='role-permissions'>
        <Text className='role-permissions-title' type={TEXT_TYPES.bodyBold}>
            <Icon className='role-permissions-title__icon' type={ICON_TYPES.info}/> Role Permissions
        </Text>

        <Text className='role-permissions-item'>
            <span className='role-permissions-item__title'>Admin</span> —
            Able to view and edit all benefits and eligibility information.
            Able to view all HealthJoy materials and performance. Can invite other contacts to the dashboard.
        </Text>

        <Text className='role-permissions-item'>
            <span className='role-permissions-item__title'>Contributor</span> —
            Same as Admin but cannot invite others to the Dashboard and cannot view Member Activation information in Reporting.
        </Text>

        <Text className='role-permissions-item'>
            <span className='role-permissions-item__title'>Viewer</span> —
            Able to view all benefits information, HealthJoy materials and performance. Cannot see eligibility.
        </Text>

        <Text className='role-permissions-item'>
            <span className='role-permissions-item__title'>Vendor Contributor</span> —
            Recommended for Ben-Admin and TPA partners involved in implementation.
            Able to upload eligibility information. Restricted access to all other areas of the dashboard.
        </Text>

        <Text className='role-permissions-item'>
            <span className='role-permissions-item__title'>Help Center-Only Viewer</span> —
            Recommended for external uses that only need access to HealthJoy’s Help Center. Will not be able to access any other part of HealthJoy’s client dashboard.
        </Text>
    </ContentSection>
);

export {RolePermissions as TestableRolePermissions};
export default React.memo(RolePermissions);
