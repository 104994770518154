import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import MultipleChoice from '../MultipleChoice';
import withPopup from '../../../HOC/withPopup';
import {redirectTo, requestCompanyByAlias, requestFeatureFlag} from '../../../actions/general';
import {
    requestImportConfigUsers,
    requestIndividualConfigVersions,
    requestTPAPartnerConfigVersions
} from '../../../actions/adminPortal';
import {getProfileInfo} from '../../../selectors/general';
import {FEATURE_FLAGS, IMPORT_VERSIONS_CONFIG_TYPES, MULTIPLE_CHOICE_TYPES, ROUTES} from '../../../constants';
import {
    convertDateToTimeZone,
    equal,
    formatDate,
    getYear,
    isEmpty,
    isFuture,
    parseQuery,
    stringifyQueryParams
} from '../../../utils';
import {IMPORT_CONFIG_STATUS_OPTIONS} from '../../../options';
import './index.scss';

const POPUP_ID = 'ImportConfigVersions';

const PLAN_PERIOD_TITLE_SEPARATOR = '=_=';

const ImportConfigVersions = ({openPopup}) => {
    const dispatch = useDispatch();
    const {group_alias: groupAlias, partner: partnerId} = useParams();
    const {search} = useLocation();
    const [configVersions, setConfigVersions] = useState({});
    const {email: userEmail} = useSelector(getProfileInfo);

    const getPeriodOptions = async () => {
        const {data: company} = await dispatch(requestCompanyByAlias(groupAlias));

        return company?.all_plan_periods?.reduce((acc, {start_date: startDate, id}) => {
            return [...acc, {label: formatDate(startDate, 'M/d/yyyy'), value: id}];
        }, []);
    };

    const getEditorOptions = async () => {
        const {data} = await dispatch(requestImportConfigUsers(partnerId ? {tpa_partner_id: partnerId} : {company_alias: groupAlias}));

        return data.reduce((acc, email) => [...acc, {label: email, value: email}], []);
    };

    const prepareIndividualChoices = async () => {
        const [periodOptions, editorOptions] = await Promise.all([getPeriodOptions(), getEditorOptions()]);

        return [
            {name: 'Period', type: 'plan_period_id', options: periodOptions},
            {name: 'Status', type: 'config_type', options: IMPORT_CONFIG_STATUS_OPTIONS},
            {name: 'Editor', type: 'updated_by', options: editorOptions}
        ];
    };

    const prepareTPAChoices = async () => {
        const editorOptions = await getEditorOptions();

        return [
            {name: 'Email', type: 'updated_by', options: editorOptions},
            {name: 'Date', type: 'updated_at', choiceType: MULTIPLE_CHOICE_TYPES.date, isDateRange: true, isMultilineInput: true}
        ];
    };

    const prepareIndividualConfigVersions = versions => versions.reduce((acc, configVersion) => {
        const {
            updated_at: updatedAt,
            config_type: configType,
            plan_period_termination_date: terminationDate,
            plan_period_effective_date: effectiveDate,
            ...restValues
        } = configVersion || {};
        const [formattedEffectiveDate, formattedTerminationDate] = [effectiveDate, terminationDate].map(date => formatDate(date, 'MMMM d, yyyy'));
        const planPeriodKey = [configType, formattedEffectiveDate, formattedTerminationDate].join(PLAN_PERIOD_TITLE_SEPARATOR);
        const enhancedConfigVersion = {
            ...restValues,
            configType,
            updatedAt: `${formatDate(convertDateToTimeZone(updatedAt), 'MM/dd/yyyy h:mm a')} CST`
        };

        return {...acc, [planPeriodKey]: [...acc[planPeriodKey] || [], enhancedConfigVersion]};
    }, {});

    const prepareTPAConfigVersions = versions => versions.reduce((acc, configVersion) => {
        const {
            updated_at: updatedAt,
            ...restValues
        } = configVersion || {};
        const yearKey = getYear(new Date(updatedAt));
        const enhancedConfigVersion = {
            ...restValues,
            updatedAt: `${formatDate(convertDateToTimeZone(updatedAt), 'MM/dd/yyyy h:mm a')} CST`
        };

        return {...acc, [yearKey]: [...acc[yearKey] || [], enhancedConfigVersion]};
    }, {});

    const setConfigVersionsData = async query => {
        const requestConfigVersionsFunc = partnerId ? requestTPAPartnerConfigVersions : requestIndividualConfigVersions;
        const {data: versions, isSuccess} = await dispatch(requestConfigVersionsFunc(partnerId || groupAlias, query));
        const prepareConfigVersionsFunc = partnerId ? prepareTPAConfigVersions : prepareIndividualConfigVersions;

        if (!isSuccess) {
            return false;
        }

        const enhancedConfigVersions = prepareConfigVersionsFunc(versions);
        setConfigVersions(enhancedConfigVersions);

        return enhancedConfigVersions;
    };

    useEffect(() => {
        (async () => {
            const {flag: isConfigVersionsHistory} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.configVersionsHistory, {userEmail}));

            if (!isConfigVersionsHistory) {
                return false;
            }

            setConfigVersionsData();
        })();
    }, [dispatch, userEmail]);

    const onGoToArchivedConfig = (sessionId, version) => {
        const {version: _, isRenewal, show_submit_alert, show_cobra_submit_alert, ...restSearch} = parseQuery(search);
        const versionQuery = `${partnerId ? 'Version History Configure TPA' : 'Archived Config'} - Version ${version}`;
        const enhancedSearch = stringifyQueryParams({...restSearch, version: versionQuery});
        const route = partnerId
            ? `${ROUTES.importsTpa}/${partnerId}/archived_tpa_config/${sessionId}?${enhancedSearch}`
            : `${ROUTES.importsIndividual}/${groupAlias}/archived_group_config/${sessionId}?${enhancedSearch}`;

        dispatch(redirectTo(route));
    };

    const onGoToActiveConfig = isRenewal => {
        const {version, isRenewal: _, show_submit_alert, show_cobra_submit_alert, ...restSearch} = parseQuery(search);
        const enhancedSearch = stringifyQueryParams({...restSearch, ...isRenewal && {isRenewal: true}});
        const route = partnerId
            ? `${ROUTES.importsTpa}/${partnerId}/tpa_config?${enhancedSearch}#configuration`
            : `${ROUTES.importsIndividual}/${groupAlias}/group_config?${enhancedSearch}#configuration`;

        dispatch(redirectTo(route));
    };

    const getConfigVersion = ({version, is_active: isActive, configType, updated_by: updatedBy, updatedAt, session_id: sessionId}) => {
        const onConfigVersionClick = () => isActive
            ? onGoToActiveConfig(equal(configType, IMPORT_VERSIONS_CONFIG_TYPES.Renewal))
            : onGoToArchivedConfig(sessionId, version);

        return (
            <ContentSection key={sessionId} className='config-version mb-10' onClick={onConfigVersionClick}>
                <Row middle='xs'>
                    <Column xs>
                        <div>
                            <Text className='mr-8' isInline type={TEXT_TYPES.bodyBold}>Version {version}</Text>
                            {isActive && <Sticker className='mr-3' type={STICKER_TYPES.success}>Active</Sticker>}
                            {configType && <Sticker>{configType}</Sticker>}
                        </div>
                        <div className='mt-5'>
                            <Text className='config-version__text' type={TEXT_TYPES.helper}>{updatedAt}</Text>
                            <Icon className='config-version__circle ml-3 mr-3' type={ICON_TYPES.circle}/>
                            <Text className='config-version__text' type={TEXT_TYPES.helper}>{updatedBy}</Text>
                        </div>
                    </Column>
                    <Column constant>
                        <Icon type={ICON_TYPES.chevronRight} className='config-version__arrow'/>
                    </Column>
                </Row>
            </ContentSection>
        );
    };

    const getIndividualConfigVersionsTitle = key => {
        const [configType, effectiveDate, terminationDate] = key.split(PLAN_PERIOD_TITLE_SEPARATOR);
        const isCurrentPlanPeriod = isFuture(new Date(terminationDate));

        return (
            <React.Fragment>
                {isCurrentPlanPeriod && (
                    <React.Fragment>
                        {equal(configType, IMPORT_VERSIONS_CONFIG_TYPES.Maintenance) ? 'Current' : configType} Plan Period <br/>
                    </React.Fragment>
                )}
                {effectiveDate} - {terminationDate}
            </React.Fragment>
        );
    };

    const onOpenConfigVersions = async query => {
        const prepareChoicesFunc = partnerId ? prepareTPAChoices : prepareIndividualChoices;
        const [configVersions, choices] = await Promise.all([setConfigVersionsData(query), prepareChoicesFunc()]);

        const popupProps = {
            title: partnerId ? 'TPA Parent Config Versions' : 'Config Versions',
            children: (
                <div className='import-config-versions-popup'>
                    <MultipleChoice name='configVersions' isVertical choices={choices} onChange={onOpenConfigVersions}/>

                    {Object.entries(configVersions).map(([key, versions]) => (
                        <Collapse key={key}
                            hasCollapseIcon
                            isOpened
                            className='mb-10'
                            initiator={(
                                <Heading type={HEADING_TYPES['5']}>
                                    {partnerId ? key : getIndividualConfigVersionsTitle(key)}
                                </Heading>
                            )}
                            content={versions.map(getConfigVersion)}/>
                    ))}
                </div>
            )
        };
        const children = <PopupContent {...popupProps}/>;

        openPopup({type: POPUP_TYPES.right, children});
    };

    return (
        !isEmpty(configVersions) && <Button onClick={() => onOpenConfigVersions()} type={BUTTON_TYPES.tertiary} className='mr-10'>{partnerId ? 'View TPA Parent Config Versions' : 'View Config Versions'}</Button>
    );
};

ImportConfigVersions.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {ImportConfigVersions as TestableImportConfigVersions};
export default withPopup(POPUP_ID)(ImportConfigVersions);
