import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import RevisionsField from '../RevisionsField';
import {requestCoreCarriers, requestAncillaryCarriers} from '../../../actions/shared';
import {promisifyAsyncFunction} from '../../../utils';

const CarrierAutocomplete = ({name, onChange, isCore, isLabelWithHiosId, formatValue, parseValue, ...restProps}) => {
    const dispatch = useDispatch();

    const loadCarrierOptions = useCallback(promisifyAsyncFunction(async query => {
        const carriers = isCore ? await dispatch(requestCoreCarriers(query)) : await dispatch(requestAncillaryCarriers(query));

        return carriers.map(carrier => {
            // FYI: name is for ancillary carrier and issuer_name is for core one (13.08.2020, Oleh)
            const label = carrier.name ?? carrier.issuer_name;

            return {label, value: isLabelWithHiosId ? carrier.hios_id : label, ...isLabelWithHiosId && {description: `ID: ${carrier.hios_id}`}};
        });
    }), [dispatch, isCore]);

    return (
        <RevisionsField name={name} onChange={onChange}>
            {props => (
                <AsyncAutocomplete {...props}
                    {...restProps}
                    loadOptions={loadCarrierOptions}
                    formatValue={formatValue}
                    parseValue={parseValue}
                    placeholder='Type to select carrier...'/>
            )}
        </RevisionsField>
    );
};

CarrierAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    isCore: PropTypes.bool,
    isLabelWithHiosId: PropTypes.bool,
    formatValue: PropTypes.func,
    parseValue: PropTypes.func,
    onChange: PropTypes.func
};

CarrierAutocomplete.defaultProps = {
    isCore: false,
    isLabelWithHiosId: false
};

export {CarrierAutocomplete as TestableCarrierAutocomplete};
export default React.memo(CarrierAutocomplete);
