import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import HelpVideoTooltip from '../HelpVideoTooltip';
import {ONBOARDING_VIDEOS_INFO} from '../../../constants';
import './index.scss';

const PageHeader = ({title, caption, children, match}) => {
    const videoOptions = ONBOARDING_VIDEOS_INFO[match.path];

    return (
        <Row>
            <Column sm={10}>
                <div className='page-header'>
                    <Heading data-testid='page-header-title' className='page-header-title' type={HEADING_TYPES['1']}>
                        {title}
                        {videoOptions?.videoId && <HelpVideoTooltip videoOptions={videoOptions}/>}
                    </Heading>
                    <Text>{caption}</Text>
                </div>
            </Column>
            <Column sm={2}>
                {children}
            </Column>
        </Row>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    match: PropTypes.shape({path: PropTypes.string}),
    children: PropTypes.node,
    caption: PropTypes.string
};

export {PageHeader as TestablePageHeader};
export default withRouter(PageHeader);
