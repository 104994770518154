import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Forms from '../AncillaryPlansDetailsForms';
import InternalMenu from '../../../Containers/shared/InternalMenu';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {clearAncillaryPlans, requestAncillaryPlans} from '../../../actions/benefits';
import {setInternalMenuItems} from '../../../actions/internalMenu';
import {getIsSuperAdmin} from '../../../selectors/general';
import {getAncillaryPlans} from '../../../selectors/benefits';
import {getInternalMenuActiveItem, getInternalMenuItems} from '../../../selectors/internalMenu';
import {getAncillaryPlanLabel} from '../../../helpers';
import {isEmpty, equal, getEqual} from '../../../utils';
import {ANCILLARY_PLAN_TYPES, ANCILLARY_PLANS_DATA_BY_CATEGORY, DEFAULT_INTERNAL_MENU_INDEX} from '../../../constants';
import './index.scss';

const FORM_BY_TYPE = {
    [ANCILLARY_PLAN_TYPES.custom]: Forms.CustomForm,
    [ANCILLARY_PLAN_TYPES.basic]: Forms.AccidentForm,
    [ANCILLARY_PLAN_TYPES.hsa]: Forms.HsaForm,
    [ANCILLARY_PLAN_TYPES.hra]: Forms.HraForm,
    [ANCILLARY_PLAN_TYPES.fsa]: Forms.FsaForm,
    [ANCILLARY_PLAN_TYPES.life]: Forms.BasicLifeForm,
    [ANCILLARY_PLAN_TYPES.ltd]: Forms.LtdForm,
    [ANCILLARY_PLAN_TYPES.std]: Forms.StdForm,
    [ANCILLARY_PLAN_TYPES.voluntary_life]: Forms.VoluntaryLifeForm,
    [ANCILLARY_PLAN_TYPES.critical_illness]: Forms.CriticalIllnessForm,
    [ANCILLARY_PLAN_TYPES.cancer]: Forms.CancerForm,
    [ANCILLARY_PLAN_TYPES.hospital_indemnity]: Forms.HospitalIndemnityForm,
    [ANCILLARY_PLAN_TYPES.k401]: Forms.K401Form,
    [ANCILLARY_PLAN_TYPES.universal_life]: Forms.UniversalLifeForm,
    [ANCILLARY_PLAN_TYPES.whole_life]: Forms.WholeLifeForm,
    [ANCILLARY_PLAN_TYPES.eap]: Forms.EapForm,
    [ANCILLARY_PLAN_TYPES.medical_travel]: Forms.MedicalTravelForm,
    [ANCILLARY_PLAN_TYPES.prescription_program]: Forms.RxProgramForm,
    [ANCILLARY_PLAN_TYPES.wellness]: Forms.WellnessForm,
    [ANCILLARY_PLAN_TYPES.benefit_guide]: Forms.BenefitGuide
};

// TODO: combine this component with CorePlansDetailsLayout. Btw, CLEAR_*_PLANS can be combined too (09.11.2020, Oleh);
const AncillaryPlansDetailsLayout = ({onComplete}) => {
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(null);
    const [isCustomizedMode, setIsCustomizedMode] = useState();
    const {planPeriodId} = useParams();
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const ancillaryPlans = useSelector(getAncillaryPlans);
    const internalMenuActiveItem = useSelector(getInternalMenuActiveItem);
    const internalMenuItems = useSelector(getInternalMenuItems);

    const ancillaryPlan = internalMenuItems ? ancillaryPlans.find(getEqual(activeItem, 'id')) : ancillaryPlans[DEFAULT_INTERNAL_MENU_INDEX];
    const {id: planId, category: planCategory, legacy_card_id: legacyCardId} = ancillaryPlan || {};
    const {label, card, type} = ANCILLARY_PLANS_DATA_BY_CATEGORY[planCategory] || {};
    const Form = FORM_BY_TYPE[type];
    const formProps = {
        card,
        planId,
        planCategory,
        onSuccess: onComplete,
        title: label,
        isCustomizable: !legacyCardId || isSuperAdmin,
        isCustomizedMode,
        setCustomizedMode: setIsCustomizedMode
    };

    useEffect(() => {
        const {customized: isCustomizedMode} = ancillaryPlans.find(getEqual(internalMenuActiveItem, 'id')) || {};

        if (!isEmpty(ancillaryPlans) && !equal(activeItem, internalMenuActiveItem)) {
            setIsCustomizedMode(isCustomizedMode);
            setActiveItem(internalMenuActiveItem);
        }
    }, [activeItem, ancillaryPlans, internalMenuActiveItem]);

    useEffect(() => {
        (async () => {
            const {items: ancillaryPlans} = await dispatch(requestAncillaryPlans(planPeriodId));

            const internalMenuItems = ancillaryPlans.map(ancillaryPlan => {
                const {category, completed, name, category_index: categoryIndex, id} = ancillaryPlan;
                const label = getAncillaryPlanLabel({category, categoryIndex, cardTitle: name});

                return {label, completed, id};
            });

            dispatch(setInternalMenuItems(internalMenuItems));
        })();

        return () => dispatch(clearAncillaryPlans());
    }, [dispatch, planPeriodId]);

    return !!ancillaryPlans.length && (
        <div className='ancillary-plans-details-layout'>
            <Row className='ancillary-plans-details-layout-row'>
                <Column className='ancillary-plans-details-layout-row__column' sm={4}>
                    {!isEmpty(internalMenuItems) && <InternalMenu/>}
                </Column>

                <Column className='ancillary-plans-details-layout-row__column' sm={8}>
                    <ContentSection className='plan-form-wrapper'>
                        {ancillaryPlan && <Form {...formProps}/>}
                    </ContentSection>
                </Column>
            </Row>
        </div>
    );
};

AncillaryPlansDetailsLayout.propTypes = {
    onComplete: PropTypes.func
};

export {AncillaryPlansDetailsLayout as TestableAncillaryPlansDetailsLayout};
export default withSubStepCompletion(AncillaryPlansDetailsLayout);
