import apiServices from '../apiServices';
import {
    CLEAR_MULTIPLE_CHOICE,
    RECEIVE_CARRIER_MANAGER_CARRIERS,
    RECEIVE_PLAN_MANAGER_BENEFITS_CATEGORIES,
    RECEIVE_PLAN_MANAGER_BENEFITS_ORDERING,
    RECEIVE_PLAN_MANAGER_PLAN_NAMES,
    RECEIVE_PLAN_MANAGER_PLAN_VERSIONS,
    RECEIVE_PLAN_MANAGER_PLANS,
    SET_MULTIPLE_CHOICE_CHOICES,
    SET_MULTIPLE_CHOICE_VALUES
} from './actionTypes';
import {clearFormRevisions, receiveFormRevisions} from './shared';
import {getProfileInfo} from '../selectors/general';
import {MULTIPLE_CHOICE_DEFAULT_NAME} from '../constants';

export const receivePlanManagerPlans = planManagerPlans => ({type: RECEIVE_PLAN_MANAGER_PLANS, planManagerPlans});
const receivePlanManagerPlanNames = planManagerPlanNames => ({type: RECEIVE_PLAN_MANAGER_PLAN_NAMES, planManagerPlanNames});
const receivePlanManagerPlanVersions = planManagerPlanVersions => ({type: RECEIVE_PLAN_MANAGER_PLAN_VERSIONS, planManagerPlanVersions});
const receivePlanManagerBenefitsCategories = planManagerBenefitsCategories => ({type: RECEIVE_PLAN_MANAGER_BENEFITS_CATEGORIES, planManagerBenefitsCategories});
const receivePlanManagerBenefitsOrdering = planManagerBenefitsOrdering => ({type: RECEIVE_PLAN_MANAGER_BENEFITS_ORDERING, planManagerBenefitsOrdering});
export const receiveCarrierManagerCarriers = carrierManagerCarriers => ({type: RECEIVE_CARRIER_MANAGER_CARRIERS, carrierManagerCarriers});

export const requestPlanManagerPlans = query => async () => {
    const {data, isSuccess} = await apiServices.getCorePlans({query});
    const planManagerPlans = isSuccess ? data : [];

    return {data: planManagerPlans, isSuccess};
};

export const requestPlanManagerPlanNames = query => async dispatch => {
    const {data, isSuccess} = await apiServices.getCorePlans({query, isLoader: false, isCancelable: true});
    const corePlans = isSuccess ? data : [];
    const planNames = corePlans.map(({name}) => name);

    dispatch(receivePlanManagerPlanNames(planNames));

    return {planNames, isSuccess};
};

export const requestPlanManagerPlan = (planId, versionId, comparisonVersionsIds) => async dispatch => {
    const {data, isSuccess} = versionId
        ? await apiServices.getMedtoolPlanRevision({planId, revisionId: versionId, comparisonRevisionsIds: comparisonVersionsIds})
        : await apiServices.getCorePlan({planId});
    const plan = isSuccess ? data : {};
    const {compare_to: comparisonRevisions, ...originalRevision} = plan;

    dispatch(clearFormRevisions()); // FYI: If user switches from compare to edit mode, we need to clear formRevisions to prevent showing revisions (Vlad, 20.05.21)

    if (comparisonRevisions) {
        dispatch(receiveFormRevisions({originalRevision, comparisonRevisions}));
    }

    return {data, isSuccess};
};

export const requestPlanManagerPlanUpdating = (planId, versionId, planDetails) => async () => {
    const {data, isSuccess} = await apiServices.updateCorePlan({planId, versionId, planDetails});

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestPlanManagerPlanDeleting = planId => async () => {
    const {data, isSuccess} = await apiServices.deleteCorePlan({planId});

    return {data, isSuccess};
};

export const requestPlanManagerPlanVersions = planId => async dispatch => {
    const {data, isSuccess} = await apiServices.getMedtoolPlanRevisions({planId});

    if (!isSuccess) {
        return false;
    }

    dispatch(receivePlanManagerPlanVersions(data));

    return {data, isSuccess};
};

export const requestPlanManagerBenefitsCategories = () => async dispatch => {
    const {data, isSuccess} = await apiServices.getBenefitsCategories();
    const {name_to_category: nameToCategory = [], ordering = {}} = isSuccess ? data : {};
    const formattedNameToCategory = nameToCategory.map(({name, ...rest}) => ({...rest, name: name.toLowerCase()}));

    dispatch(receivePlanManagerBenefitsCategories(formattedNameToCategory));
    dispatch(receivePlanManagerBenefitsOrdering(ordering));

    return {benefitsCategories: nameToCategory, isSuccess};
};

export const requestCarrierManagerCarriers = query => async () => {
    const {data, isSuccess} = await apiServices.getMedtoolIssuers({query, isLoader: true, isCancelable: false}); // FYI: we use MedtoolIssuers instead CoreCarriers according to transfer api to the new one (Pasha, 27.05.2021)
    const carriers = isSuccess ? data : [];

    return {data: carriers, isSuccess};
};

export const requestCarrierManagerCarrier = carrierId => async () => {
    const {data, isSuccess} = await apiServices.getMedtoolIssuer({carrierId});
    const plan = isSuccess ? data : {};

    return {data: plan, isSuccess};
};

export const requestCarrierManagerCarrierCreating = carrierDetails => async () => {
    const {data, isSuccess} = await apiServices.createMedtoolIssuer({carrierDetails});

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestCarrierManagerCarrierUpdating = (carrierId, carrierDetails) => async () => {
    const {data, isSuccess} = await apiServices.updateMedtoolIssuer({carrierId, carrierDetails});

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestCarrierManagerCarrierDeleting = carrierId => async () => {
    const {data, isSuccess} = await apiServices.deleteMedtoolIssuer({carrierId});

    return {data, isSuccess};
};

export const setMultipleChoiceChoices = (choices, name = MULTIPLE_CHOICE_DEFAULT_NAME) => ({type: SET_MULTIPLE_CHOICE_CHOICES, choices, name});

export const setMultipleChoiceValues = (values, name = MULTIPLE_CHOICE_DEFAULT_NAME) => ({type: SET_MULTIPLE_CHOICE_VALUES, values, name});

export const clearMultipleChoice = () => ({type: CLEAR_MULTIPLE_CHOICE});

export const requestTimelineTemplates = query => async () => {
    const {data, isSuccess} = await apiServices.getTimelineTemplates({query});
    const timelineTemplates = isSuccess ? data : [];

    return {data: timelineTemplates, isSuccess};
};

export const requestTimelineTemplate = templateId => async () => {
    const {data, isSuccess} = await apiServices.getTimelineTemplate({templateId});

    return {data, isSuccess};
};

export const requestTimelineTemplateDeleting = templateId => async () => {
    const {data, isSuccess} = await apiServices.deleteTimelineTemplate({templateId});

    return {data, isSuccess};
};

export const requestTimelineTemplateCreating = timelineTemplate => async () => {
    const {data, isSuccess} = await apiServices.createTimelineTemplate({timelineTemplate});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestTimelineTemplateUpdating = timelineTemplate => async () => {
    const {id: templateId} = timelineTemplate;
    const {data, isSuccess} = await apiServices.updateTimelineTemplate({templateId, timelineTemplate});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestAppConfigurations = companyAlias => async () => {
    const {data, isSuccess} = await apiServices.getAppConfigurations({companyAlias});

    return {data, isSuccess};
};

export const requestConsultFees = companyAlias => async () => {
    const {data, isSuccess} = await apiServices.getAppConfigurationConsultFees({companyAlias});

    return {data, isSuccess};
};

export const requestConsultFeesUpdating = ({companyAlias, config}) => async () => {
    const {data, isSuccess} = await apiServices.updateAppConfigurationConsultFees({companyAlias, config});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestAppConfiguration = ({companyAlias, type}) => async () => {
    const {data, isSuccess} = await apiServices.getAppConfigurationByType({companyAlias, type});

    return {data, isSuccess};
};

export const requestAppConfigurationUpdating = ({companyAlias, type, config}) => async () => {
    const {data, isSuccess} = await apiServices.updateAppConfigurationByType({companyAlias, type, config});

    return {data, isSuccess, submissionErrors: data.messages};
};


export const requestSFTPPasswordLinkCreation = credentialsId => async () => {
    const {data, isSuccess} = await apiServices.createSFTPPasswordLink({credentialsId});

    return {data, isSuccess};
};

export const requestSFTPTemporaryPasswordCreation = credentialsId => async () => {
    const {data, isSuccess} = await apiServices.createSFTPTemporaryPassword({credentialsId});

    return {data, isSuccess};
};

export const requestSFTPCredentials = id => async () => {
    const {data, isSuccess} = await apiServices.getSFTPCredentials({id});

    return {data, isSuccess};
};

export const requestSFTPCredentialsList = ({hostType, usernameQuery, multiClientFeedNameQuery, isMultiClient}, isCancelable = true) => async () => {
    const {data, isSuccess} = await apiServices.getSFTPCredentialsList({hostType, isMultiClient, usernameQuery, multiClientFeedNameQuery, isCancelable});

    return {data, isSuccess};
};

export const requestSFTPCredentialsCreation = credentialsInfo => async () => {
    const {data, isSuccess} = await apiServices.createSFTPCredentials({credentialsInfo});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestMailOrderPrograms = () => async () => {
    const {data, isSuccess} = await apiServices.getMailOrderPrograms();

    return {data, isSuccess};
};

export const requestGroupIdGenerate = ({companyAlias, segment}) => async () => {
    const {data, isSuccess} = await apiServices.generateGroupId({companyAlias, segment});

    return {data, isSuccess};
};

export const requestDeleteGroupId = ({companyAlias, segment}) => async () => {
    const {data, isSuccess} = await apiServices.deleteGroupId({companyAlias, segment});

    return {data, isSuccess};
};

export const requestRecommendationsManagerDetailsConfig = () => async () => {
    const {data, isSuccess} = await apiServices.getRecommendationsManagerDetailsConfig();

    return {data, isSuccess};
};

export const requestRecommendationsManagerListConfig = () => async () => {
    const {data, isSuccess} = await apiServices.getRecommendationsManagerListConfig();

    return {data, isSuccess};
};

export const requestRecommendationsCardPublishing = cardDetails => async () => {
    const {data, isSuccess} = await apiServices.publishCard({cardDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestRecommendationsCardUnPublishing = id => async () => {
    const {isSuccess} = await apiServices.unPublishCard({id});

    return {isSuccess};
};

export const requestRecommendationsCardUpdatePublishing = (cardDetails, id) => async () => {
    const {data, isSuccess} = await apiServices.updatePublishCard({id, cardDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestRecommendationsCardDraftCreating = cardDetails => async () => {
    const {data, isSuccess} = await apiServices.createRecommendationsCardDraft({cardDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestRecommendationsCardDraftUpdating = (cardDetails, id) => async () => {
    const {data, isSuccess} = await apiServices.updateRecommendationsCardDraft({cardDetails, id});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestRecommendationsCard = ({cardId}) => async () => {
    const {data, isSuccess} = await apiServices.getRecommendationsCard({cardId});

    return {data, isSuccess};
};

export const requestRecommendationsCards = (query) => async () => {
    const {data, isSuccess} = await apiServices.getRecommendationsCards({query});

    return {data, isSuccess};
};

export const requestEligibilityImportSessions = params => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportSessions(params);
    const {data: sessions = [], count = 0} = isSuccess ? data : {};

    return {sessions, count, isSuccess};
};

export const requestEligibilityImportSessionCreating = sessionDetails => async () => {
    const {data, isSuccess} = await apiServices.createEligibilityImportSession({sessionDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestEligibilityImportSessionUpdating = (id, sessionDetails) => async () => {
    const {data, isSuccess} = await apiServices.updateEligibilityImportSession({id, sessionDetails});

    return {data, isSuccess};
};

export const requestEligibilityImportSession = id => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportSession({id});

    return {data, isSuccess};
};

export const requestEligibilityImportSessionStats = id => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportSessionStats({id});

    return {data, isSuccess};
};

export const requestEligibilityImportSessionErrors = id => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportSessionErrors({id});

    return {data, isSuccess};
};

export const requestMassMembersChanges = id => async () => {
    const {data, isSuccess} = await apiServices.getMassMembersChanges({id});

    return {data, isSuccess};
};

export const requestEligibilityImportMigratedMembers = query => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportSessionMigratedMembers(query);
    const {data: migratedMembers = [], count = 0} = isSuccess ? data : {};

    return {migratedMembers, count, isSuccess};
};

export const requestEligibilityImportTerminatedMembers = query => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportSessionTerminatedMembers(query);
    const {data: terminatedMembers = [], count = 0} = isSuccess ? data : {};

    return {terminatedMembers, count, isSuccess};
};

export const requestEligibilityImportUsers = createdBy => async () => {
    const {data, isSuccess} = await apiServices.getEligibilityImportUsers({created_by: createdBy});
    const {data: usersList = []} = isSuccess ? data : {};

    return {usersList, isSuccess};
};

export const requestTPAPartnerImportSessions = query => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartnerImportSessions(query);
    const sessions = isSuccess ? data : {};

    return {sessions, isSuccess};
};

export const requestTPAPartnerImportSessionCreating = tpaSession => async () => {
    const {data, isSuccess} = await apiServices.createTPAPartnerImportSession({session: tpaSession});
    const session = isSuccess ? data : {};

    return {session, isSuccess};
};

export const requestTPAPartnerImportSessionUpdating = (tpaSession, sessionId) => async () => {
    const {data, isSuccess} = await apiServices.updateTPAPartnerImportSession({session: tpaSession, sessionId});
    const session = isSuccess ? data : {};

    return {session, isSuccess};
};

export const requestTPAPartnerImportSession = tpaSessionId => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartnerImportSession({sessionId: tpaSessionId});
    const session = isSuccess ? data : {};

    return {session, isSuccess};
};

export const requestImportsJsonrpc = body => async () => {
    const {data, isSuccess} = await apiServices.createImportsJsonrpc({body});
    const jsonrpc = isSuccess ? data : {};

    return {jsonrpc, isSuccess};
};

export const requestPreprocessingReport = sessionId => async () => {
    const {data, isSuccess} = await apiServices.getPreprocessingReport({sessionId});
    const report = isSuccess ? data : {};

    return {report, isSuccess};
};

export const requestInsuranceNetworks = params => async () => {
    const {data, isSuccess} = await apiServices.getInsuranceNetworks(params);

    return {data, isSuccess};
};

export const requestImportConfigUsers = query => async () => {
    const {data, isSuccess} = await apiServices.getImportConfigUsers(query);

    return {data, isSuccess};
};

export const requestTPAPartnerConfig = ({tpaPartnerId}) => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartnerConfig({tpaPartnerId});

    return {data: data.data, isSuccess};
};

export const requestTPAPartnerConfigVersions = (tpaPartnerId, query) => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartnerConfigVersions({tpaPartnerId, query});

    return {data, isSuccess};
};

export const requestTPAPartnerConfigVersion = sessionId => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartnerConfigVersion({sessionId});

    return {data, isSuccess};
};

export const requestIndividualConfig = ({companyAlias}) => async () => {
    const {data, isSuccess} = await apiServices.getIndividualImportConfig({companyAlias});

    return {data, isSuccess};
};

export const requestIndividualConfigUpdating = configDetails => async () => {
    const {data, isSuccess} = await apiServices.updateIndividualImportConfig({configId: configDetails?.id, ...configDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestIndividualConfigAncillaryInfo = configId => async () => {
    const {data, isSuccess} = await apiServices.getIndividualConfigAncillaryInfo({configId, query: {duplicated_values: true}});
    const {duplicated_values: duplicatedValues, ...ancillaryInfo} = data || {};

    return {ancillaryInfo, duplicatedValues, isSuccess};
};

export const requestUnlistedAncillaryCategories = configId => async () => {
    const {data, isSuccess} = await apiServices.getIndividualConfigAncillaryCategories({configId, query: {unlisted: true}});

    return {data, isSuccess};
};

export const requestIndividualConfigAncillaryCategoryCreating = ({configId, categoryDetails}) => async () => {
    const {data, isSuccess} = await apiServices.createIndividualConfigAncillaryCategory({configId, categoryDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestIndividualConfigAncillaryCategoryUpdating = ({configId, categoryDetails}) => async () => {
    const {data, isSuccess} = await apiServices.updateIndividualConfigAncillaryCategory({configId, categoryDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestIndividualConfigAncillaryCategoryDeleting = ({configId, category}) => async () => {
    const {isSuccess} = await apiServices.deleteIndividualConfigAncillaryCategory({configId, category});

    return {isSuccess};
};

export const requestIndividualConfigVersions = (companyAlias, query) => async () => {
    const {data, isSuccess} = await apiServices.getIndividualImportConfigVersions({companyAlias, query});

    return {data, isSuccess};
};

export const requestIndividualConfigVersion = sessionId => async () => {
    const {data, isSuccess} = await apiServices.getIndividualImportConfigVersion({sessionId});

    return {data, isSuccess};
};

export const requestGroupAutomationStatus = companyAlias => async () => {
    const {data, isSuccess} = await apiServices.getGroupAutomationStatus({companyAlias});

    return {data, isSuccess};
};

export const requestTPAPartnerConfigCreating = ({tpaPartnerId, ...configDetails}) => async () => {
    const {data, isSuccess} = await apiServices.createTPAPartnerConfig({tpa_partner_id: tpaPartnerId, ...configDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestTPAPartnerConfigUpdating = ({tpaPartnerId, ...configDetails}) => async () => {
    const {data, isSuccess} = await apiServices.updateTPAPartnerConfig({tpaPartnerConfigId: configDetails?.id, ...configDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestImportSessionStatuses = query => async () => {
    const {data, isSuccess} = await apiServices.getImportSessionStatuses(query);

    return {data, isSuccess};
};

export const requestPendingTerminations = query => async () => {
    const {data, isSuccess} = await apiServices.getPendingTerminations(query);
    const {data: pendingTerminations = [], count = 0} = isSuccess ? data : {};

    return {pendingTerminations, count, isSuccess};
};

export const requestPendingTerminationsNotesUpdating = (query, companyMetadataId) => async () => {
    const {data, isSuccess} = await apiServices.updatePendingTerminationsNotes({query, companyMetadataId});

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestPendingTerminationsMembers = query => async () => {
    const {data, isSuccess} = await apiServices.getPendingTerminationsMembers(query);
    const {data: terminatedMembers = [], count = 0} = isSuccess ? data : {};

    return {terminatedMembers, count, isSuccess};
};

export const getImportSessionInitialValues = ({companyAlias, ...values}) => async (dispatch, getState) => {
    const state = getState();
    const {full_name: userFullName} = getProfileInfo(state);
    const {data: company} = await apiServices.getCompanyByAlias({companyAlias});

    return {
        additional_data: {company_name: company?.name || companyAlias, created_by: userFullName},
        company_alias: companyAlias,
        only_new_users: false,
        is_cobra: false,
        ...values
    };
};

export const requestImportsCompanyConfig = companyAlias => async () => {
    const {data, isSuccess} = await apiServices.getImportsCompanyConfig({companyAlias});

    return {data, isSuccess};
};

export const requestImportsCompanyConfigCreating = query => async () => {
    const {data, isSuccess} = await apiServices.createImportsCompanyConfig(query);

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestImportsCompanyConfigUpdating = query => async () => {
    const {data, isSuccess} = await apiServices.updateImportsCompanyConfig(query);

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestVerificationReport = query => async () => {
    const {data, isSuccess} = await apiServices.getVerificationReport(query);

    return {isSuccess, verificationReport: data};
};

export const requestVerificationReportCardLinks = query => async () => {
    const {data = [], isSuccess} = await apiServices.getVerificationReportCardLinks(query);
    const {data: cardLinks = [], count = 0} = isSuccess ? data : {};

    return {isSuccess, count, cardLinks};
};

export const requestVerificationReportCardLinksUpdating = query => async () => {
    const {isSuccess} = await apiServices.updateVerificationReportCardLinks(query);

    return {isSuccess};
};

export const requestVerificationReportEmailsMatchedUpdating = query => async () => {
    const {isSuccess} = await apiServices.updateVerificationReportEmailsMatched(query);

    return {isSuccess};
};

export const requestUsersByCompany = (companyId, query) => async () => {
    const {data, isSuccess} = await apiServices.getCompanyEmployees({companyId, query});
    const users = isSuccess ? data : [];

    return {users, isSuccess};
};
