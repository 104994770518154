import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import {Field} from '../../shared/FormComponents';
import Notation from '../../shared/Notation';
import withEligibilityInfoForm from '../../../HOC/withEligibilityInfoForm';
import {validateRequired} from '../../../utils';
import {APPROXIMATION_OPTIONS} from '../../../options';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    return {
        open_enrollment_due_date: validateRequired(values.open_enrollment_due_date),
        date_clean_eligibility_file: validateRequired(values.date_clean_eligibility_file)
    };
};

const TimingRolloutStep = () => {
    return (
        <Row className='eligibility-wizard-step eligibility-wizard-step_timing-rollout'>
            <Column sm>
                <Row>
                    <Column sm={8}>
                        <Field name='open_enrollment_due_date'>
                            {props => <DatePicker {...props} placeholder='Choose date...' label='Open Enrollment End Date' isRequired wrapperClassName='mb-12'/>}
                        </Field>

                        <Field name='date_clean_eligibility_file'>
                            {props => <DatePicker {...props} placeholder='Choose date...' label='When do you expect to be able to send a clean eligibility file?' isRequired wrapperClassName='mb-12'/>}
                        </Field>

                        <Field name='employees_have_email'>
                            {props => <Select {...props} options={APPROXIMATION_OPTIONS} label='Approximately what % of employees have an email or cell phone listed in the eligibility file?'/>}
                        </Field>
                    </Column>
                </Row>
            </Column>

            <Column constant className='note'>
                <Notation title='Education Tip'>
                    Having good contact information for your employees and their spouses goes a long way to being successful with HealthJoy from the start.
                    If you don’t already have this information, please require an email address and/or cell phone number at enrollment.
                </Notation>
            </Column>
        </Row>
    );
};

export {TimingRolloutStep as TestableTimingRolloutStep};
export default withEligibilityInfoForm({validate})(TimingRolloutStep);
