import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import DropdownWrapper, {DROPDOWN_WRAPPER_ALIGNING} from '@frontend/ui-kit/Components/DropdownWrapper';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {requestZendeskSSO} from '../../../actions/authorization';
import {JWT_ZENDESK_ACCESS_URL, ZENDESK_HELP_CENTER_URL} from '../../../constants';
import './index.scss';

const HelpCenter = () => {
    const dispatch = useDispatch();
    const [SSOToken, setSSOToken] = useState('');

    useEffect(() => {
        (async () => {
            const {data, isSuccess} = await dispatch(requestZendeskSSO());
            const {external_access_token: token} = data || {};

            if (isSuccess) {
                setSSOToken(token);
            }
        })();
    }, [dispatch]);

    const getHelpCenterActionsList = ({onClose}) => {
        const onHelpCenterClick = () => {
            onClose();

            document.getElementById('zendeskSSOForm').submit();
        };

        return (
            <div className='user-actions-list'>
                <div className='user-actions-list__item' onClick={onHelpCenterClick} data-testid='help-center-item'>
                    Help Center
                    <form id='zendeskSSOForm' method='POST' action={`${JWT_ZENDESK_ACCESS_URL}/access/jwt`} style={{display: 'none'}}>
                        <input type='hidden' name='jwt' value={SSOToken}/>
                        <input type='hidden' name='return_to' value={`${ZENDESK_HELP_CENTER_URL}/hc/en-us`}/>
                    </form>
                </div>
            </div>
        );
    };

    const dropdownWrapperProps = {
        className: 'help-center',
        aligning: DROPDOWN_WRAPPER_ALIGNING.right,
        renderDropdownInitiator: () => <Icon className='help-center__icon' type={ICON_TYPES.questionMark}/>
    };

    return (
        <DropdownWrapper {...dropdownWrapperProps}>
            {getHelpCenterActionsList}
        </DropdownWrapper>
    );
};

export {HelpCenter as TestableHelpCenter};
export default React.memo(HelpCenter);
