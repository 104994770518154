import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import Input from '@frontend/ui-kit/Components/Input';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {trimStart} from '../../../utils';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'main_paragraph', label: 'Main Paragraph'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const K401Form = ({card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isFinAccount/>

            <CardHeaderInfo/>

            <CopyableField name='main_paragraph' parse={trimStart}>
                {props => <Textarea {...props} placeholder='Please enter...' label='Main Paragraph' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='display_card_settings.show_employer_contribution'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption='Show Employer Contribution' wrapperClassName='mb-7'/>}
            </CopyableField>

            <CopyableField name='employer_contribution' parse={trimStart}>
                {props => <Input {...props} placeholder='Enter employer contribution...' label='Employer Contribution' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='display_card_settings.show_irs_contribution_limit'>
                {props => <Switcher {...props} className='ancillary-plan-details-form__irs-switcher' caption='Show IRS Contribution Limit'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

K401Form.propTypes = {
    card: PropTypes.string
};

export {K401Form as TestableK401Form};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(K401Form);
