import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import FileUploader from '../../shared/FileUploader';
import CopyableField from '../CopyableField';
import useForm from '../../../hooks/useForm';
import {requestPharmacyBenefitManagers} from '../../../actions/benefits';
import {getPharmacyBenefitManagers} from '../../../selectors/benefits';
import {getEqual, promisifyAsyncFunction, trimStart, validateRequired} from '../../../utils';
import {ACCEPTABLE_IMAGE_FORMATS, DEFAULT_PHONE_PLACEHOLDER, MAX_IMAGE_SIZE} from '../../../constants';

const PHARMACY_BENEFIT_MANAGER_NAME = 'pharmacy_benefit_manager';
const PHARMACY_BENEFIT_MANAGER_LOGO_NAME = 'pbm_logo';
const PHARMACY_BENEFIT_MANAGER_MEMBER_SERVICES_PHONE = 'pbm_member_services_phone';
const PHARMACY_BENEFIT_MANAGER_SUPPORT_PHONE = 'pbm_support_phone';

/* istanbul ignore next */
const validateOnSubmit = values => ({
    [PHARMACY_BENEFIT_MANAGER_NAME]: validateRequired(values[PHARMACY_BENEFIT_MANAGER_NAME]),
    [PHARMACY_BENEFIT_MANAGER_MEMBER_SERVICES_PHONE]: validateRequired(values[PHARMACY_BENEFIT_MANAGER_MEMBER_SERVICES_PHONE]),
    [PHARMACY_BENEFIT_MANAGER_SUPPORT_PHONE]: validateRequired(values[PHARMACY_BENEFIT_MANAGER_SUPPORT_PHONE])
});

const PharmacyBenefitManagerInfo = ({isCustomizedMode}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const pharmacyBenefitManagers = useSelector(getPharmacyBenefitManagers);

    const loadPharmacyBenefitManagerOptions = useCallback(promisifyAsyncFunction(async query => {
        const {pharmacyBenefitManagers} = await dispatch(requestPharmacyBenefitManagers(query));

        return pharmacyBenefitManagers.map(({name: label}) => ({label, value: label}));
    }), [dispatch]);

    const onPharmacyBenefitManagerChange = useCallback(value => {
        const {
            logo = null,
            member_support_phone: memberServicesPhone = null,
            pharmacist_support_phone: supportPhone = null
        } = pharmacyBenefitManagers.find(getEqual(value, 'name')) || {};

        form.batch(() => {
            form.change(PHARMACY_BENEFIT_MANAGER_LOGO_NAME, logo);
            form.change(PHARMACY_BENEFIT_MANAGER_MEMBER_SERVICES_PHONE, memberServicesPhone);
            form.change(PHARMACY_BENEFIT_MANAGER_SUPPORT_PHONE, supportPhone);
        });
    }, [form, pharmacyBenefitManagers]);

    return (
        <React.Fragment>
            <CopyableField name={PHARMACY_BENEFIT_MANAGER_NAME} onChange={onPharmacyBenefitManagerChange}>
                {props => <AsyncAutocomplete {...props} loadOptions={loadPharmacyBenefitManagerOptions} placeholder='Type to select PBM...' label='Pharmacy Benefit Manager (PBM)' isRequired={!isCustomizedMode} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name={PHARMACY_BENEFIT_MANAGER_LOGO_NAME}>
                {props => <FileUploader {...props} isImage accept={ACCEPTABLE_IMAGE_FORMATS} maxSize={MAX_IMAGE_SIZE} size='lg' label='Upload PBM Logo' caption='240px x 50px' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name={PHARMACY_BENEFIT_MANAGER_MEMBER_SERVICES_PHONE} parse={trimStart}>
                {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Member Support Number' isRequired={!isCustomizedMode} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name={PHARMACY_BENEFIT_MANAGER_SUPPORT_PHONE} parse={trimStart}>
                {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Pharmacy Support Number' isRequired={!isCustomizedMode} wrapperClassName='mb-12'/>}
            </CopyableField>
        </React.Fragment>
    );
};

PharmacyBenefitManagerInfo.propTypes = {
    isCustomizedMode: PropTypes.bool
};

PharmacyBenefitManagerInfo.defaultProps = {
    isCustomizedMode: false
};

export {validateOnSubmit, PharmacyBenefitManagerInfo as TestablePharmacyBenefitManagerInfo};
export default React.memo(PharmacyBenefitManagerInfo);
