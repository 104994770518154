import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import {equal} from '../../../../utils';

const NOTIFICATION_TYPES = {
    delete: 'delete',
    cancel: 'cancel'
};

const PopupActionBar = ({category, onCancel, onSaveAndContinue, onSaveAndClose, onDelete, dirty}) => {
    const [isNotification, setIsNotification] = useState(false);
    const onCancelButtonClick = () => dirty && category
        ? setIsNotification(NOTIFICATION_TYPES.cancel)
        : onCancel();

    return (
        <div className='ancillary-info-popup-action-bar'>
            <div className='action-bar'>
                {onDelete && (
                    <Button onClick={() => setIsNotification(NOTIFICATION_TYPES.delete)} type={BUTTON_TYPES.destructive} disabled={isNotification}><Icon className='delete-icon' type={ICON_TYPES.delete}/></Button>
                )}
                <Button type={BUTTON_TYPES.secondary} onClick={onCancelButtonClick} disabled={isNotification}>Cancel</Button>
                {onSaveAndContinue && (
                    <Button type={BUTTON_TYPES.secondary} onClick={onSaveAndContinue} disabled={isNotification}>Save and Continue Editing</Button>
                )}
                {onSaveAndClose && (
                    <Button type={BUTTON_TYPES.primary} onClick={onSaveAndClose} disabled={isNotification}>Save and Close</Button>
                )}
            </div>

            {equal(isNotification, NOTIFICATION_TYPES.delete) && (
                <div className='remove-plan mt-15'>
                    <div className='remove-plan__heading'>
                        <Icon type={ICON_TYPES.alert} className='remove-plan__icon mr-2'/>
                        <Heading type={HEADING_TYPES['5']}>Are you sure you want to remove the plan configuration associated with {category}?</Heading>
                    </div>

                    <Text className='mt-5'>Please note deleting this plan configuration will remove all associated saved information and settings.</Text>

                    <div className='remove-plan__action-bar mt-15'>
                        <Button type={BUTTON_TYPES.secondary} onClick={() => setIsNotification(false)}>Cancel</Button>
                        <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Yes, Delete</Button>
                    </div>
                </div>
            )}

            {equal(isNotification, NOTIFICATION_TYPES.cancel) && (
                <div className='cancel-plan mt-15'>
                    <div className='cancel-plan__heading'>
                        <Icon type={ICON_TYPES.alert} className='cancel-plan__icon mr-2'/>
                        <Heading type={HEADING_TYPES['5']}>Are you sure you want to leave without saving?</Heading>
                    </div>

                    <Text className='mt-5'>You have unsaved changes and and will lose any information if you leave without saving.</Text>

                    <div className='cancel-plan__action-bar mt-15'>
                        <Button type={BUTTON_TYPES.secondary} onClick={onCancel}>Leave Without Saving</Button>
                        <Button onClick={() => setIsNotification(false)}>Stay on Details</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

PopupActionBar.propTypes = {
    category: PropTypes.string,
    dirty: PropTypes.bool,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onSaveAndContinue: PropTypes.func,
    onSaveAndClose: PropTypes.func
};

export default PopupActionBar;
