import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Text from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import RenewalPlanInfoAlert from '../RenewalPlanInfoAlert';
import {getIsSuperAdmin, getPlanPeriodTypeCriteria} from '../../../selectors/general';
import {equal} from '../../../utils';
import {PLAN_PERIOD_TYPES, CORE_PLAN_TYPES, ROUTES} from '../../../constants';
import './index.scss';

const CoreBenefitsStepHeader = ({planType}) => {
    const {planPeriodId} = useParams();
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const isRenewalPlanPeriod = useSelector(state => getPlanPeriodTypeCriteria(state, planPeriodId, PLAN_PERIOD_TYPES.renewal));
    const stepDescription = !equal(planType, CORE_PLAN_TYPES.additional)
        ? `Enable the button below if you offer a ${planType} plan. Otherwise click Save and Continue.`
        : `
            Does your plan have any of the below custom traits for our service team to know?
            Select all that apply to your plan, and upload any relevant document if there is one.
            If none of these apply, click Save and Continue.
        `;

    return (
        <div className='core-benefits-step-header'>
            <Text className='core-benefits-step-header__text'>{stepDescription}</Text>

            <div className='step-info'>
                {isSuperAdmin && <Link className='step-info__link' href={ROUTES.planManager}>Visit Plan Manager</Link>}
                {isRenewalPlanPeriod && <RenewalPlanInfoAlert className='step-info__alert'/>}
            </div>
        </div>
    );
};

CoreBenefitsStepHeader.propTypes = {
    planType: PropTypes.string
};

export {CoreBenefitsStepHeader as TestableCoreBenefitsStepHeader};
export default React.memo(CoreBenefitsStepHeader);
