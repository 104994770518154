import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ContactsBoard from '../../../Components/ContactsAndCompany/ContactsBoard';

const PAGE_HEADER_CAPTION = 'Create contacts for whoever will be supporting your implementation or should view program performance once you launch. Each person will receive an invitation to the dashboard.';

const Contacts = () => (
    <React.Fragment>
        <Row>
            <Column sm={9}>
                <PageHeader title='Your Contacts' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <ContactsBoard/>
    </React.Fragment>
);

export {Contacts as TestableContacts};
export default React.memo(Contacts);
