import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import NewContactInfoForm from '../../../Components/ContactsAndCompany/NewContactInfoForm';
import ExistedContactInfoForm from '../../../Components/ContactsAndCompany/ExistedContactInfoForm';
import RolePermissions from '../../../Components/ContactsAndCompany/RolePermissions';
import {parseQuery} from '../../../utils';

const ContactInfo = ({location}) => {
    const {userType: type, userId: id} = parseQuery(location.search);

    return (
        <React.Fragment>
            <Row>
                <Column sm={9}>
                    <PageHeader title='Your Contacts' caption='Confirm, edit or add company overview information below.'/>
                </Column>
            </Row>
            <Row>
                <Column sm>
                    {id ? <ExistedContactInfoForm type={type} id={id}/> : <NewContactInfoForm type={type}/>}
                </Column>

                <Column constant>
                    <RolePermissions/>
                </Column>
            </Row>
        </React.Fragment>
    );
};

ContactInfo.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string
    })
};

export default React.memo(ContactInfo);
