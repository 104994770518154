import React, {useMemo} from 'react';
import MarkupEditorField from '@frontend/ui-kit/Components/MarkupEditorField';
import './index.scss';

const ShadowCustomizableInput = props => {
    const enhancedOptions = useMemo(() => ({toolbar: false, disableExtraSpaces: true}), []);

    return <MarkupEditorField className='shadow-customizable-input' options={enhancedOptions} isMultiline={false} {...props}/>;
};

export {ShadowCustomizableInput as TestableShadowCustomizableInput};
export default React.memo(ShadowCustomizableInput);
