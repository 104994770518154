import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import EligibilityImportsSearch from '../EligibilityImportsSearch';
import UploadCobraFilePopup from './UploadCobraFilePopup';
import AutomationStatusDetailsPopup from './AutomationStatusDetailsPopup';
import CustomTerminationsThresholds from '../CustomTerminationsThresholds';
import ImportConfigVersions from '../ImportConfigVersions';
import withPopup from '../../../HOC/withPopup';
import {redirectTo, replaceTo, requestCompanyByAlias, requestFeatureFlag} from '../../../actions/general';
import {
    requestAppConfiguration,
    requestEligibilityImportSessionErrors,
    requestIndividualConfig,
    requestPendingTerminationsMembers
} from '../../../actions/adminPortal';
import {getProfileInfo} from '../../../selectors/general';
import {equal, formatDate, isEmpty, parseQuery, stringifyQueryParams} from '../../../utils';
import {getImportThresholdsMassMembersText, getIndividualConfig} from '../../../helpers';
import {
    AUTOIMPORT_CREATED_BY,
    COMPANY_STAGES,
    FEATURE_FLAGS,
    HOUSTON_URL,
    PLATFORM_AUTOMATION_TYPES,
    ROUTES
} from '../../../constants';
import './index.scss';

const POPUP_ID = 'individualGroupDetailsPopup';

const AUTOMATION_STATUSES = {
    fully_automated: 'fully_automated',
    semi_automated: 'semi_automated',
    on_hold: 'on_hold',
    not_updated: 'not_updated',
    manual: 'manual'
};

const AUTOMATION_STATUSES_BY_PLATFORM_TYPES = {
    [PLATFORM_AUTOMATION_TYPES['SFTP Standard - Fully Automated']]: {
        label: 'Fully Automated',
        status: AUTOMATION_STATUSES.fully_automated
    },
    [PLATFORM_AUTOMATION_TYPES['SFTP Standard - Semi-Automated']]: {
        label: 'Semi-Automated',
        status: AUTOMATION_STATUSES.semi_automated
    },
    [PLATFORM_AUTOMATION_TYPES['SFTP Custom']]: {
        label: 'Manual-Not Applicable',
        status: AUTOMATION_STATUSES.manual
    },
    [PLATFORM_AUTOMATION_TYPES['Non-SFTP Standard']]: {
        label: 'Manual-Not Applicable',
        status: AUTOMATION_STATUSES.manual
    },
    [PLATFORM_AUTOMATION_TYPES['Non-SFTP Custom']]: {
        label: 'Manual-Not Applicable',
        status: AUTOMATION_STATUSES.manual
    },
    [PLATFORM_AUTOMATION_TYPES['On Hold']]: {
        label: 'On Hold',
        status: AUTOMATION_STATUSES.on_hold
    }
};

const IndividualGroupDetails = ({openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {group_alias: groupAlias} = useParams();
    const {search, pathname} = useLocation();
    const {email: userEmail} = useSelector(getProfileInfo);
    const [company, setCompany] = useState({});
    const [importConfig, setImportConfig] = useState({});
    const [renewalConfig, setRenewalConfig] = useState({});
    const [platformAutomationType, setPlatformAutomationType] = useState(null);
    const {label: automationLabel, status: automationStatus} = AUTOMATION_STATUSES_BY_PLATFORM_TYPES[platformAutomationType] || {};
    const [isGroupLaunched, setIsGroupLaunched] = useState(false);
    const [isTerminatedMembers, setIsTerminatedMembers] = useState(false);
    const [isTermination, setIsTermination] = useState(false);
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [isAutoimport, setIsAutoimport] = useState(false);
    const {show_submit_alert: showSubmitAlert, show_cobra_submit_alert: showCobraSubmitAlert} = parseQuery(search);
    const [lastSession, setLastSession] = useState(null);
    const [isAutoImportBlockersErrors, setIsAutoImportBlockersErrors] = useState(false);
    const [autoImportThresholdsText, setAutoImportThresholdsText] = useState('');

    useEffect(() => {
        (async () => {
            const [
                {data: company},
                {data: {group_launched: groupLaunched}},
                {data: configs},
                {count},
                {flag: isTermination},
                {flag: isMaintenance},
                {flag: isAutoimport},
                {data: sftpConfig}
            ] = await Promise.all([
                dispatch(requestCompanyByAlias(groupAlias)),
                dispatch(requestAppConfiguration({companyAlias: groupAlias, type: 'platform'})),
                dispatch(requestIndividualConfig({companyAlias: groupAlias})),
                dispatch(requestPendingTerminationsMembers({alias: groupAlias, limit: 0, offset: 0})),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.terminationSurgeVisibility, {userEmail})),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.maintenanceProcessing, {userEmail})),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.autoimportsSchedulerV2, {userEmail})),
                dispatch(requestAppConfiguration({companyAlias: groupAlias, type: 'sftp'}))
            ]);
            const [renewalConfig, config] = [true, false].map(value => getIndividualConfig(value, configs));

            setImportConfig(config);
            setRenewalConfig(renewalConfig);
            setIsGroupLaunched(groupLaunched);
            setCompany(company);
            setIsTerminatedMembers(count > 0);
            setIsTermination(isTermination);
            setIsMaintenance(isMaintenance);
            setIsAutoimport(isAutoimport);
            setPlatformAutomationType(sftpConfig?.automation_status);
        })();
    }, [dispatch, groupAlias, userEmail]);

    const requestSessionErrors = async sessionId => {
        const {data} = await dispatch(requestEligibilityImportSessionErrors(sessionId));

        setIsAutoImportBlockersErrors(!isEmpty(data?.blockers));
    };

    useEffect(() => {
        const {id: sessionId, additional_data: additionalData} = lastSession || {};

        if (sessionId && equal(additionalData?.created_by, AUTOIMPORT_CREATED_BY)) {
            requestSessionErrors(lastSession?.id);
            setAutoImportThresholdsText(getImportThresholdsMassMembersText(lastSession?.thresholds?.mass_data_changes));
        }
    }, [lastSession]);

    const onUploadCobraFile = () => openPopup({type: POPUP_TYPES.simple, children: <UploadCobraFilePopup closeParentPopup={closePopup} importConfigId={importConfig?.id} filePattern={importConfig?.cobra_file_pattern}/>});

    const onOpenAutomationStatusDetails = () => openPopup({type: POPUP_TYPES.simple, children: <AutomationStatusDetailsPopup automationStatus={automationLabel}/>});

    const onDismissSubmitAlert = param => {
        const {[param]: _, ...restSearch} = parseQuery(search);
        const enhancedSearch = stringifyQueryParams(restSearch);

        dispatch(replaceTo(`${pathname}?${enhancedSearch}`));
    };

    const onGoToConfig = () => {
        const {isRenewal, version, show_submit_alert, show_cobra_submit_alert, ...restSearch} = parseQuery(search);
        const enhancedSearch = stringifyQueryParams(restSearch);

        dispatch(redirectTo(`${ROUTES.importsIndividual}/${groupAlias}/group_config?${enhancedSearch}#configuration`));
    };

    const onRenewalConfigClick = () => {
        const {isRenewal, version, show_submit_alert, show_cobra_submit_alert, ...restSearch} = parseQuery(search);
        const enhancedSearch = stringifyQueryParams({...restSearch, isRenewal: true});

        dispatch(redirectTo(`${ROUTES.importsIndividual}/${groupAlias}/group_config?${enhancedSearch}#configuration`));
    };

    const onViewPendingTerminations = () => dispatch(redirectTo(`${ROUTES.eligibilityImports}#pending_terminations`));

    return (
        <div className='individual-group-details'>
            {isTermination && isTerminatedMembers && <Alert className='mb-20' type={ALERT_TYPES.warning} description={(<span>Terminations threshold surge met. <span onClick={onViewPendingTerminations} className='view-pending-terminations'>View members in pending terminations.</span></span>)}/>}

            {showSubmitAlert && (
                <Alert onClose={() => onDismissSubmitAlert('show_submit_alert')} className='mb-20' type={ALERT_TYPES.success} description='Import has been submit. Track its upload progress in the Import Log below.'/>
            )}

            {showCobraSubmitAlert && (
                <Alert onClose={() => onDismissSubmitAlert('show_cobra_submit_alert')} className='mb-20' type={ALERT_TYPES.success} description='COBRA Import has been submit. Track its upload progress in the Import Log below.'/>
            )}

            {isAutoImportBlockersErrors && (
                <Alert className='mb-20' type={ALERT_TYPES.danger} description={(<span>The auto-import encountered blocker errors and these members were not processed. Review the <Link type={BUTTON_TYPES.tertiary} href={`${ROUTES.importsIndividual}/${groupAlias}/validation_report/${lastSession?.id}?group_name=${encodeURIComponent(parseQuery(search).group_name)}#file_errors`}>blockers report</Link> for these members and take action accordingly.</span>)}/>
            )}

            {!isEmpty(autoImportThresholdsText) && (
                <Alert className='mb-20' type={ALERT_TYPES.danger} description={(<span>Automated import has met {autoImportThresholdsText} threshold and the file was not imported. Please check the <Link type={BUTTON_TYPES.tertiary} href={`${ROUTES.importsIndividual}/${groupAlias}/validation_report/${lastSession?.id}?group_name=${encodeURIComponent(parseQuery(search).group_name)}#thresholds`}>validation report</Link> to review thresholds.</span>)}/>
            )}

            {equal(company?.activity_stage, COMPANY_STAGES.renewal) && !isEmpty(renewalConfig) && (
                !renewalConfig?.updated_by || equal(renewalConfig?.updated_by, 'system') ? (
                    <Alert className='mb-20' type={ALERT_TYPES.info} description={`60-day ♻️ Renewal period started. Please configure before ${formatDate(renewalConfig?.config_activation_date, 'MM/dd/yyyy')}.`}>
                        <Button className='configure-renewal' onClick={onRenewalConfigClick} type={BUTTON_TYPES.tertiary} iconLeft={<Icon type={ICON_TYPES.edit}/>}>Configure Renewal</Button>
                    </Alert>
                ) : (
                    <Alert className='mb-20' type={ALERT_TYPES.success} description={`Renewal config saved. Renewal date is ${formatDate(renewalConfig?.config_activation_date, 'MM/dd/yyyy')}. After this date, the renewal config will be become the maintenance config.`}>
                        <Button className='configure-renewal' onClick={onRenewalConfigClick} type={BUTTON_TYPES.tertiarySuccess} iconLeft={<Icon type={ICON_TYPES.edit}/>}>View or Update</Button>
                    </Alert>
                )
            )}

            <Row>
                <Column sm>
                    <Heading type={HEADING_TYPES['3']} className='mb-5'>{company?.name}</Heading>
                    <Heading type={HEADING_TYPES['1']} className='mb-20'>{groupAlias}</Heading>
                </Column>
                <Column constant className='pr-5 pl-10'>
                    <ImportConfigVersions/>
                    {isMaintenance
                        ? importConfig?.cobra_in_separate_file && <Button onClick={onUploadCobraFile} type={BUTTON_TYPES.secondary}>Upload Cobra File</Button>
                        : (
                            <React.Fragment>
                                {importConfig?.cobra_in_separate_file && !isGroupLaunched && <Button onClick={onUploadCobraFile} type={BUTTON_TYPES.secondary}>Upload Cobra File</Button>}
                                {importConfig?.cobra_in_separate_file && isGroupLaunched && <Link href={`${HOUSTON_URL}/admin/import_users/`} isButton type={BUTTON_TYPES.secondary} target='_blank'>Upload COBRA File in Houston</Link>}
                            </React.Fragment>
                        )}
                    <Button onClick={onGoToConfig} className='ml-10'>{isGroupLaunched ? 'Maintain Config' : 'Configure Group'}</Button>
                </Column>
            </Row>

            <Row className='mb-20'>
                {company?.address && (
                    <Column sm={3}>
                        <ContentSection className='group-info'>
                            <Text type={TEXT_TYPES.helper} className='mb-2'>Group Address</Text>
                            <Text>{company?.address}</Text>
                        </ContentSection>
                    </Column>
                )}
                {isAutoimport && automationStatus && (
                    <Column sm={3}>
                        <ContentSection className='group-info'>
                            <Row middle='xs'>
                                <Column xs>
                                    <Text type={TEXT_TYPES.helper} className='mb-2'>Automation Status</Text>
                                    <Sticker className='mt-7' type={STICKER_TYPES.default} isMultiline>{automationLabel}</Sticker>
                                </Column>
                                {!equal(automationStatus, AUTOMATION_STATUSES.manual) && (
                                    <Column constant>
                                        <Button onClick={onOpenAutomationStatusDetails} type={BUTTON_TYPES.tertiary}>View Details</Button>
                                    </Column>
                                )}
                            </Row>
                        </ContentSection>
                    </Column>
                )}
                <Column sm={6}>
                    <CustomTerminationsThresholds groupAlias={groupAlias}/>
                </Column>
            </Row>

            <ContentSection>
                <EligibilityImportsSearch groupAlias={groupAlias} getLastSession={setLastSession}/>
            </ContentSection>
        </div>
    );
};

IndividualGroupDetails.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {IndividualGroupDetails as TestableIndividualGroupDetails};
export default withPopup(POPUP_ID)(IndividualGroupDetails);
