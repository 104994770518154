import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import CopyableField from '../CopyableField';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import DocumentUploader from '../DocumentUploader';
import {formatList, normalizeList, trimStart} from '../../../utils';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';

const POLICY_HOLDER_TOOLTIP_CONTENT = 'If you’re unsure, most of the time this is the Client’s name.';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'main_paragraph', label: 'Main Paragraph'},
    {name: 'policy_holder', label: 'Policyholder'},
    {name: 'group_policy', label: 'Group Policy'},
    {name: 'card_list', label: 'List'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const AccidentForm = ({card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo/>

            <CardHeaderInfo/>

            <CopyableField name='main_paragraph' parse={trimStart}>
                {props => <Textarea {...props} placeholder='Please enter...' label='Main Paragraph' wrapperClassName='mb-12'/>}
            </CopyableField>

            <Text type={TEXT_TYPES.bodyBold} className='ancillary-plan-details-form__title'>Member Information</Text>

            <CopyableField name='policy_holder' parse={trimStart}>
                {props => <Input {...props} placeholder='Please enter' label={<LabeledTooltip title='Policyholder' content={POLICY_HOLDER_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='group_policy' parse={trimStart}>
                {props => <Input {...props} placeholder='Please enter' label='Group Policy' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='card_list' parse={normalizeList} format={formatList}>
                {props => <Textarea {...props} placeholder='Separate list items by hitting return' label='List'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

AccidentForm.propTypes = {
    card: PropTypes.string
};

export {AccidentForm as TestableAccidentForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(AccidentForm);
