import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import {Form, Field} from '../../shared/FormComponents';
import AssignableActionBar from '../../shared/AssignableActionBar';
import withCoreBenefitsStepLayout from '../../../HOC/withCoreBenefitsStepLayout';
import {requestMedicalTraits, requestMedicalTraitsSetting} from '../../../actions/benefits';
import {validateRequired} from '../../../utils';
import {FORMS, MEDICAL_TRAIT_LABEL_BY_TYPE} from '../../../constants';
import {VENDOR_OPTIONS, ANALYTIC_OPTIONS} from '../../../options';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const {is_vendor: isVendor, is_platform: isPlatform} = values;

    return {
        reference_based_pricing_vendor: isVendor ? validateRequired(values.reference_based_pricing_vendor) : undefined,
        claims_analytics_platform: isPlatform ? validateRequired(values.claims_analytics_platform) : undefined
    };
};

const MedicalTraitsStep = ({...props}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const {planPeriodId} = useParams();

    const resetFields = useCallback((form, fields) => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, []);

    const onSubmit = useCallback(async values => {
        const {is_vendor, is_platform, ...medicalTraits} = values; // FYI: is_vendor/is_platform are used only on the frontend, they are not needed on the backend (Vlad, 22.09.20)

        const {isSuccess, submissionErrors} = await dispatch(requestMedicalTraitsSetting(planPeriodId, medicalTraits));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(values);
    }, [dispatch, planPeriodId]);

    useEffect(() => {
        (async () => {
            const {medicalTraits} = await dispatch(requestMedicalTraits(planPeriodId));
            setInitialValues(medicalTraits);
        })();
    }, [planPeriodId, dispatch]);

    return (
        <Form name={FORMS.medicalTraitsStep} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, form, values}) => {
                const {is_vendor: isVendor, is_platform: isPlatform} = values;
                const actionBarProps = {...props, onSubmitStep: handleSubmit};

                return (
                    <React.Fragment>
                        <Row className='benefits-wizard-step medical-traits-step'>
                            <Column className='medical-traits-step__column' sm={6}>
                                <Field name='on_site_clinics'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.on_site_clinics}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='in_house_pharmacies'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.in_house_pharmacies}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='in_house_providers'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.in_house_providers}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='direct_contracted_providers'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.direct_contracted_providers}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='multiple_networks'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.multiple_networks}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='specialty_surgical_program'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.specialty_surgical_program}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='specialty_rx'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.specialty_rx}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='tpa_onboarding_worksheet'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.tpa_onboarding_worksheet}/>}</Field>
                                <Separator className='medical-traits-step__separator'/>

                                <Field name='all_other_plan_documents'>{props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.all_other_plan_documents}/>}</Field>
                            </Column>

                            <Column className='medical-traits-step__column' sm={6}>
                                <Field name='is_vendor' onChange={resetFields(form, ['reference_based_pricing_vendor'])}>
                                    {props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.reference_based_pricing_vendor} wrapperClassName='mb-5'/>}
                                </Field>

                                <Field name='reference_based_pricing_vendor'>
                                    {props => <Select {...props} options={VENDOR_OPTIONS} disabled={!isVendor} placeholder='Please select...' className='medical-traits-step__select' label='Who is Your Vendor?'/>}
                                </Field>

                                <Separator className='medical-traits-step__separator'/>

                                <Field name='is_platform' onChange={resetFields(form, ['claims_analytics_platform'])}>
                                    {props => <Switcher {...props} caption={MEDICAL_TRAIT_LABEL_BY_TYPE.claims_analytics_platform} wrapperClassName='mb-5'/>}
                                </Field>

                                <Field name='claims_analytics_platform'>
                                    {props => <Select {...props} options={ANALYTIC_OPTIONS} disabled={!isPlatform} placeholder='Please select...' className='medical-traits-step__select' label='What Is Your Platform?'/>}
                                </Field>
                            </Column>
                        </Row>

                        <AssignableActionBar {...actionBarProps}/>
                    </React.Fragment>
                );
            }}
        </Form>
    );
};

export {MedicalTraitsStep as TestableMedicalTraitsStep};
export default withCoreBenefitsStepLayout(MedicalTraitsStep);
