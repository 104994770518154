import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Row from '@frontend/ui-kit/Components/Row';
import {Form} from '../../shared/FormComponents';
import Telemedicine from '../AppConfigurationContents/Telemedicine';
import MedicalBillReview from '../AppConfigurationContents/MedicalBillReview';
import SurgeryPlus, {validate as validateSurgeryPlus} from '../AppConfigurationContents/SurgeryPlus';
import EmployeeAssistanceProgram from '../AppConfigurationContents/EmployeeAssistanceProgram';
import BehavioralHealth from '../AppConfigurationContents/BehavioralHealth';
import Musculoskeletal from '../AppConfigurationContents/Musculoskeletal';
import TeladocChronicCare, {validate as validateTeladocChronicCare} from '../AppConfigurationContents/TeladocChronicCare';
import TeladocMentalHealth, {validate as validateTeladocMentalHealth} from '../AppConfigurationContents/TeladocMentalHealth';
import TeladocPrimary360, {validate as validateTeladocPrimary360} from '../AppConfigurationContents/TeladocPrimary360';
import TeladocGeneralMedicalConfig, {validate as validateTeladocGeneralMedicalConfig} from '../AppConfigurationContents/TeladocGeneralMedicalConfig';
import TeladocTherapy, {validate as validateTeladocTherapy} from '../AppConfigurationContents/TeladocTherapy';
import Platform, {validate as validatePlatform} from '../AppConfigurationContents/Platform';
import SFTP, {validate as validateSFTP} from '../AppConfigurationContents/SFTP';
import SoftLaunch from '../AppConfigurationContents/SoftLaunch';
import AppConfigurationStatusSticker from '../AppConfigurationStatusSticker';
import StickyActionSection from '../../shared/StickyActionSection';
import {redirectTo} from '../../../actions/general';
import {requestAppConfiguration, requestAppConfigurations, requestAppConfigurationUpdating} from '../../../actions/adminPortal';
import {getDefaultSFTPCredentialsInfo} from '../../../helpers';
import {equal, getEqual, getRegisteredFieldsValues, isEmpty, isObjectHasProps} from '../../../utils';
import {APP_CONFIG_STATUSES, FORMS, ROUTES} from '../../../constants';
import './index.scss';

const TELADOC_SEGMENTS_ALERT_TEXT = `
    This group has a grandfathered Teladoc fee structure, and will show different fees for their various segments.
    If changes to the fees are needed, please submit an SE ticket requesting technical assistance.
`;

const ADDONS = {
    telemedicine: {Component: Telemedicine, heading: 'Walmart Health / UCM Telemedicine'},
    medical_bill_review: {Component: MedicalBillReview, heading: 'Medical Bill Review'},
    eap: {Component: EmployeeAssistanceProgram, heading: 'Employee Assistance Program (EAP)'},
    behavioral_health: {Component: BehavioralHealth, heading: 'Walmart Health Behavioral Health'},
    msk: {Component: Musculoskeletal, heading: 'Musculoskeletal (MSK)'},
    surgery_plus: {Component: SurgeryPlus, heading: 'SurgeryPlus', validate: validateSurgeryPlus},
    teladoc_chronic_care: {Component: TeladocChronicCare, heading: 'Teladoc Chronic Care', validate: validateTeladocChronicCare},
    teladoc_mental_health: {Component: TeladocMentalHealth, heading: 'Teladoc Mental Health', validate: validateTeladocMentalHealth},
    teladoc_primary360: {Component: TeladocPrimary360, heading: 'Teladoc Health Primary360', validate: validateTeladocPrimary360},
    teladoc_general_medical: {Component: TeladocGeneralMedicalConfig, heading: 'Teladoc General Medical', validate: validateTeladocGeneralMedicalConfig},
    teladoc_therapy: {Component: TeladocTherapy, heading: 'Teladoc Therapy', validate: validateTeladocTherapy},
    platform: {Component: Platform, heading: 'Platform', validate: validatePlatform},
    sftp: {Component: SFTP, heading: 'SFTP Configurations', validate: validateSFTP},
    soft_launch: {Component: SoftLaunch, heading: 'Pre-launch Demo Access'}
};

const AppConfigurationDetailsForm = () => {
    const dispatch = useDispatch();
    const {companyAlias, type: addonType} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [isCompanyActive, setIsCompanyActive] = useState(false);
    const [companyName, setCompanyName] = useState(null);
    const [isConsultFeeSeparatedBySegment, setIsConsultFeeSeparatedBySegment] = useState(null);
    const {Component, heading, validate} = ADDONS[addonType] || {};
    const isTeladoc = useMemo(() => {
        return ['teladoc_chronic_care', 'teladoc_mental_health', 'teladoc_primary360', 'teladoc_general_medical', 'teladoc_therapy'].includes(addonType);
    }, [addonType]);

    const redirectToConfigurationList = useCallback(({toastContent}) => {
        const queryParams = toastContent ? `?toast_content=${toastContent}` : '';

        dispatch(redirectTo(`${ROUTES.appConfiguration}${queryParams}`));
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestAppConfiguration({companyAlias, type: addonType}));
            const [isSFTP, isSurgeryPlus] = ['sftp', 'surgery_plus'].map(getEqual(addonType));
            const HSAConsultFeeData = {hsa_consult_fee: data.hsa_consult_fee ?? 'N/A'};
            const enhancedData = {
                ...data,
                ...(isObjectHasProps(data, ['hsa_consult_fee']) && HSAConsultFeeData),
                ...(isSurgeryPlus && isEmpty(data.consult_fees) && {
                    consult_fees: [{}]
                }),
                ...(isSFTP && {
                    // FYI: settings field may be missed because of creation of empty credentials and linking them to company before form is saved (10.07.2023, Oleh)
                    // FYI: also settings field may be cut because of migration from old SFTP (10.07.2023, Oleh)
                    creds: data.creds?.map(({host_type, settings, ...fields}) => {
                        const {settings: defaultSettings} = getDefaultSFTPCredentialsInfo(host_type);

                        return {
                            ...fields,
                            host_type,
                            settings: equal(settings.length, defaultSettings.length)
                                ? settings
                                : defaultSettings.map(defaultSettingsItem => {
                                    const settingsItem = settings.find(getEqual(defaultSettingsItem.type, 'type'));

                                    return settingsItem ?? defaultSettingsItem;
                                })
                        };
                    })
                })
            };

            setIsConsultFeeSeparatedBySegment(data.separate_consult_fees_by_segment);
            setInitialValues(enhancedData);
        })();
    }, [dispatch, addonType, companyAlias]);

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestAppConfigurations(companyAlias));

            setIsCompanyActive(data.is_active);
            setCompanyName(data.title);
        })();
    }, [dispatch, companyAlias]);

    const onSubmit = useCallback(async (values, {getRegisteredFields}) => {
        let config = getRegisteredFieldsValues(getRegisteredFields(), values);

        if (addonType === 'sftp') {
            config = {
                ...config,
                creds: config.creds?.map(credentialsInfo => {
                    // FYI: password_updated_at field is readonly (10.07.2023, Oleh)
                    return {...credentialsInfo, password_updated_at: undefined};
                })
            };
        }

        const {isSuccess, submissionErrors, data} = await dispatch(requestAppConfigurationUpdating({companyAlias, type: addonType, config}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
    }, [addonType, companyAlias, dispatch]);
    const onSubmitSuccess = useCallback(() => redirectToConfigurationList({toastContent: 'Changes saved!'}), [redirectToConfigurationList]);

    return (
        <Form name={FORMS.appConfiguration} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, values, dirty}) => {
                const status = isCompanyActive ? values.status?.toLowerCase() : APP_CONFIG_STATUSES.terminated;
                const isEditDisabled = [APP_CONFIG_STATUSES.terminated, APP_CONFIG_STATUSES.inactive, APP_CONFIG_STATUSES.completed].includes(status);
                const actionBar = (
                    <React.Fragment>
                        <Button type={BUTTON_TYPES.secondary} onClick={redirectToConfigurationList}>Close</Button>
                        {!isEditDisabled && <Button disabled={!dirty} onClick={handleSubmit} type={BUTTON_TYPES.primary}>Save</Button>}
                    </React.Fragment>
                );

                return (
                    <form onSubmit={handleSubmit} className='app-configuration' noValidate>
                        {isTeladoc && isConsultFeeSeparatedBySegment && (
                            <Alert className='mb-12' type={ALERT_TYPES.warning} title='PLEASE NOTE' description={TELADOC_SEGMENTS_ALERT_TEXT}/>
                        )}

                        <Row>
                            <Column sm>
                                <Component isEditDisabled={isEditDisabled} status={status}/>
                            </Column>

                            <Column constant>
                                <StickyActionSection title={heading} actionBar={actionBar}>
                                    <Text className='mb-10'>
                                        For <Text type={TEXT_TYPES.bodyBold} isInline>{companyName}</Text>
                                    </Text>
                                    {status && <AppConfigurationStatusSticker status={status}/>}
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

export {AppConfigurationDetailsForm as TestableAppConfigurationDetailsForm};
export default AppConfigurationDetailsForm;
