import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {Form, Field} from '../../shared/FormComponents';
import ContactDeletionPopup from '../ContactDeletionPopup';
import withPopup from '../../../HOC/withPopup';
import {redirectTo} from '../../../actions/general';
import {requestContactDeletion, requestContactInvitation, requestUserValidation} from '../../../actions/contacts';
import {getActiveCompany} from '../../../selectors/general';
import {getEqual, formatDate, validateEmail, validateRequired, equal, isEmail} from '../../../utils';
import {ROUTES, USER_TYPES, USER_ROLES, DEFAULT_PHONE_PLACEHOLDER} from '../../../constants';
import {BASIC_USER_ROLE_OPTIONS, USER_ROLE_OPTIONS} from '../../../options';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import './index.scss';

const OTHER_USER_TYPE_ROLES = [USER_ROLES.vendorContributor, USER_ROLES.zendesk];

const ROLE_OPTIONS_BY_TYPE = {
    [USER_TYPES.employee]: BASIC_USER_ROLE_OPTIONS,
    [USER_TYPES.broker]: BASIC_USER_ROLE_OPTIONS,
    [USER_TYPES.other]: USER_ROLE_OPTIONS.filter(({value}) => OTHER_USER_TYPE_ROLES.some(getEqual(value)))
};

const POPUP_ID = 'contactDeletionPopup';

const USER_ROLE_VALIDATION_MESSAGE = 'The email is already used for another role';

/* istanbul ignore next */
export const validate = isUserRoleAvailable => values => ({
    first_name: validateRequired(values.first_name),
    last_name: validateRequired(values.last_name),
    email: validateEmail(values.email) || (!isUserRoleAvailable ? USER_ROLE_VALIDATION_MESSAGE : undefined),
    phone: validateRequired(values.phone),
    role: validateRequired(values.role) || (!isUserRoleAvailable ? USER_ROLE_VALIDATION_MESSAGE : undefined),
    title: validateRequired(values.title)
});

const ContactInfoBasicForm = ({id, type, formName, isExisted, initialValues, openPopup, onSubmit, onSubmitSuccess}) => {
    const [isUserRoleAvailable, setIsUserRoleAvailable] = useState(true);
    const dispatch = useDispatch();
    const {name: companyName, alias: companyAlias} = useSelector(getActiveCompany);
    const roleOptions = ROLE_OPTIONS_BY_TYPE[type];
    const isInitialZendeskRole = equal(initialValues.role, USER_ROLES.zendesk);

    const requestUserRoleAvailable = useCallback(async (email, role) => {
        const {isValid, isSuccess} = await dispatch(requestUserValidation({email, role, company_alias: companyAlias}));

        if (!isSuccess) {
            return false;
        }

        setIsUserRoleAvailable(isValid);
    }, [companyAlias, dispatch]);

    const goToBoard = () => dispatch(redirectTo(ROUTES.contacts));

    const reinvite = useCallback(async form => {
        const {isSuccess} = await dispatch(requestContactInvitation(id));

        if (!isSuccess) {
            return false;
        }

        form.restart();
        goToBoard();
    }, []);

    const deleteContact = useCallback(form => {
        const onPopupDelete = async () => {
            const {isSuccess} = await dispatch(requestContactDeletion(id));

            if (!isSuccess) {
                return false;
            }

            form.restart();
            goToBoard();
        };
        const popupProps = {fullName: initialValues.full_name, companyName, onDelete: onPopupDelete};
        const children = <ContactDeletionPopup {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    }, []);

    const onUserRoleAvailable = useDebouncedCallback((email, role) => isEmail(email) && role && requestUserRoleAvailable(email, role));

    return (
        <ContentSection className='contact-info-basic-form'>
            <Text type={TEXT_TYPES.bodyBold}>{isExisted ? 'Edit' : 'Add'} Contact</Text>
            <Form name={formName} initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} validate={validate(isUserRoleAvailable)}>
                {({handleSubmit, form, values}) => {
                    const onReinvite = () => reinvite(form);
                    const onDelete = () => deleteContact(form);

                    const onEmailChange = ({target: {value: email}}) => onUserRoleAvailable(email, values.role);
                    const onRoleChange = role => onUserRoleAvailable(values.email, role);

                    return (
                        <form className='contact-form' onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Column sm={6}>
                                    <Field name='first_name'>
                                        {props => <Input {...props} placeholder='Enter first name...' label='First Name' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='last_name'>
                                        {props => <Input {...props} placeholder='Enter last name...' label='Last Name' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='email' onChange={onEmailChange}>
                                        {props => <Input {...props} disabled={isExisted} maxLength={100} placeholder='Enter valid email...' icon={ICON_TYPES.message} label='Email Address' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='phone'>
                                        {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Phone' isRequired/>}
                                    </Field>
                                </Column>

                                <Column sm={6}>
                                    <Field name='role' onChange={onRoleChange}>
                                        {props => <Select options={roleOptions} {...props} disabled={isInitialZendeskRole} label='Role' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='title'>
                                        {props => <Input placeholder='Enter title...' {...props} label='Title' isRequired/>}
                                    </Field>

                                    {isExisted && !initialValues.active && (
                                        <div className='invitation-wrapper'>
                                            <Text>Invitation email sent on: {formatDate(initialValues.last_invited_at, 'MM/dd/yyyy')}</Text>
                                            <Button className='invitation-wrapper__button' type={BUTTON_TYPES.tertiary} onClick={onReinvite}>Re-Invite Contact</Button>
                                        </div>
                                    )}
                                </Column>
                            </Row>

                            <Separator type='solid'/>

                            <Row middle='xs'>
                                <Column sm>
                                    <Field name='is_billing_contact'>{props => <Checkbox caption='Mark as Billing Contact' {...props}/>}</Field>
                                </Column>

                                <Column className='contact-form-action-bar' constant>
                                    <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={goToBoard}>Cancel</Button>

                                    {isExisted && <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.destructive} onClick={onDelete}>Remove contact</Button>}

                                    <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>{isExisted ? 'Save Changes' : 'Save & Invite contact'}</Button>
                                </Column>
                            </Row>
                        </form>
                    );
                }}
            </Form>
        </ContentSection>
    );
};

ContactInfoBasicForm.propTypes = {
    isExisted: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        full_name: PropTypes.string,
        active: PropTypes.bool,
        last_invited_at: PropTypes.string,
        role: PropTypes.string,
        email: PropTypes.string
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
    openPopup: PropTypes.func
};

export {ContactInfoBasicForm as TestableContactInfoBasicForm};
export default withPopup(POPUP_ID)(ContactInfoBasicForm);
