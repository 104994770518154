import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import FileSource, {validateOnSubmit as validateFileSourceOnSubmit} from '../FileSource';
import withEligibilityDetailsForm from '../../../HOC/withEligibilityDetailsForm';
import useFormState from '../../../hooks/useFormState';
import {getEqual} from '../../../utils';
import {ROUTES} from '../../../constants';
import {ENROLMENT_OPTIONS, UNIQUE_MEMBER_IDENTIFIER_OPTIONS} from '../../../options';

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateFileSourceOnSubmit(values)});

const VoluntaryElections = () => {
    const {planPeriodId} = useParams();
    const {values} = useFormState();
    const {voluntary_source: voluntarySource, unique_member_key: uniqMemberKey} = values;
    const {label: voluntarySourceName} = useMemo(() => ENROLMENT_OPTIONS.find(getEqual(voluntarySource, 'value')) || {}, [voluntarySource]);
    const {label: uniqMemberKeyName} = useMemo(() => UNIQUE_MEMBER_IDENTIFIER_OPTIONS.find(getEqual(uniqMemberKey, 'value')) || {}, [uniqMemberKey]);

    return (
        <div className='eligibility-details-form medical-ids-file'>
            <Separator/>

            <Row className='eligibility-details-form-row'>
                <Column className='eligibility-details-form-row__column' sm>
                    <Row className='eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={7}>
                            <FileSource/>
                        </Column>
                    </Row>

                    <Separator/>

                    <Row className='overview-selection eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={12}>
                            <Row className='overview-selection-heading eligibility-details-form-row'>
                                <Column className='eligibility-details-form-row__column' sm={10}>
                                    <Text className='overview-selection-heading__title'>Overview Selections</Text>
                                </Column>

                                <Column sm={2} className='edit-wrapper eligibility-details-form-row__column'>
                                    <Link className='edit-wrapper-link' href={`${ROUTES.eligibility}/${planPeriodId}/implementation`}>
                                        <Icon className='edit-wrapper-link__link' type={ICON_TYPES.edit}/>

                                        <Text className='edit-wrapper-link__title'>Edit</Text>
                                    </Link>
                                </Column>
                            </Row>

                            <Row className='eligibility-details-form-row'>
                                <Column sm={10} className='overview-selection-body overview-selection-body_with-indent eligibility-details-form-row__column'>
                                    <Row className='eligibility-details-form-row'>
                                        <Column sm={6} className='eligibility-details-form-row__column'>Unique member identifier</Column>
                                        <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{uniqMemberKeyName}</Column>
                                    </Row>

                                    <Row className='eligibility-details-form-row'>
                                        <Column sm={6} className='eligibility-details-form-row__column'>Where will the voluntary elections be coming from?</Column>
                                        <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{voluntarySourceName}</Column>
                                    </Row>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </div>
    );
};

export {VoluntaryElections as TestableVoluntaryElections};
export default withEligibilityDetailsForm({validateOnSubmit})(VoluntaryElections);
