import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import CategoryFormContent from './CategoryFormContent';
import PopupActionBar from './PopupActionBar';
import {Form} from '../../../shared/FormComponents';
import {
    requestIndividualConfigAncillaryCategoryDeleting,
    requestIndividualConfigAncillaryCategoryUpdating
} from '../../../../actions/adminPortal';
import {FORMS} from '../../../../constants';

const EditCategoryPopup = ({getFormattedCategory, initialValues: formInitialValues, closePopup, importConfigId, isArchived, showToast, duplicatedValues: initialDuplicatesValues}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState(formInitialValues);
    const formattedCategory = getFormattedCategory(formInitialValues?.category);
    const [duplicatedValues, setDuplicatesValues] = useState(initialDuplicatesValues);

    const onSubmit = async ({isClosePopup, ...values}) => {
        const {data, isSuccess, submissionErrors} = await dispatch(requestIndividualConfigAncillaryCategoryUpdating({configId: importConfigId, categoryDetails: values}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues({isClosePopup, ...data?.ancillary_config});
        setDuplicatesValues(data?.duplicated_values);
    };

    const onSubmitSuccess = ({values}) => {
        if (values?.isClosePopup) {
            closePopup();
        }

        showToast({content: 'Ancillary Plan changes saved!'});
    };

    const onDelete = async category => {
        const {isSuccess} = await dispatch(requestIndividualConfigAncillaryCategoryDeleting({configId: importConfigId, category}));

        if (!isSuccess) {
            return false;
        }

        closePopup();
        showToast({content: `Ancillary Plan - ${category} was deleted!`});
    };

    return (
        <Form initialValues={initialValues} name={FORMS.importConfigAncillary} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, form, values, dirty}) => {
                const onSave = isClosePopup => {
                    form.change('isClosePopup', isClosePopup);
                    handleSubmit();
                };

                const actionBarProps = {
                    category: formattedCategory,
                    onCancel: closePopup,
                    ...!isArchived && {
                        dirty,
                        onDelete: () => onDelete(values?.category),
                        onSaveAndContinue: () => onSave(false),
                        onSaveAndClose: () => onSave(true)
                    }
                };

                const popupProps = {
                    title: formattedCategory,
                    actionBar: <PopupActionBar {...actionBarProps}/>,
                    isScrollableContent: true
                };

                return (
                    <PopupContent {...popupProps}>
                        <form onSubmit={handleSubmit} noValidate>
                            <CategoryFormContent disabled={isArchived} duplicatedValues={duplicatedValues} category={formInitialValues?.category}/>
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

EditCategoryPopup.propTypes = {
    isArchived: PropTypes.bool,
    importConfigId: PropTypes.number,
    duplicatedValues: PropTypes.shape({}),
    initialValues: PropTypes.shape({
        category: PropTypes.string
    }),
    getFormattedCategory: PropTypes.func,
    closePopup: PropTypes.func,
    showToast: PropTypes.func
};

export default EditCategoryPopup;
