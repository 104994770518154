import React, {useCallback} from 'react';
import Select from '@frontend/ui-kit/Components/Select';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import CopyableField from '../CopyableField';
import AncillaryCarrierInfo, {validateOnSubmit as validateAncillaryCarrierOnSubmit} from '../AncillaryCarrierInfo';
import DocumentUploader from '../DocumentUploader';
import withCorePlanDetails from '../../../HOC/withCorePlanDetails';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {normalizeBoolean, trimStart, validateRequired} from '../../../utils';
import {CONDITION_OPTIONS, PLAN_TYPE_OPTIONS} from '../../../options';
import visionCard from '../../../static/images/cards/vision_card.svg';

const recommendedFields = [
    {name: 'network_name', label: 'Provider network'},
    {name: 'exam', label: 'Exam'},
    {name: 'materials', label: 'Materials'},
    {name: 'lenses', label: 'Standard Lenses'},
    {name: 'frames', label: 'Frames'},
    {name: 'contacts_fitting', label: 'Fitting'},
    {name: 'contacts_necessary', label: 'Necessary'},
    {name: 'contacts_elective', label: 'Elective'},
    {name: 'contacts_conventional', label: 'Conventional'},
    {name: 'contacts_disposable', label: 'Disposable'}
];

/* istanbul ignore next */
const validateOnSubmit = values => {
    const {is_additional_network: isAdditionalNetwork} = values;

    return ({
        ...validateAncillaryCarrierOnSubmit(values),
        group_number: validateRequired(values.group_number),
        secondary_network_name: isAdditionalNetwork ? validateRequired(values.secondary_network_name) : undefined
    });
};

/* istanbul ignore next */
const validate = values => ({
    name: validateRequired(values.name)
});

const VisionPlanForm = () => {
    const form = useForm();
    const {values} = useFormState();

    const {is_additional_network: isAdditionalNetwork} = values;

    const resetFields = useCallback(fields => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, [form]);

    return (
        <div className='plan-details-form vision-plan-form'>
            <Row className='plan-details-form-row'>
                <Column className='plan-details-form-row__column' sm>
                    <CopyableField name='name' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' label='Plan Name' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='app_name' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' label='Plan Name Show in the App' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='type'>
                        {props => <Select {...props} placeholder='Type to select...' options={PLAN_TYPE_OPTIONS} label='Plan Type'/>}
                    </CopyableField>
                </Column>
            </Row>

            <Row className='plan-details-form-row'>
                <Column className='plan-details-form-row__column' sm>
                    <AncillaryCarrierInfo/>
                </Column>
            </Row>

            <Row className='plan-details-form-row'>
                <Column className='plan-details-form-row__column' sm={7}>
                    <DocumentUploader label='Upload File'/>

                    <Text className='plan-details-form__description'>
                        Enter the relevant information to complete your Vision Plan. This will appear in your HealthJoy Benefits Wallet.
                    </Text>

                    <div className='numbered-title numbered-title_first'>
                        <div className='numbered-title__num'>1</div> Member Information
                    </div>

                    <CopyableField name='group_number' parse={trimStart}>
                        {props => <Input {...props} maxLength={40} placeholder='Please enter...' label='Group ID' isRequired wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='network_name' parse={trimStart}>
                        {props => <Input {...props} placeholder='Please enter...' label='Provider network' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='is_additional_network' onChange={resetFields(['secondary_network_name'])} parse={normalizeBoolean}>
                        {props => <RadioGroup {...props} options={CONDITION_OPTIONS} label='Does this plan have an additional network?' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    {isAdditionalNetwork && (
                        <CopyableField name='secondary_network_name' parse={trimStart}>
                            {props => <Input {...props} placeholder='Please enter...' label='Additional provider network' wrapperClassName='mb-12'/>}
                        </CopyableField>
                    )}

                    <div className='numbered-title numbered-title_second'>
                        <div className='numbered-title__num'>2</div> Benefit Highlights
                    </div>

                    <CopyableField name='exam' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XX Copay' label='Exam' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='materials' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XX Copay' label='Materials' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='lenses' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XX Copay' label='Standard Lenses' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='frames' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XXX allowance then X% off' label='Frames' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <Text className='plan-details-form__title' type={TEXT_TYPES.bodyBold}>Contacts</Text>

                    <CopyableField name='contacts_fitting' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XX Copay' label='Fitting' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='contacts_necessary' parse={trimStart}>
                        {props => <Input {...props} placeholder='e.g.: Covered in full' label='Necessary' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='contacts_elective' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XXX allowance' label='Elective' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='contacts_conventional' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XXX allowance' label='Conventional' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='contacts_disposable' parse={trimStart}>
                        {props => <Input {...props} placeholder='$XXX allowance' label='Disposable'/>}
                    </CopyableField>
                </Column>

                <Column sm={5} className='numerated-img plan-details-form-row__column'>
                    <div className='img-numerator'>
                        <div className='img-numerator-step num_one'>1</div>
                        <div className='img-numerator-step num_two'>2</div>
                    </div>

                    <img className='numerated-img-content' src={visionCard} alt=''/>
                </Column>
            </Row>
        </div>
    );
};

export {VisionPlanForm as TestableVisionPlanForm};
export default withCorePlanDetails({recommendedFields, validate, validateOnSubmit})(VisionPlanForm);
