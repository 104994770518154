import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Row from '@frontend/ui-kit/Components/Row';
import Table from '@frontend/ui-kit/Components/Table';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {
    requestEligibilityImportSession,
    requestVerificationReportCardLinks,
    requestVerificationReportCardLinksUpdating
} from '../../../actions/adminPortal';
import {REDASH_URL} from '../../../constants';
import './index.scss';

const TABLE_PAGE_SIZE = 20;

const VerificationReportCardLinks = () => {
    const dispatch = useDispatch();
    const {id: importId} = useParams();
    const [importSession, setImportSession] = useState({});
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0});
    const [tableData, setTableData] = useState([]);
    const [tooltip, setTooltip] = useState('Copy');

    useEffect(() => {
        (async () => {
            const {data: importSession} = dispatch(requestEligibilityImportSession(importId));

            setImportSession(importSession);
        })();
    }, [importId, dispatch]);

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE}) => {
        const query = {
            session_id: importId,
            limit: pageSize,
            offset: pageIndex * pageSize
        };
        const {isSuccess, cardLinks, count} = await dispatch(requestVerificationReportCardLinks(query));

        if (!isSuccess) {
            return false;
        }

        setTable({unitCount: count, pageIndex});
        setTableData(cardLinks);
    }, [importId, dispatch]);

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        setTooltip('Copied to Clipboard');
    };

    const onConfirm = async (value, index) => {
        const {isSuccess} = await dispatch(requestVerificationReportCardLinksUpdating({session_id: importId, row_number: index, confirmation: value}));

        if (!isSuccess) {
            return false;
        }

        onFetchData({pageIndex: table.pageIndex});
    };

    const getTableColumns = () => {
        const getEmail = ({value}) => (
            <div className='email'>
                <div className='email__content'>{value}</div>
                <Tooltip content={tooltip} onHidden={() => setTooltip('Copy')}>
                    <Button type={BUTTON_TYPES.tertiary} className='email__button ml-3'><Icon onClick={() => copyToClipboard(value)} type={ICON_TYPES.copy}/></Button>
                </Tooltip>
            </div>
        );

        const getConfirmation = ({value, state, row}) => {
            const {pageIndex} = state || {};
            const {index} = row || {};
            const rowIndex = pageIndex * TABLE_PAGE_SIZE + index;

            return (
                <Tooltip content={`${value ? 'Remove' : 'Save'} Confirmation`}>
                    <Checkbox value={value} onChange={() => onConfirm(!value, rowIndex)}/>
                </Tooltip>
            );
        };

        return [
            {Header: 'Category', accessor: 'category'},
            {Header: 'Plan Name', accessor: 'plan_name'},
            {Header: 'Effective Date', accessor: 'effective_date'},
            {Header: 'Expiration Date', accessor: 'expiration_date'},
            {Header: 'Email', accessor: 'email', Cell: getEmail},
            {Header: 'Confirmation', accessor: 'confirmation', Cell: getConfirmation, align: 'center'}
        ];
    };

    const tableProps = {
        data: tableData,
        isSortable: false,
        isFilterable: false,
        className: 'mt-10',
        columns: getTableColumns(),
        pageSize: TABLE_PAGE_SIZE,
        onFetchData,
        ...table
    };

    return (
        <div className='verification-report-card-links'>
            <Heading type={HEADING_TYPES['5']} className='mb-5'>{importSession?.additional_data?.company_name}</Heading>
            <Heading type={HEADING_TYPES['1']}>Card Links Verification</Heading>

            <ContentSection className='mt-20 pb-10 pt-15'>
                <Row end='xs'>
                    <Column constant>
                        <Link href={`${REDASH_URL}/queries/3419?p_alias=${importSession?.company_alias}&p_group_vol_by_name=No`} target='_blank'>
                            <Icon className='mr-3' type={ICON_TYPES.externalLink}/> Open Ancillary Plans Import Correctly Query
                        </Link>
                    </Column>
                </Row>

                <Table {...tableProps}/>
            </ContentSection>
        </div>
    );
};

export {VerificationReportCardLinks as TestableVerificationReportCardLinks};
export default VerificationReportCardLinks;
