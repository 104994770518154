import {addDays as addDaysToDate, addYears as addYearsToDate, format, parseISO, isValid, isDate, isFuture as getIsFuture, getYear as extractYear, isWithinInterval, differenceInCalendarDays} from 'date-fns';
import queryString from 'query-string';
import scrollIntoView from 'scroll-into-view';
import fileSaver from 'file-saver';
import {v4 as uuidv4} from 'uuid';
import {diff as getDiff} from 'deep-object-diff';
import dotObject from 'dot-object';
import jwtDecode from 'jwt-decode';
import {
    ALPHABETIC_CHARACTER_REGEXP,
    ALPHANUMERIC_REGEXP,
    DATA_UNITS,
    EMAIL_REGEXP,
    INT_REGEXP,
    KB_SIZE,
    PASSWORD_RULES,
    URL_REGEXP,
    SECURE_URL_REGEXP,
    SFTP_USERNAME_REGEXP,
    SFTP_PATH_REGEXP,
    SPEC_CHARACTER_REGEXP,
    HOST_REGEXP,
    USA_TIMEZONES,
    FILE_NAME_WITH_HASH
} from './constants';

export const equal = (val, other) => val === other;

export const pipe = (...funcs) => {
    const _pipe = (prevFunc, currFunc) => (...arg) => currFunc(prevFunc(...arg));

    return funcs.reduce(_pipe);
};

export const compose = (...funcs) => pipe(...funcs.reverse());

export const getMatches = (rules, value = '') => {
    return Object
        .entries(rules)
        .reduce((acc, [key, pattern]) => ({...acc, [key]: value.match(pattern)}), {});
};

export const pass = val => val;

export const negate = val => !val;

export const negateFunc = func => pipe(func, negate);

export const isPrimitive = val => val !== Object(val);

export const isBoolean = val => equal(typeof val, 'boolean');

export const isString = val => equal(typeof val, 'string');

export const isNumber = val => equal(typeof val, 'number');

export const isFunction = val => equal(typeof val, 'function');

export const isObject = val => equal(typeof val, 'object') && !equal(val, null);

export const isError = val => val instanceof Error;

export const isFormData = val => val instanceof FormData;

export const isFile = val => val instanceof File;

export const isBlob = val => val instanceof Blob;

export const isEmail = value => EMAIL_REGEXP.test(value);

export const isUrl = value => URL_REGEXP.test(value);

export const isEmpty = obj => {
    if (isPrimitive(obj)) {
        return !obj;
    }
    if (Array.isArray(obj)) {
        return !obj.length;
    }

    return isEmpty(Object.keys(obj));
};

export const isEmptyNested = obj => {
    if (isPrimitive(obj)) {
        return !obj;
    }
    if (Array.isArray(obj)) {
        return !obj.filter(negateFunc(isEmpty)).length;
    }

    return isEmpty(obj) ? true : Object.values(obj).every(isEmptyNested);
};

export const isObjectHasProps = (obj, props) => {
    try {
        return props.every(prop => prop in obj);
    } catch (e) {
        return false;
    }
};

export const partial = (func, ...params) => (...args) => func(...params, ...args);

export const getItemKeyValue = key => obj => obj?.[key];

export const getEqual = (value, key) => pipe(key ? getItemKeyValue(key) : pass, partial(equal, value));

export const groupBy = (arr, key, initial = {}) => arr.reduce((acc, item) => {
    const {[key]: groupKey} = item;
    const groupedItems = acc[groupKey] || [];

    return {...acc, [groupKey]: [...groupedItems, item]};
}, initial);

export const splitIntoParts = (arr = [], partsCount = 2) => {
    const separator = Math.ceil(arr.length / partsCount);

    return Array(partsCount).fill(null).reduce((acc, item, index) => {
        const nextIndex = index + 1;

        return [...acc, arr.slice(separator * index, separator * nextIndex)];
    }, []);
};

export const splitIntoChunks = (arr = [], chunkCount = 2) => {
    const res = [];
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkCount);
        res.push(chunk);
    }
    return res;
};

export const splitByIndex = (arr = [], index) => [arr.slice(0, index), arr.slice(index)];

export const promisifyAsyncFunction = (func, resolve = res => res, reject = () => {}) => (...params) => func(...params).then(resolve).catch(reject);

export const omit = (obj, keys) => Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));

export const decorateWith = (...decorators) => (target, name, descriptor) => {
    const decorate = compose(...decorators);
    const originalFunc = descriptor.value;
    const funcName = originalFunc.name;
    const boundFunc = originalFunc.bind(target);
    boundFunc.__funcName__ = name || funcName;

    return {...descriptor, value: decorate(boundFunc)};
};

export const getRegisteredFieldsValues = (registeredFields, values) => {
    return registeredFields.reduce((acc, field) => {
        dotObject.set(field, dotObject.pick(field, values), acc);

        return acc;
    }, {});
};

export const getUpdatedValues = (values, initialValues = {}) => {
    const getFormattedDiff = (diff, value) => {
        if (Array.isArray(value) || isPrimitive(value)) {
            return value;
        }

        return Object.entries(diff).reduce((acc, [key, val]) => ({...acc, [key]: getFormattedDiff(val, value[key])}), {});
    };

    return getFormattedDiff(getDiff(initialValues, values), values);
};

export const getErrorFieldNames = (errors = {}) => {
    const getFieldNames = (errors, name = '') => {
        if (isPrimitive(errors)) {
            return name;
        }

        if (Array.isArray(errors)) {
            return errors.map((item, index) => item && getFieldNames(item, `${name}[${index}]`));
        }

        return Object.entries(errors).map(([key, value]) => value && getFieldNames(value, name ? `${name}.${key}` : key));
    };

    return getFieldNames(errors).flat(Infinity).filter(Boolean);
};

export const getNormalizedErrorMessages = value => {
    if (Array.isArray(value)) {
        return value.every(isString) ? value[0] : value.map(getNormalizedErrorMessages);
    }

    if (isPrimitive(value)) {
        return value;
    }

    return Object.entries(value).reduce((acc, [key, val]) => ({...acc, [key]: getNormalizedErrorMessages(val)}), {});
};

export const getErrorlessData = data => {
    if (Array.isArray(data)) {
        return data.filter(negateFunc(isError)).map(getErrorlessData);
    }
    if (isError(data)) { // FYI: due to removing of empty fields by apiSauce we've decided that default value for fields which contains error - null (02.07.2021, Oleh)
        return null;
    }
    if ([isPrimitive, isFormData, isFile, isBlob].some(func => func(data))) {
        return data;
    }

    return Object.entries(data).reduce((acc, [key, val]) => ({...acc, [key]: getErrorlessData(val)}), {});
};

export const getFileFormat = name => name && name.split('.').reverse()?.[0];

export const getFileName = fileUrl => fileUrl && fileUrl.split?.('/').pop();

export const getFileUrl = file => file && (file.dataUrl || file);

export const getFromObjSafe = (path, obj) => dotObject.pick(path, obj);

export const getObjWithoutPaths = (paths, obj) => {
    const updatedObj = {...obj};
    paths.forEach(path => dotObject.delete(path, updatedObj));

    return updatedObj;
};

export const getDottedObj = obj => dotObject.dot(obj);

export const getObjFromDotted = dottedObj => dotObject.object(dottedObj);

export const saveFile = (file, name = '') => fileSaver.saveAs(file, name);

export const toCapitalize = str => str && (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());

export const trimStart = str => str && str.replace(/^ +/g, '');

export const normalizeBoolean = value => ({true: true, false: false, null: null}[value]);

export const normalizeNumber = value => value === '' ? null : Number(value);

export const normalizePositiveNumber = (value, fieldName, form) => {
    const {values} = form.getState();

    if (value === '') {
        return null;
    }

    if (/^[+]?([.]\d+|\d+[.]?\d*)$/.test(value)) {
        if (value.endsWith('.')) {
            return value;
        }

        return Number(value);
    }

    return getFromObjSafe(fieldName, values);
};

export const normalizeList = value => (value || '').split('\n').map(line => line.replace(/✓/g, '').replace(/^ /g, '')).join('\n');

export const formatList = value => (value || '').split('\n').map(line => line ? `✓ ${line}` : '').join('\n');

export const formatListByLocales = (list, {locales = 'en', ...options}) => {
    const formatter = new Intl.ListFormat(locales, options);

    return formatter.format(list);
};

export const formatPhone = (phone, isCountryCallingCode = true) => {
    if (!phone) {
        return false;
    }

    const formattedPhone = `${phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}`;

    return isCountryCallingCode ? `+1 ${formattedPhone}` : formattedPhone;
};

export const formatDate = (date, dateFormat) => {
    const parsedDate = isDate(date) ? date : parseISO(date);

    return isValid(parsedDate) ? format(parsedDate, dateFormat) : null;
};

export const addDays = (date, daysCount = 0) => date && addDaysToDate(date, daysCount);

export const addYears = (date, daysCount = 0) => date && addYearsToDate(date, daysCount);

export const isDateWithinRange = (date = null, dateFrom = null, dateTo = null) => isWithinInterval(
    new Date(date),
    {start: new Date(dateFrom), end: new Date(dateTo)}
);

export const isFuture = data => getIsFuture(data);

export const getDifferenceInCalendarDays = (dateLeft, dateRight) => differenceInCalendarDays(new Date(dateLeft), new Date(dateRight));

export const getYear = date => date && extractYear(date);

export const formatMoney = (money, separator = ',', currency = '$') => {
    const formattedMoney = money.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${separator}`);

    return `${currency}${formattedMoney}`;
};

export const minToSec = min => min * 60;
export const secToMin = sec => sec / 60;

export const scrollToComponent = (component, time = 500, settings) => scrollIntoView(component, {time, ...settings});

export const bytesToMegabytes = bytes => bytes / 1024 / 1000;

export const formatBytes = (bytes, decimals = 2) => {
    const units = Object.values(DATA_UNITS);
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(KB_SIZE));
    const unit = units[unitIndex];
    const size = parseFloat((bytes / Math.pow(KB_SIZE, unitIndex)).toFixed(decimals));

    return `${size} ${unit}`;
};

export const validateAlphabeticCharacterPresence = (...values) => {
    return values?.some(value => !new RegExp(ALPHABETIC_CHARACTER_REGEXP, '').test(value)) ? 'The value should contain at least one alphabetic character' : undefined;
};
export const validateSpecCharactersAbsence = (...values) => {
    return values?.some(value => new RegExp(SPEC_CHARACTER_REGEXP, '').test(value))
        ? 'The value cannot contain special characters. Only alphabetic, numeric characters and spaces are allowed'
        : undefined;
};
export const validateAlphanumeric = value => {
    return value && !ALPHANUMERIC_REGEXP.test(value) ? 'Only alphabetic and numeric characters are allowed' : undefined;
};
export const validateInt = (value, range = {}, intErrorMessage = 'Enter a numeric value') => {
    const {from = -Infinity, to = Infinity} = range;
    const isInRange = value >= from && value <= to;

    const intValidationMessage = !INT_REGEXP.test(value) && intErrorMessage;
    const rangeValidationMessage = !isInRange && `Enter value between ${from} and ${to}`;

    return intValidationMessage || rangeValidationMessage || undefined;
};
export const validateMinLength = (value, minLength) => {
    return value && value.length < minLength ? `Must be ${minLength} characters or more` : undefined;
};
export const validateMaxLength = (value, maxLength) => {
    return value && value.length > maxLength ? `Must be ${maxLength} characters or less` : undefined;
};
export const validateEmail = email => !isEmail(email) ? 'Please enter a valid email' : undefined;
export const validateSecureLink = secureLink => !SECURE_URL_REGEXP.test(secureLink) ? 'Please enter a valid secure link (https://)' : undefined;
export const validateRequired = (value, {isBooleanExpected = false, isNumberExpected = false} = {}) => {
    let isInvalidValue = false;
    if (isBooleanExpected) {
        isInvalidValue = !isBoolean(value);
    }
    if (isNumberExpected) {
        isInvalidValue = !isNumber(value);
    }
    if (!isBooleanExpected && !isNumberExpected) {
        isInvalidValue = isEmpty(getErrorlessData(value));
    }

    return isInvalidValue ? 'Required' : undefined;
};
export const validateDifference = (...values) => (values[0] && values.every(getEqual(values[0]))) ? 'Values cannot be the same' : undefined;
export const validateStrongPassword = password => !Object.values(getMatches(PASSWORD_RULES, password)).every(Boolean) ? ' ' : undefined;
export const validatePasswordConfirm = (passwordConfirm, password) => passwordConfirm !== password ? ' ' : undefined;
export const validateFileSize = (value, maxSize) => {
    const errorMessage = `Your uploaded file is too big. Maximum file size is: ${maxSize}Mb`;
    const files = [].concat(value).filter(item => item instanceof File);
    const isInvalidSize = files.some(({size}) => bytesToMegabytes(size) > maxSize);

    return isInvalidSize ? errorMessage : undefined;
};

export const validateHost = value => !HOST_REGEXP.test(value) ? 'Please enter a valid host' : undefined;

export const validateSFTPUsername = value => !SFTP_USERNAME_REGEXP.test(value) ? 'Please enter valid symbols: letters, numbers, "_" or "-"' : undefined;
export const validateSFTPPath = value => value && !SFTP_PATH_REGEXP.test(value) ? 'Should be a valid sftp path. For example: sftp_healthjoy:username@password' : undefined;
export const validateSFTPWhiteListItem = value => !/^([0-9.-]+|[0-9./]+)$/.test(value) ? 'Please enter valid symbols: numbers, ".", "/" or "-"' : undefined;

export const validateCampaignTriggerEvent = value => !/^[a-z0-9_]*$/.test(value) ? 'Please enter valid symbols: lower case letters, numbers and underscore, no spaces' : undefined;

export const validateJSON = string => {
    if (!string) {
        return undefined;
    }

    try {
        JSON.parse(string);
        return undefined;
    } catch (e) {
        return 'Invalid JSON';
    }
};

export const validateJSONObject = value => {
    const errorMessage = validateJSON(value);

    if (errorMessage) {
        return errorMessage;
    }

    return value && Array.isArray(JSON.parse(value)) ? 'Should be a valid Object' : undefined;
};

export const matchFileName = (fileName, pattern = '', patternSymbol = '*') => {
    const patternWord = pattern.replaceAll(patternSymbol, '');
    const isPatternStartWithSymbol = pattern.startsWith(patternSymbol);
    const isPatternEndBySymbol = equal(pattern.at(-1), patternSymbol);

    if (!patternWord.length) {
        return true;
    }

    const basicRegexPattern = isPatternStartWithSymbol ? `(${patternWord})$` : `^(${patternWord})`;
    const regex = new RegExp(`${isPatternStartWithSymbol && isPatternEndBySymbol ? patternWord : basicRegexPattern}`, 'g');

    return regex.test(fileName);
};

export const validateFileNamePattern = (file, pattern, matchFileName) => {
    const basicfileName = getFileName(file);
    // FYI: need to remove the prefix that was added in the file manager (7.09.2023)
    const isHash = FILE_NAME_WITH_HASH.test(basicfileName);
    const fileName = isHash ? basicfileName?.slice(0, basicfileName.lastIndexOf('_')) : basicfileName?.slice(0, basicfileName?.lastIndexOf('.'));

    return (!pattern || matchFileName(fileName, pattern)) ? undefined : `Filename does not match file pattern ${pattern} in the import config.`;
};

export const validateFileNamesPattern = (files = [], pattern, matchFileName) => {
    const filesErrors = files.map(file => validateFileNamePattern(file, pattern, matchFileName));

    return (!pattern || isEmpty(filesErrors.filter(Boolean))) ? undefined : filesErrors;
};

export const toPercent = (value, limit = 0) => (parseFloat(value, 10) * 100).toFixed(limit);

export const getDelimitedNum = (num = 0) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatChartLabels = (labels = [], formatPattern) => {
    const formatChartLabel = date => formatDate(date, formatPattern).split(' ');

    return labels.map(formatChartLabel);
};

export const getIncreasedByCoefficient = (value, coefficient) => value * coefficient;

export const trimByMaxLength = (str, maxLength, endMark = '...', separator = ' ') => {
    if (str?.length > maxLength) {
        const trimmedValue = str.substr(0, str.lastIndexOf(separator, maxLength - endMark.length));

        return `${trimmedValue}${endMark}`;
    }

    return str;
};

export const isEven = number => number % 2 === 0;

export const getUnfilledArray = (length = 0) => Array(length).fill(undefined);

export const generateUniqueId = () => uuidv4();

export const normalizeMarkupEditor = value => {
    const matches = isString(value) ? value.match(/<section>([^]*)<\/section>/) : null;
    const parsedValue = matches ? matches[1] : value;

    return !equal(parsedValue, '<br>') ? parsedValue : '';
};

export const delay = (func, ms = 0) => setTimeout(func, ms);

export const moveArrayItem = (arr, oldId, newId) => {
    const updatedArray = [...arr];
    // FYI Sorter use key attribute as id, so we need to make correct comparison with real item ids (15.02.22, Yuri)
    const oldIndex = arr.findIndex(({view}) => equal(`$${view.id}`, oldId));
    const newIndex = arr.findIndex(({view}) => equal(`$${view.id}`, newId));
    const [item] = updatedArray.splice(oldIndex, 1);
    updatedArray.splice(newIndex, 0, item);

    return updatedArray;
};

export const filterUniqArrayValues = (value, index, array) => equal(array.indexOf(value), index);

export const setPropForSort = path => (first, second) => {
    // Convert undefined || null || NaN to 0
    const [firstVal, secondVal] = [first, second].map(obj => getFromObjSafe(path, obj) || 0);

    return firstVal - secondVal;
};

export const debounce = (func, delay = 300) => {
    let timeout = null;

    return (...args) => {
        clearTimeout(timeout);

        return new Promise(resolve => {
            const next = () => resolve(func(...args));

            timeout = setTimeout(next, delay);
        });
    };
};

export const parseQuery = (query, {arrayFormat = 'comma'} = {}) => queryString.parse(query, {arrayFormat});

export const stringifyQueryParams = ({arrayFormat = 'comma', ...params}) => queryString.stringify(params, {arrayFormat});

export const isValidDate = date => /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);

export const getTextFromHtml = (value, shouldBeTrimmed = false) => {
    const element = document.createElement('div');
    element.innerHTML = value;

    return shouldBeTrimmed ? element.innerText.trim() : element.innerText;
};

export const getEncodedHtml = value => value && value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

export const getReportingMetricInfo = (metricId, companyData = {}) => {
    const {show_find_care: isFindCareVisible} = companyData;
    const REPORTING_METRICS_INFO_BY_ID = {
        activation_percentage: {label: 'Activation Percentage'},
        dollar_saved: {label: 'Dollars Saved'},
        roi_rate: {label: 'Return on Investment'},
        telemed_savings: {
            label: 'MeMD',
            description: 'Calculated by using the percentage of members who would have visited another care site and the cost of visiting that care site. The time savings of the consult is then added to get an average savings per consult. We multiply this average by the total number of telemedicine consults.'
        },
        teladoc_health: {label: 'Teladoc Health'},
        teladoc_general_medical_consults: {label: 'General Medical (Urgent Care)'},
        teladoc_mental_health_consults: {label: 'Mental Health'},
        rx_savings: {
            label: 'Rx',
            description: 'Calculated as the sum of the actual savings found in actual identified prescriptions. Savings are accrued according to the refill cadence. For example, if a member has a $10 savings opportunity on a monthly prescription, over 12 months that would total $120 in savings.'
        },
        bill_savings: {
            label: 'Medical Bill Review',
            description: 'Calculated as $689 per medical bill, an average dollar savings value. Total Medical Bill Review savings is the sum of all of the savings from each review.'
        },
        msk_savings: {
            label: 'MSK Therapy',
            description: 'Calculated by incorporating each participant’s prior care and current care intentions to determine their savings potential. Total MSK Savings is the sum of the realized savings values.'
        },
        precert_savings: {
            label: 'Pre-certification Steerage',
            description: 'Calculated as the difference between the average price for procedure and the estimated price of the recommendation to determine the total savings opportunity. Average price is determined by procedure type and facility location.'
        },
        provider_facility_savings: {
            label: 'Provider/Facility Steerage',
            description: `
                Calculated as the sum of the cost savings found in all of the provider and facility steerage recommendations.
                Steerage consists of several subcategories with unique dollar values attached to each recommendation.
                Values range from $50 (dental) - $2,000 (surgery) per request.
                ${isFindCareVisible ? 'Find Care searches are included in the calculation. ' : ''}
                A detailed breakdown of each subcategory can be found in the downloadable report.
            `
        },
        member_actions: {label: 'Unique Member Actions'},
        telemed_consults: {label: 'MeMD Consults'},
        service_requests: {label: 'Service Requests'},
        wallet_views: {label: 'Benefits Wallet Views'},
        provider_requests: {label: 'Provider Requests'},
        facility_requests: {label: 'Facility Requests'},
        appointments_scheduled: {label: 'Appointments Scheduled'},
        find_care_searches: {label: 'Find Care Searches'},
        rx_requests: {label: 'Prescription Savings Requests'},
        bill_requests: {label: 'Medical Bill Reviews Completed'},
        benefits_support: {label: 'Benefits Support Interactions'},
        precert_recommendations: {label: 'New Provider Recommendations'},
        precert_designated: {label: 'Providers Designated Optimal'}
    };

    return REPORTING_METRICS_INFO_BY_ID[metricId];
};

export const decodeJWT = (token, params) => jwtDecode(token, params);

export const convertDateToTimeZone = (value, options = {timeZone: USA_TIMEZONES.central}) => {
    return value && new Date((isString(value) ? new Date(value) : value).toLocaleString('en-US', options));
};

export const removeOffset = dateString => dateString ? dateString.replace(/([+-]\d{2}:\d{2})$/, '') : '';

export const getTimeZoneOffset = dateTimeString => {
    const timeZoneMatch = dateTimeString.match(/([+-]\d{2}:\d{2})$/);

    return timeZoneMatch ? timeZoneMatch[0].replace(/^[+-]/, '') : null;
};

export const removeUtcPrefix = label => label.replace(/\(UTC[+-]\d{2}:\d{2}\)\s*/, '');

export const getTimeZoneLabelByOffset = (deliveryTime, options) => {
    const findTimeZoneLabel = timeZoneOffset => {
        const {label = ''} = options.find(option => equal(option.value, timeZoneOffset)) || {};

        return label;
    };

    const timeZoneOffset = deliveryTime && getTimeZoneOffset(deliveryTime);
    const timeZoneLabel = timeZoneOffset ? findTimeZoneLabel(timeZoneOffset) : '';

    return removeUtcPrefix(timeZoneLabel);
};

export const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];
