import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ImportCardDetails from '../../ImportCardDetails';
import {equal, isEmpty, toCapitalize} from '../../../../utils';
import {ANCILLARY_PLANS_DATA_BY_CATEGORY} from '../../../../constants';
import './index.scss';

const BASE_PLAN_LABELS = {
    active: 'Active',
    expired: 'Expired',
    future: 'Future',
    unknown: 'Unknown',
    member_ids: 'Member IDs',
    medical_ids_equal_to_ssn: 'IDs may be SSN',
    dependents_inherited_plan: 'Dependents Inherited Plan',
    duplicated_member_ids_in_file: 'Duplicated Member IDs in File',
    existing_member_ids_in_db: 'Existing Member IDs in DB'
};

const BenefitStatistics = ({benefits_statistics: benefitsStats = {}}) => {
    const {medical, dental, vision, medical_cobra: medicalCobra, dental_cobra: dentalCobra, vision_cobra: visionCobra, ancillary} = benefitsStats;
    const {plans: ancillaryPlans = []} = ancillary || {};
    const getEnhancedData = (data, type) => Object.entries(data).map(([key, value]) => {
        if (equal(key, 'plans')) {
            return value.map(({name, members_count}) => ({key: name, value: members_count}));
        }

        return ({key: `${equal(key, 'medical_ids_equal_to_ssn') ? `${type} ` : ''}${BASE_PLAN_LABELS[key]}`, value});
    }).flat();

    const corePlanDetails = useMemo(() => [
        {title: 'Medical', data: getEnhancedData(medical, 'Medical')},
        {title: 'Dental', data: getEnhancedData(dental, 'Dental')},
        {title: 'Vision', data: getEnhancedData(vision, 'Vision')}
    ], [medical, dental, vision]);

    const cobraPlanDetails = useMemo(() => [
        {title: 'COBRA Medical', data: getEnhancedData(medicalCobra, 'COBRA Medical')},
        {title: 'COBRA Dental', data: getEnhancedData(dentalCobra, 'COBRA Dental')},
        {title: 'COBRA Vision', data: getEnhancedData(visionCobra, 'COBRA Vision')}
    ], [medicalCobra, dentalCobra, visionCobra]);

    const getAncillaryName = category => category.split('_').map(word => toCapitalize(word)).join(' ');

    const enhancedAncillaryDetails = ancillaryPlans.map(({category, members_active_count, members_future_count}) => ({key: ANCILLARY_PLANS_DATA_BY_CATEGORY[category]?.label || getAncillaryName(category), value: members_active_count, future: members_future_count}));

    return (
        <div className='benefit-statistics'>
            <Heading className='mb-10'>Core Plan Details</Heading>

            <Row>
                {corePlanDetails.map(({title, data}) => (
                    <Column key={title} sm={4}>
                        <ImportCardDetails title={title} data={data}/>
                    </Column>
                ))}
            </Row>

            {!isEmpty(enhancedAncillaryDetails) && (
                <React.Fragment>
                    <Separator/>

                    <Heading className='mb-10'>Ancillary Plan Details</Heading>

                    <ImportCardDetails title='Active' data={enhancedAncillaryDetails} isAncillary/>
                </React.Fragment>
            )}

            <Separator/>

            <Heading className='mb-10'>COBRA Plan Details</Heading>

            <Row>
                {cobraPlanDetails.map(({title, data}) => (
                    <Column key={title} sm={4}>
                        <ImportCardDetails title={title} data={data}/>
                    </Column>
                ))}
            </Row>
        </div>
    );
};

const BaseCoreDetails = {
    active: PropTypes.number,
    expired: PropTypes.number,
    future: PropTypes.number,
    unknown: PropTypes.number,
    member_ids: PropTypes.number,
    medical_ids_equal_to_ssn: PropTypes.number,
    dependents_inherited_plan: PropTypes.number,
    duplicated_member_ids_in_file: PropTypes.number,
    existing_member_ids_in_db: PropTypes.number,
    plans: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, members_count: PropTypes.number}))
};

BenefitStatistics.propTypes = {
    benefits_statistics: PropTypes.shape({
        medical: PropTypes.shape({...BaseCoreDetails}),
        dental: PropTypes.shape({...BaseCoreDetails}),
        vision: PropTypes.shape({...BaseCoreDetails}),
        medical_cobra: PropTypes.shape({...BaseCoreDetails}),
        dental_cobra: PropTypes.shape({...BaseCoreDetails}),
        vision_cobra: PropTypes.shape({...BaseCoreDetails}),
        ancillary: PropTypes.shape({
            plans: PropTypes.arrayOf(PropTypes.shape({category: PropTypes.string, members_count: PropTypes.number}))
        })
    })
};

export {BenefitStatistics as TestableBenefitStatistics};
export default BenefitStatistics;
